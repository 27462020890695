import { useState, useEffect } from "react";
import { useLastLocation } from "react-router-last-location";

// Custom Hook to track the previous location
const usePreviousLocation = () => {
  const location = useLastLocation(); // Get current location
  const [prevLocation, setPrevLocation] = useState(null);

  useEffect(() => {
    setPrevLocation(location); // Update the previous location when location changes
  }, [location]);

  return prevLocation; // Return the previous location
};

export default usePreviousLocation;
