import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import {
//   Editor,
//   EditorState,
//   RichUtils,
//   convertToRaw,
//   convertFromHTML,
//   ContentState,
// } from "draft-js";
// import draftToHtml from "draftjs-to-html";

// react component
// import { MyEditor } from "../editor/Editor";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { IconButton, Button } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import { validatePositiveNumberEvent } from "./../../../util/commonUtil/commonUtil";
import Axios from "axios";
import { alertSnackBar } from "../../../redux/actions/customerActions";

const useStyles = makeStyles((theme) => ({
  dilougDiv: {
    background: "#F7F7F7",
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  appBar: {
    position: "fixed",
    background: "#53E7BC",
    marginBottom: "1rem",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1100,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
    width: "90%",
    margin: "auto",
  },
  headingSec: {
    padding: theme.spacing(2),
    position: "sticky",
    top: 0,
    color: "#4A4A4A",
    fontSize: "1.75rem",
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
  },
  sectionDiv: {
    padding: theme.spacing(2),
  },
  gridSection: {
    paddingTop: 100,
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // marginTop: '20px',
      // background: '#F7F7F7',
    },
  },
  submitButton: {
    textTransform: "none",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  cancelButton: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    textTransform: "none",
    "&:hover": {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
  sellingTitle: {
    textAlign: "initial",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#666666",
  },
  radioGroup: {
    "& .MuiTypography-body1": {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#666666",
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
  },
  uploadInput: {
    display: "none",
  },
  uploadImageButton: {
    textTransform: "none",
    fontFamily: "'Fira Sans', sans-serif",
    background: "#4AD0AA",
    margin: "20px",
    color: "#FFFFFF",
    fontWeight: 600,
    marginBottom: 0,
    "&:hover": {
      background: "#4AD0AA",
    },
  },
  bargainDiv: {
    display: "flex",
    border: "1px solid #d6d6d6",
    width: "85%",
    color: "#212529",
    borderRadius: "4px",
    padding: "6px",
    backgroundColor: "#f8f8f8",
  },
  dateDiv: {
    display: "flex",
    border: "1px solid #d6d6d6",
    width: "80%",
    color: "#212529",
    borderRadius: "4px",
    padding: "6px",
    backgroundColor: "#f8f8f8",
    marginLeft: "12px",
  },
  date: {
    border: "none",
    width: "100%",
    outline: "none",
    fontSize: "13px",
    fontFamily: "'Fira Sans', sans-serif",
    opacity: 0.54,
    backgroundColor: "#f8f8f8",
  },
  inputErrorBlock: {
    fontSize: "13px",
    color: "red",
    width: "80%",
    margin: "auto",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
  },
  notFoundBlock: {
    width: "80%",
    margin: "auto",
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    background: "#F7F7F7",
    textAlign: "center",
  },
  notFoundText: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#747474",
    fontFamily: "'Fira Sans', sans-serif",
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    fontFamily: "sans-serif",
    fontSize: "14px",
    height: "50px",
    "& .react-date-picker__wrapper": {
      padding: "0 10px",
      borderColor: "#ccc",
      borderRadius: "4px",
    },
    "& .react-date-picker--open": {
      borderColor: "red",
    },
  },
  label: {
    marginTop: "-7px",
    marginLeft: "12px",
    backgroundColor: "white",
    position: "absolute",
    zIndex: 6,
    fontSize: "11px",
    fontFamily: "sans-serif",
    paddingLeft: "10px",
  },
  totalQtyDiv: {
    "& .MuiFormControl-root": {
      width: "85%",
      margin: "0px",
      marginTop: "16px",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NUMERICSPEL_ONLY = /^[0-9.]+$/;
const AlPHANUMERIC_ONLY = /^[ A-Za-z0-9_@./#&+-]+$/;
const NUMERIC_ONLY = /^[0-9.]+$/;
const NUM_ONLY = /^[0-9]+$/;

const EditProductForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const textRef = React.useRef();
  const { account } = useSelector((state) => state.auth);
  const { categorylist } = useSelector((state) => state.seller);
  const { open, handleEditClose, product, handleEditProduct } = props;

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState({ code: "", id: "" });
  const [sku, setSku] = useState("");
  const [brand, setBrand] = useState("");
  const [idealFor, setIdealFor] = useState("");
  const [productGroup, setProductGroup] = useState("");
  const [hsn, setHsn] = useState("");
  const [countryOfOrigin, setCountyOfOrigin] = useState("India");
  const [season, setSeason] = useState("");
  const [seasonYear, setSeasonYear] = useState(new Date());
  const [taxRate, setTaxRate] = useState(0);
  const [fashionGroup, setFashionGroup] = useState("");
  const [type, setType] = useState("");
  const [startBargain, setStartBargain] = useState("");
  const [endBargain, setEndBargain] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [quantity, setQuantity] = useState("");
  const [packagingType, setPackagingType] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  // const [sellfast, setSellfast] = useState(false);
  const [bundle, setBundle] = useState("");
  const [sellfast, setSellfast] = useState("NO");
  const [buyable, setBuyAble] = useState(true);
  const [keyWords, setKeyWords] = useState("");
  const [hsnErr, setHsnErr] = useState("");
  const [hsnVerified, setHsnVerified] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState("");

  useEffect(() => {
    if (Object.keys(product).length !== 0) {
      setName(product.description.name);
      setTitle(product.description.title);
      setSelectedCategoryLabel(product.categories[0].code);
      setCategory((prevState) => ({
        ...prevState,
        code: product.categories[0].code,
        id: product.categories[0].id,
      }));

      setSku(product.sku);
      setBrand(product.brandName);
      setIdealFor(product.collectionCategory);
      setProductGroup(product.productGroup);
      setHsn(product.hsn);
      setCountyOfOrigin(product.countryOfOrigin);
      setSeason(product.season);
      setSeasonYear(Date.parse(product.seasonYear));
      // console.log("products",product);
      setTaxRate(product.taxRate);
      setFashionGroup(product.fashionGroup);
      setType(product.bargain);
      setStartBargain(product.bargainStartDateTime);
      setEndBargain(product.bargainEndDateTime);
      setFromDate(Date.parse(product.dateAvailable));
      setQuantity(product.quantity);
      setPackagingType(product.packagingSpecifications.packagingType);
      setWeight(product.packagingSpecifications.weight);
      setLength(product.packagingSpecifications.length);
      setWidth(product.packagingSpecifications.width);
      setHeight(product.packagingSpecifications.height);
      setSellfast(product.sellItFaster);
      setBuyAble(product.buyable);
      setKeyWords(product.description.keyWords);
      setBundle(product.bundleType);
    }
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  //basic form vaildation
  const handleFormValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!name) {
      inputsValid = false;
      formErrors["name"] = "Product name is required.";
    }

    // if (!AlPHANUMERIC_ONLY.test(name) || NUMERIC_ONLY.test(name)) {
    //   inputsValid = false;
    //   formErrors["name"] = "Please enter valid product name";
    // }

    // if (!AlPHANUMERIC_ONLY.test(title) || NUMERIC_ONLY.test(title)) {
    //   inputsValid = false;
    //   formErrors["title"] = "Please enter valid title";
    // }

    if (!title) {
      inputsValid = false;
      formErrors["title"] = "Product title is required";
    }
    if (!category) {
      inputsValid = false;
      formErrors["category"] = "Select the category";
    }
    if (!sku) {
      inputsValid = false;
      formErrors["sku"] = "This field is required";
    }
    if (!brand) {
      inputsValid = false;
      formErrors["brand"] = "This field is required";
    }
    if (selectedCategoryLabel !== "toys" && !idealFor) {
      inputsValid = false;
      formErrors["idealFor"] = "This field is required";
    }

    if (!hsn) {
      inputsValid = false;
      formErrors["hsn"] = "Product HSN required";
    }

    if (!bundle) {
      inputsValid = false;
      formErrors["bundle"] = "Bundle type is required";
    }

    if (type === "YES") {
      if (!startBargain) {
        inputsValid = false;
        formErrors["startBargain"] = "This field is required";
      }

      if (!endBargain) {
        inputsValid = false;
        formErrors["endBargain"] = "This field is required";
      }
    }

    if (!fromDate) {
      inputsValid = false;
      formErrors["fromDate"] = "This field is required";
    }

    if (quantity <= 0) {
      inputsValid = false;
      formErrors["quantity"] = "This field is required";
    }

    if (!sellfast) {
      inputsValid = false;
      formErrors["sellfast"] = "This field is required";
    }
    if (!packagingType) {
      inputsValid = false;
      formErrors["packagingType"] = "This field is required";
    }

    if (!weight || weight <= 0) {
      inputsValid = false;
      formErrors["weight"] = "This field is required";
    }

    if (!length || length <= 0) {
      inputsValid = false;
      formErrors["length"] = "This field is required";
    }

    if (!width || width <= 0) {
      inputsValid = false;
      formErrors["width"] = "This field is required";
    }

    if (!height || height <= 0) {
      inputsValid = false;
      formErrors["height"] = "This field is required";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  useEffect(() => {
    const handleCheckHsncode = async (hsnData) => {
      const { data } = await Axios.get(
        `${process.env.REACT_APP_HSN_VALIDATION}/ywc_cas_bng_gg_stxb_hsn_desc?hsn_code=${hsn}`,{
          headers: {
            'Accept-Encoding': 'gzip',
          }
        }
      );
      if (data.Status === "false") {
        setHsnVerified(false);
        setHsnErr("Product HSN code is Invalid");
      } else {
        setHsnErr("");
        setHsnVerified(true);
      }
    };
    hsn && handleCheckHsncode(hsn);
  }, [hsn]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (handleFormValidation()) {
      const editData = {
        price: 0,
        quantity: quantity,
        sku: sku,
        productPriceType: type,
        bargain: type,
        sellItFaster: sellfast,
        productLength: length,
        productWidth: width,
        productHeight: height,
        productWeight: weight,
        buyable: buyable,
        bundleType: bundle,
        bargainEndDateTime: type === "YES" ? new Date(endBargain) : "",
        bargainStartDateTime: type === "YES" ? new Date(startBargain) : "",
        dateAvailable: new Date(fromDate),
        brandName: brand,
        collectionCategory: idealFor,
        countryOfOrigin: countryOfOrigin,
        fashionGroup: fashionGroup || "abc",
        hsn: hsn,
        packagingSpecifications: {
          dimensionUnitOfMeasure: "cm",
          height: height,
          id: 0,
          length: length,
          packagingType: packagingType,
          weight: weight,
          weightUnitOfMeasure: "g",
          width: width,
        },
        description: {
          language: "en",
          name: name,
          title: title,
          description: "",
          friendlyUrl: "",
          highlights: "",
          id: 0,
          keyWords: keyWords,
          metaDescription: "",
        },
        categories: [
          {
            code: category.code,
            id: category.id,
          },
        ],
        productUserDetail: {
          id: 0,
          userId: account.id,
          userName: account.userName,
          firstName: account.firstName,
          lastName: account.lastName,
          emailAddress: account.emailAddress,
          userType: account.userType,
        },
        productGroup: productGroup || "xyz",
        season: season,
        seasonYear: seasonYear || "2024",
        taxRate: taxRate || "0",
      };
      if (hsnVerified) {
        handleEditProduct(editData, product.id);
      } else {
        textRef.current.focus();
        dispatch(alertSnackBar("error", "Product HSN code is Invalid."));
      }
    }
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedCategoryLabel === "toys") {
      setIdealFor("Kids");
    } else {
      setIdealFor(selectedValue);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleEditClose}
      TransitionComponent={Transition}
      className={classes.dilougDiv}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleEditClose}
            aria-label="close"
            style={{
              outline: "none",
              border: "2px solid #000000",
              padding: 0,
              color: "#000000",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Back
          </Typography>
        </Toolbar>
      </AppBar>

      {product && Object.keys(product).length !== 0 ? (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={3} className={classes.gridSection}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.headingSec}>
                Basic Details
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <div className={classes.sectionDiv}>
                  <TextField
                    id="name"
                    name="name"
                    label="Product Name *"
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.name ? formErrors.name : ""}
                  />

                  <TextField
                    id="title"
                    name="title"
                    label="Product Title *"
                    variant="outlined"
                    size="small"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.title ? formErrors.title : ""}
                  />

                  <FormControl variant="outlined" size="small" disabled>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      className={classes.labelRoot}
                    >
                      Select Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={category.code}
                      onChange={(e) => setCategory(e.target.value)}
                      className={classes.selecteCategory}
                      label="Select Category *"
                    >
                      {categorylist &&
                        categorylist.map((item) => (
                          <MenuItem key={item.id} value={item.code}>
                            <em>{capitalizeFirstLetter(item.code)}</em>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <TextField
                    disabled
                    id="sku"
                    name="sku"
                    label="Parent SKU"
                    variant="outlined"
                    size="small"
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.sku ? formErrors.sku : ""}
                  />

                  <TextField
                    id="brand"
                    name="brand"
                    label="Brand *"
                    variant="outlined"
                    size="small"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.brand ? formErrors.brand : ""}
                  />

                  <FormControl variant="outlined" size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.labelRoot}
                    >
                      Ideal For *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={idealFor}
                      onChange={handleChange}
                      className={classes.selecteCategory}
                      label="Select Gender"
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      <MenuItem
                        value="Men"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Men
                      </MenuItem>
                      <MenuItem
                        value="Women"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Women
                      </MenuItem>
                      <MenuItem
                        value="Kids"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Kids
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {formErrors.idealFor ? formErrors.idealFor : ""}
                    </FormHelperText>
                  </FormControl>

                  {selectedCategoryLabel !== "toys" && (
                    <FormControl variant="outlined" size="small">
                      <InputLabel
                        id="demo-simple-select-label"
                        className={classes.labelRoot}
                      >
                        Product Groups *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={productGroup}
                        onChange={(e) => setProductGroup(e.target.value)}
                        className={classes.selecteCategory}
                        label="Product Group"
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem
                          value="Casual"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Casual
                        </MenuItem>
                        <MenuItem
                          value="Evening"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Evening
                        </MenuItem>
                        <MenuItem
                          value="Occasion"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Occasion
                        </MenuItem>
                        <MenuItem
                          value="Work"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Work
                        </MenuItem>
                        <MenuItem
                          value="Active"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Active
                        </MenuItem>
                        <MenuItem
                          value="Universal"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Universal
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}

                  {(selectedCategoryLabel === "sneakers" ||
                    selectedCategoryLabel === "streetwears") && (
                    <FormControl variant="outlined" size="small" disabled>
                      <InputLabel
                        id="demo-simple-select-label"
                        className={classes.labelRoot}
                      >
                        Bundle Type *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={bundle}
                        onChange={(e) => setBundle(e.target.value)}
                        className={classes.selecteCategory}
                        label="Select Bundle Type"
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem
                          value="colors"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Pack of Colors
                        </MenuItem>

                        <MenuItem
                          value="sizes"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Pack of Sizes
                        </MenuItem>
                      </Select>
                      {formErrors.bundle ? formErrors.bundle : ""}
                    </FormControl>
                  )}

                  <TextField
                    id="hsn"
                    name="hsn"
                    type="text"
                    autoComplete="off"
                    label="HSN Code *"
                    variant="outlined"
                    size="small"
                    inputRef={textRef}
                    value={hsn}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && !AlPHANUMERIC_ONLY.test(value)) {
                        return;
                      }

                      setHsn(e.target.value);
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={
                      formErrors.hsn ? formErrors.hsn : hsnErr ? hsnErr : ""
                    }
                  />
                  {hsnVerified && (
                    <Typography
                      variant="subtitle1"
                      size="small"
                      style={{
                        color: "green",
                        fontSize: "12px",
                        paddingLeft: "20px",
                      }}
                    >
                      Product HSN code is valid
                    </Typography>
                  )}

                  <TextField
                    id="countryOfOrigin"
                    name="countryOfOrigin"
                    label="Country of Origin"
                    variant="outlined"
                    size="small"
                    value={countryOfOrigin}
                    onClick={(e) => setCountyOfOrigin(e.target.value)}
                    disabled={true}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={
                      formErrors.countryOfOrigin
                        ? formErrors.countryOfOrigin
                        : ""
                    }
                  />

                  {selectedCategoryLabel !== "toys" && (
                    <FormControl variant="outlined" size="small">
                      <InputLabel
                        id="demo-simple-select-label"
                        className={classes.labelRoot}
                      >
                        Season
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={season}
                        onChange={(e) => setSeason(e.target.value)}
                        className={classes.selecteCategory}
                        label="Select Season"
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem
                          value="Summer"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Summer
                        </MenuItem>
                        <MenuItem
                          value="Winter"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Winter
                        </MenuItem>
                        <MenuItem
                          value="Core"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Core
                        </MenuItem>
                        <MenuItem
                          value="Festive"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Festive
                        </MenuItem>
                        <MenuItem
                          value="Monsoon"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Monsoon
                        </MenuItem>
                        <MenuItem
                          value="Fall"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Fall
                        </MenuItem>
                        <MenuItem
                          value="Spring"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Spring
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}

                  {selectedCategoryLabel !== "toys" && (
                    <Box className={classes.dateDiv}>
                      <DateRangeIcon />

                      <DatePicker
                        placeholderText="Season year "
                        selected={seasonYear}
                        onChange={(date) => setSeasonYear(date)}
                        showYearPicker
                        dateFormat="yyyy"
                        className={classes.date}
                      />
                    </Box>
                  )}

                  {selectedCategoryLabel !== "toys" && (
                    <TextField
                      id="taxRate"
                      name="taxRate"
                      label="Tax Rate"
                      variant="outlined"
                      size="small"
                      type="tel"
                      value={taxRate}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                          return;
                        }
                        setTaxRate(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 100 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                    />
                  )}

                  {selectedCategoryLabel !== "toys" && (
                    <FormControl variant="outlined" size="small">
                      <InputLabel
                        id="demo-simple-select-label"
                        className={classes.labelRoot}
                      >
                        Fashion Group *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={fashionGroup}
                        onChange={(e) => setFashionGroup(e.target.value)}
                        className={classes.selecteCategory}
                        label="Select Category *"
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem
                          value="FASHION"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Fashion
                        </MenuItem>
                        <MenuItem
                          value="CORE"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                        >
                          Core
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        {formErrors.fashionGroup ? formErrors.fashionGroup : ""}
                      </FormHelperText>
                    </FormControl>
                  )}

                  <TextField
                    id="keyWords"
                    name="keyWords"
                    label="Keywords"
                    variant="outlined"
                    size="small"
                    value={keyWords}
                    onChange={(e) => setKeyWords(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  />
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={classes.headingSec}>
                Selling Details
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <div className={classes.sectionDiv}>
                  <FormControl
                    component="fieldset"
                    style={{ background: "none" }}
                  >
                    <FormLabel
                      component="legend"
                      className={classes.sellingTitle}
                    >
                      {account.userType !== "MANU" && "Bargain /"} Fixed Price*{" "}
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      className={classes.radioGroup}
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {account.userType !== "MANU" && (
                        <FormControlLabel
                          value="YES"
                          control={<Radio color="primary" size="small" />}
                          label="Bargain"
                        />
                      )}

                      <FormControlLabel
                        value="NO"
                        control={<Radio color="primary" size="small" />}
                        label="Fixed"
                      />
                    </RadioGroup>

                    <FormHelperText>
                      {formErrors.type ? formErrors.type : ""}
                    </FormHelperText>
                  </FormControl>

                  {type === "YES" && (
                    <div>
                      <div>
                        <label className={classes.label}>Bargain Start*</label>
                        <Box className={classes.bargainDiv}>
                          <DateRangeIcon />
                          <DatePicker
                            placeholderText="Bargain Start"
                            selected={
                              startBargain ? new Date(startBargain) : ""
                            }
                            showTimeSelect
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            minDate={new Date()}
                            onChange={(date) => setStartBargain(date)}
                            className={classes.date}
                          />
                        </Box>

                        {formErrors.startBargain && (
                          <Box className={classes.inputErrorBlock}>
                            {formErrors.startBargain}
                          </Box>
                        )}
                      </div>

                      <div style={{ marginTop: "16px" }}>
                        <label className={classes.label}>Bargain End*</label>
                        <Box className={classes.bargainDiv}>
                          <DateRangeIcon />

                          <DatePicker
                            placeholderText="Bargain End"
                            selected={endBargain ? new Date(endBargain) : ""}
                            showTimeSelect
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            minDate={new Date()}
                            onChange={(date) => setEndBargain(date)}
                            className={classes.date}
                          />
                        </Box>

                        {formErrors.endBargain && (
                          <Box className={classes.inputErrorBlock}>
                            {formErrors.endBargain}
                          </Box>
                        )}
                      </div>
                    </div>
                  )}

                  <Grid container spacing={1} style={{ marginTop: "16px" }}>
                    <Grid item xs>
                      <div>
                        <label className={classes.label}>From*</label>
                        <Box className={classes.dateDiv}>
                          <DateRangeIcon />

                          <DatePicker
                            placeholderText="From"
                            className={classes.date}
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            minDate={new Date()}
                          />
                        </Box>

                        {formErrors.fromDate && (
                          <Box className={classes.inputErrorBlock}>
                            {formErrors.fromDate}
                          </Box>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <TextField
                    id="quantity"
                    name="quantity"
                    type="tel"
                    label="Total Quantity *"
                    variant="outlined"
                    size="small"
                    value={quantity}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && !NUM_ONLY.test(value)) {
                        return;
                      }
                      setQuantity(e.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1, max: 999999 } }}
                    onKeyUp={(e) => validatePositiveNumberEvent(e)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.quantity ? formErrors.quantity : ""}
                  />
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={classes.headingSec}>
                Shipping Details
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <div className={classes.sectionDiv}>
                  <FormControl variant="outlined" size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.labelRoot}
                    >
                      Package Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={packagingType}
                      onChange={(e) => setPackagingType(e.target.value)}
                      className={classes.selecteCategory}
                      label="Select Category"
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      <MenuItem
                        value="Loose"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Loose
                      </MenuItem>
                      <MenuItem
                        value="Boxed"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Boxed
                      </MenuItem>
                      <MenuItem
                        value="Non-woven Bag"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Non-woven Bag
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {formErrors.packagingType ? formErrors.packagingType : ""}
                    </FormHelperText>
                  </FormControl>
                  <TextField
                    id="weight"
                    name="weight"
                    type="tel"
                    label="Package Weight in grams *"
                    variant="outlined"
                    size="small"
                    value={weight}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                        return;
                      }
                      setWeight(e.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    onKeyUp={(e) => validatePositiveNumberEvent(e)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.weight ? formErrors.weight : ""}
                  />

                  <TextField
                    id="length"
                    name="length"
                    type="tel"
                    label="Product Length in cm *"
                    variant="outlined"
                    size="small"
                    value={length}
                    InputProps={{ inputProps: { min: 1, max: 100 } }}
                    onKeyUp={(e) => validatePositiveNumberEvent(e)}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                        return;
                      }
                      setLength(e.target.value);
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.length ? formErrors.length : ""}
                  />

                  <TextField
                    id="width"
                    name="width"
                    type="tel"
                    label="Product Width in cm *"
                    variant="outlined"
                    size="small"
                    value={width}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                        return;
                      }
                      setWidth(e.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1, max: 100 } }}
                    onKeyUp={(e) => validatePositiveNumberEvent(e)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.width ? formErrors.width : ""}
                  />

                  <TextField
                    id="height"
                    name="height"
                    type="tel"
                    label="Product Height in cm *"
                    variant="outlined"
                    size="small"
                    value={height}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                        return;
                      }
                      setHeight(e.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1, max: 100 } }}
                    onKeyUp={(e) => validatePositiveNumberEvent(e)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.height ? formErrors.height : ""}
                  />

                  <FormControl
                    component="fieldset"
                    style={{ background: "none" }}
                  >
                    <FormLabel
                      component="legend"
                      className={classes.sellingTitle}
                    >
                      Sell it faster
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      className={classes.radioGroup}
                      // value={sellfast ? "YES" : "NO"}
                      // onChange={(e) =>
                      //   setSellfast(e.target.value === "YES" ? true : false)
                      // }
                      value={sellfast}
                      onChange={(e) => setSellfast(e.target.value)}
                    >
                      <FormControlLabel
                        value="YES"
                        control={<Radio color="primary" size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="NO"
                        control={<Radio color="primary" size="small" />}
                        label="No"
                      />
                    </RadioGroup>

                    <FormHelperText>
                      {formErrors.sellfast ? formErrors.sellfast : ""}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    component="fieldset"
                    style={{ background: "none" }}
                  >
                    <FormLabel
                      component="legend"
                      className={classes.sellingTitle}
                    >
                      Buyable
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      className={classes.radioGroup}
                      value={buyable ? "YES" : "NO"}
                      onChange={(e) =>
                        setBuyAble(e.target.value === "YES" ? true : false)
                      }
                    >
                      <FormControlLabel
                        value="YES"
                        control={<Radio color="primary" size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="NO"
                        control={<Radio color="primary" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div className={classes.buttonGroup}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.submitButton}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className={classes.cancelButton}
                  onClick={handleEditClose}
                >
                  cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Paper className={classes.notFoundBlock}>
          <Typography component="p" className={classes.notFoundText}>
            Product not found for this ID.
          </Typography>
        </Paper>
      )}
    </Dialog>
  );
};
export default React.memo(EditProductForm);
