import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux actions
import { getBannerImages } from "../redux/actions/homepageActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles((theme) => ({
  section: {
    width: "100%",
    height: "44vh",
    // height: "100vh",
    display: "flex",
    backgroundColor: "#fff",
    position: "relative",
    marginTop: 80,
    overflow: "hidden",

    "@media(max-Width: 780px)": {
      width: "100%",
      height: "35vh",
    },
    "@media(max-Width: 500px)": {
      width: "100%",
      height: "30vh",
    },
    "@media(max-Width: 200px)": {
      width: "100%",
      height: "28vh",
    },
  },
  leftArrow: {
    width: 50,
    height: 50,
    backgroundColor: "#f7eeee",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 10,
    margin: "auto",
    cursor: "pointer",
    opacity: 0.5,
    zIndex: 2,
  },
  rightArrow: {
    width: 50,
    height: 50,
    backgroundColor: "#f7eeee",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 10,
    margin: "auto",
    cursor: "pointer",
    opacity: 0.5,
    zIndex: 2,
  },
  wrapper: {
    height: "100%",
    display: "flex",
    transition: "all 1.5s ease",
  },
  slide: {
    width: "100vw",
    alignItems: "center",
    display: "flex",
  },
  imageContainer: {
    height: "100%",
    flex: 1,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  
}));

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

const Banners = (props) => {
  const classes = useStyles();
  const { type } = props;
  const { bannerImages } = useSelector((state) => state?.home || []);
  const dispatch = useDispatch();

  const [slideIndex, setSlideIndex] = useState(0);
  const [slideDone, setSlideDone] = useState(true);
  const [timeID, setTimeID] = useState(null);

  useEffect(() => {
    dispatch(getBannerImages());
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      setTimeID(
        setTimeout(() => {
          slidePrev();
          setSlideDone(true);
        }, 5000)
      );
    }
  }, [bannerImages, slideDone]);

  // const slideNext = () => {
  //   setSlideIndex((val) => {
  //     if (val <= 0) {
  //       return (
  //         bannerImages &&
  //         bannerImages
  //           .filter((object) => object.bannerName === type)
  //           .map((user) => user.totalCount - 1)
  //       );
  //     } else {
  //       return val - 1;
  //     }
  //   });
  // };
  const slideNext = () => {
    setSlideIndex((val) => {
      const filteredImages =
        bannerImages &&
        bannerImages.filter((object) => object.bannerName === type)[0];

      const totalImageCount =
        filteredImages && filteredImages.bannerImages.filter((data) => data.active === true).length;

      return totalImageCount > 0 ? (val - 1 + totalImageCount) % totalImageCount : val;
    });
  };


  // const slidePrev = () => {
  //   setSlideIndex((val) => {
  //     const totalImageCount =
  //       bannerImages &&
  //       bannerImages
  //         .filter((object) => object.bannerName === type)
  //         .map((user) => user.totalCount - 1);
  //     if (val >= totalImageCount) {
  //       return 0;
  //     } else {
  //       return val + 1;
  //     }
  //   });
  // };
  const slidePrev = () => {
    setSlideIndex((val) => {
      const filteredImages =
        bannerImages &&
        bannerImages.filter((object) => object.bannerName === type)[0];

      const totalImageCount =
        filteredImages && filteredImages.bannerImages.filter((data) => data.active === true).length;

      return totalImageCount > 0 ? (val + 1) % totalImageCount : val;
    });
  };


  const AutoPlayStop = () => {
    if (timeID > 0) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  return (
    <section className={classes.section}>
      {bannerImages?.length > 0 &&
        bannerImages
          .filter((object) => object.bannerName === type)
          .map((item, index) => (
            <Fragment key={index}>
              {item.activeImagesCount > 0 &&
                item.bannerImages
                  .filter((data) => data.active === true)
                  .map((image, index) => (
                    <Fragment key={index}>
                      <div
                        className={classes.leftArrow}
                        onClick={(e) => {
                          e.preventDefault();
                          slidePrev();
                        }}
                      >
                        <ArrowRightIcon />
                      </div>

                      <div
                        className={classes.wrapper}
                        onMouseEnter={AutoPlayStop}
                        onMouseLeave={AutoPlayStart}
                        style={{
                          transform: `translateX(${slideIndex * -100}vw)`,
                        }}
                      >
                        <div className={classes.slide}>
                          <div className={classes.imageContainer}>
                            <img
                              src={`${imageBaseUrl}${image.bannerImageUrl}`}
                              alt={image.bannerImageName}
                              key={image.id}
                              className={classes.img}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={classes.rightArrow}
                        onClick={(e) => {
                          e.preventDefault();
                          slideNext();
                        }}
                      >
                        <ArrowLeftIcon />
                      </div>
                    </Fragment>
                  ))}
            </Fragment>
          ))}
    </section>
  );
};

export default React.memo(Banners);