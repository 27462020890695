import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 8,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // Soft shadow around the dialog
  },
  dialogTitle: {
    fontWeight: 600,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  brand: {
    color: "#27B23E",
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
  title: {
    fontWeight: 600,
    marginTop: theme.spacing(0.5),
    fontSize: "1.1rem", // Make product name slightly larger
  },
  orderStatus: {
    color: "#999",
    fontSize: "0.9rem", // Slightly smaller text for the status
  },
  address: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 8,
    border: "1px solid #e0e0e0",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  image: {
    width: "120px", // Resize image to a smaller size
    height: "120px",
    objectFit: "contain", // Ensure the image maintains its aspect ratio
    borderRadius: 8,
    border: "1px solid #ddd", // Border around the image
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Soft shadow around the image
  },
  sectionTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  textSection: {
    marginBottom: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(1, 2),
    justifyContent: "center",
  },
}));

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const ViewOrderDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const bunnyCdnUrl = process.env.REACT_APP_BUNNY_CDN_URL;
  const { open, handleClose, order } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.dialog}
    >
      {Object.keys(order?.details).length > 0 && (
        <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
          {`Order Details (${order.details.orderId})`}
        </DialogTitle>
      )}
      <DialogContent style={{ textAlign: "center" }}>
        {Object.keys(order.details).length > 0 && (
          <div className={classes.textSection}>
            <div className={classes.imageWrapper}>
              <img
                alt="product"
                src={order?.details?.product?.attributes[0]?.attributeOptionValues[0]?.images[0]?.imageUrl?.includes(bunnyCdnUrl) ? `${order?.details?.product?.attributes[0]?.attributeOptionValues[0]?.images[0]?.imageUrl}` : `${bunnyCdnUrl}${order?.details?.product?.attributes[0]?.attributeOptionValues[0]?.images[0]?.imageUrl}`}
                className={classes.image}
              />
            </div>

            <Typography variant="subtitle2" className={classes.brand}>
              {order.details.product.brandName}
            </Typography>

            <Typography variant="h6" className={classes.title}>
              {order.details.productName}
            </Typography>

            <Typography variant="caption" className={classes.orderStatus}>
              {order.details.orderStatus}
            </Typography>
          </div>
        )}

        {Object.keys(order.delivery).length > 0 && (
          <div className={classes.address}>
            <Typography variant="subtitle2" className={classes.sectionTitle}>
              Delivery Address
            </Typography>
            <Typography variant="caption">
              {order.delivery.firstName} {order.delivery.lastName},{" "}
              {order.delivery.address}, {order.delivery.city},{" "}
              {order.delivery.postalCode}, ph-{order.delivery.phone}
            </Typography>
          </div>
        )}

        {Object.keys(order.billing).length > 0 && (
          <div className={classes.address}>
            <Typography variant="subtitle2" className={classes.sectionTitle}>
              Billing Address
            </Typography>
            <Typography variant="caption">
              {order.billing.firstName} {order.billing.lastName},{" "}
              {order.billing.address}, {order.billing.city},{" "}
              {order.billing.postalCode}, ph-{order.billing.phone}
            </Typography>
          </div>
        )}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
