import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/* Imprort react components */
import { EditReview } from "../EditReview";
import Spinner from "../../../util/spinner/spinner";
import { Paginator } from "../../../components/Paginator";
import moment from "moment";
/* Import redux actions */
import {
  deleteReview,
  getCustomerReviews,
} from "../../../redux/actions/customerActions";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";

/* material ui css block */
const useStyles = makeStyles((theme) => ({
  reviewroot: {
    flexGrow: 1,
    border: "solid 1px #d8d8d8",
    borderRadius: "4px",
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  labletext: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#5e5e5e",
    lineHeight: 1.29,
  },
  border: {
    border: "1px solid #d8d8d8",
    borderRadius: "4px",
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    margin: "auto",
    fontSize: "16px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    lineHeight: 1.19,
    color: "#383838",
    textTransform: "capitalize",
  },
  verified: {
    textAlign: "center",
    margin: "auto",
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: "bold",
    color: "#36dead",
    lineHeight: 1.25,
  },
  text: {
    textAlign: "center",
    margin: "auto",
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#5e5e5e",
    lineHeight: 1.25,
  },
  reviewtext: {
    margin: "auto",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.29,
    color: "#5e5e5e",
  },
  review: {
    margin: "auto",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    lineHeight: 1.29,
    color: "#5e5e5e",
  },
  tagstitle: {
    margin: "auto",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    lineHeight: 1.29,
    color: "#5e5e5e",
  },
  tagstext: {
    margin: "auto",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    lineHeight: 1.29,
    color: "#5e5e5e",
  },
  notfound: {
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    lineHeight: 1.71,
    textAlign: "center",
  },
  editreview: {
    backgroundColor: "#53e7bc",
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#53e7bc",
      color: "#ffffff",
    },
  },
  deletereview: {
    backgroundColor: "#e75380",
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e75380",
      color: "#ffffff",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100px",
  },
  imagegrid: {
    padding: theme.spacing(1),
  },
}));

/* image base url */
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
const bunnyCdnUrl = process.env.REACT_APP_BUNNY_CDN_URL;

export const ReviewsRatings = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("cust"));
  const { reviews, loading, error } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const [openEdit, setEdit] = useState(false);
  const [review, setReview] = useState({});

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
  };

  /* handle function to delete review */
  const handleDeleteReview = (review) => {
    dispatch(deleteReview(review.id, review.productId));
  };

  const handleEditReview = (review) => {
    setEdit(true);
    setReview(review);
  };

  const handleCloseEdit = () => {
    setEdit(false);
  };

  useEffect(() => {
    dispatch(getCustomerReviews(user.id));
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <div>
      <Typography className={classes.labletext}>
        My Reviews and Ratings  
      </Typography>

      {loading ? (
        <Spinner />
      ) : error ? (
        <div className={classes.border}>
          <Typography className={classes.notfound}>{error}</Typography>
        </div>
      ) : reviews && reviews.length > 0 ? (
        <>
          <div className={classes.reviewroot}>
            {reviews.sort((a,b) => new Date(b.date) - new Date(a.date))
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((review, index) => (
                <div className={classes.border} key={index}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <div style={{ width: 200 }}>
                        <Typography className={classes.title}>
                          {review.product.description.title}
                        </Typography>
                        <div className={classes.imagegrid}>
                          <img
                            className={classes.img}
                            alt={review.product.description.title}
                            src={review?.product?.attributes[0]?.attributeOptionValues[0]?.images[0]?.imageUrl?.includes(bunnyCdnUrl) ? `${review?.product?.attributes[0]?.attributeOptionValues[0]?.images[0]?.imageUrl}` : `${bunnyCdnUrl}${review?.product?.attributes[0]?.attributeOptionValues[0]?.images[0]?.imageUrl}`}
                          />
                        </div>
                        <Typography className={classes.verified}>
                          VERIFIED PURCHASE
                        </Typography>
                        <Typography className={classes.text}>
                          {moment(review.date).format('DD-MM-YYYY')}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm container>
                      <Grid item xs>
                        <Typography className={classes.reviewtext}>
                          {review.description}
                        </Typography>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          container
                          style={{ marginTop: "12px" }}
                        >
                          <Grid item xs>
                            <Typography className={classes.review}>
                              Quality
                            </Typography>
                            <Rating
                              name="size-small"
                              value={review.product_quality}
                              size="small"
                              precision={1}
                              readOnly
                            />
                          </Grid>

                          <Grid item xs>
                            <Typography className={classes.review}>
                              Values
                            </Typography>
                            <Rating
                              name="size-small"
                              value={review.product_values}
                              size="small"
                              precision={1}
                              readOnly
                            />
                          </Grid>

                          <Grid item xs>
                            <Typography className={classes.review}>
                              Matches
                            </Typography>
                            <Rating
                              name="size-small"
                              value={review.product_matches}
                              size="small"
                              precision={1}
                              readOnly
                            />
                          </Grid>
                        </Grid>

                        <Typography>
                          <Box component="span" className={classes.tagstitle}>
                            Pros
                          </Box>
                          <Box component="span" className={classes.tagstext}>
                            : {review.product_pros}
                          </Box>
                        </Typography>

                        <Typography>
                          <Box component="span" className={classes.tagstitle}>
                            Cons
                          </Box>
                          <Box component="span" className={classes.tagstext}>
                            : {review.product_cons}
                          </Box>
                        </Typography>
                      </Grid>

                      <Grid item>
                        <div style={{ margin: 10 }}>
                          <Button
                            className={classes.editreview}
                            onClick={(e) => handleEditReview(review)}
                          >
                            Edit
                          </Button>
                        </div>
                        <div style={{ margin: 10 }}>
                          <Button
                            className={classes.deletereview}
                            onClick={(e) => handleDeleteReview(review)}
                          >
                            Delete
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ))}
          </div>

          {reviews && reviews.length > 0 && reviews.length > itemsPerPage && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paginator
                  page={page}
                  handleChangePage={handleChangePage}
                  count={Math.ceil(reviews.length / itemsPerPage)}
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <div className={classes.border}>
          <Typography className={classes.notfound}>
            Reviews not found.
          </Typography>
        </div>
      )}

      <div>
        <EditReview open={openEdit} onClose={handleCloseEdit} item={review} />
      </div>
    </div>
  );
};
