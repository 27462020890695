import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import XIcon from '@mui/icons-material/X';
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import shopImage from "../assets/images/shop.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "auto",
    backgroundColor: "#333",
    padding: "0 90px 0",
    minHeight: 160,
    "& .MuiListItemText-primary": {
      fontSize: 10,
      color: "#a29e9e",
      fontFamily: "'Fira Sans', sans-serif",
      textTransform: "capitalize",
      lineHeight: "180%",
    },
  },
  heading: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#fff",
    textTransform: "capitalize",
    margin: "5px 0px 10px 0px",
  },
  address: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#fff",
    textTransform: "capitalize",
    margin: "5px 0px 10px 0px",
  },
  text: {
    fontSize: 10,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#a29e9e",
    lineHeight: "180%",
  },
  subheading: {
    fontSize: 10,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "uppercase",
    fontWeight: 500,
    margin: "10px 0 4px 0",
    color: "#a29e9e",
  },
  spancontent: {
    fontSize: 9,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#fff",
  },
  newsletterInput: {
    margin: "10px 0 0 0",
    borderStyle: "none",
    backgroundColor: "#4d4d4d",
    borderRadius: "6px",
    color: "#a29e9e",
  },
  newsletterButton: {
    margin: "16px 0 0 0",
    backgroundColor: "#f00",
    fontSize: 10,
    borderStyle: "none",
    "&:hover": {
      backgroundColor: "#f00",
    },
  },
  socialIconText: {
    fontSize: 10,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "uppercase",
    fontWeight: 500,
    margin: "16px 0 0 0",
    color: "#a29e9e",
  },
  icon: {
    borderRadius: "50%",
    color: "#fff",
    padding: 3,
    fontSize: 24,
    "&:hover": {
      color: "rgba(0, 0, 0, .3)",
      transform: "scale(1.3)",
      transition: "all .2s ease-in-out",
    },
  },
  footer2: {
    width: "100%",
    height: "45px",
    padding: "15px 20px",
    backgroundColor: "#252121",
    position: "relative",
  },
  copyRight: {
    color: "rgb(255, 255, 255)",
    fontSize: "12px",
    textAlign: "center",
  },
  list: {
    paddingTop: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  signUpButton: {
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    height: "40px",
    backgroundColor: "#ff9d00",
  },
  Shop: {
    width: "81px",
    height: "90px",
    margin: "auto",
    objectFit: "cover",
  },
}));

export const Footer = ({ setShowSiteMap, showSiteMap }) => {
  const classes = useStyles();
  // const history = useHistory();

  return (
    <Fragment>
      <div className={classes.footer}>
        <Grid container spacing={2}>
          <Grid item lg={1} md={2} sm={6} xs={12}>
            <img src={shopImage} className={classes.Shop} alt="home" />
          </Grid>

          <Grid item lg={3} md={2} sm={6} xs={12}>
            <Typography variant="h6" className={classes.heading}>
              Are you an established retailer?
            </Typography>

            {/* <Typography variant="h6" className={classes.heading}>
              Want to grow your business in this overcrowded online space?
            </Typography> */}

            <Link
              to="/seller-signup"
              target="_black"
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" className={classes.signUpButton}>
                Become a Seller
              </Button>
            </Link>

            {/* <Grid item xs={12} sm container>
              <Grid item xs container direction="row" spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography component="p" className={classes.subheading}>
                    Phone Number
                  </Typography>
                  <Typography component="span" className={classes.spancontent}>
                    +1 (800)060-07-30
                  </Typography>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography component="p" className={classes.subheading}>
                    Email Address
                  </Typography>
                  <Typography component="span" className={classes.spancontent}>
                    support@stockxbid.com
                  </Typography>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography component="p" className={classes.subheading}>
                    Our Location
                  </Typography>
                  <Typography component="span" className={classes.spancontent}>
                    #29/1,First floor, 6th cross Swimming pool Extn
                    Malleshwaram, Bangalore - 560003
                  </Typography>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography component="p" className={classes.subheading}>
                    Working Hours
                  </Typography>
                  <Typography component="span" className={classes.spancontent}>
                    Mon-Fri 9.30am-6.00pm
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Typography variant="h6" className={classes.heading}>
              {/* Information */}
            </Typography>

            <List className={classes.list}>
              {/* needed */}


              <ListItem
                button
                component={Link}
                to="/terms-and-conditions"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Terms & conditions" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/privacy-policy"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Privacy Policy" />
              </ListItem>
              <ListItem
                button
                component={Button}
                onClick={() => setShowSiteMap(!showSiteMap)}
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Sitemap" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/shipping-policy"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Delivery & Shipping Policy" />
              </ListItem>

              {/* <ListItem
                button
                component={Link}
                to="/meet-our-team"
                className={classes.list}
              >
                <ListItemText primary="Refund Policy" />
              </ListItem> */}
              <ListItem
                button
                component={Link}
                to="/Refund-policy"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Refund Policy" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/about-us"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="About US" />
              </ListItem>

              <ListItem
                button
                component="a" // Use "a" for external links
                href="https://outxtock.com/contact-us" // External link URL
                className={classes.list}
              >
                <ListItemText primary="Contact Us" />
              </ListItem>

              {/* needed */}

              {/* <ListItem button component={Link} to="/" className={classes.list}>
                <ListItemText primary="Support" />
              </ListItem> */}

              {/* <ListItem button component={Link} to="/" className={classes.list}>
                <ListItemText primary="FAQs" />
              </ListItem> */}
            </List>
          </Grid>

          <Grid item lg={4} md={4} sm={6} xs={12}>
            {/* <Typography variant="h6" className={classes.heading}>
              Newsletter
            </Typography>

            <Typography component="p" className={classes.text}>
              Enter your email address below to subscribe to our newsletter and
              keep up to date with discounts and special offers
            </Typography> */}

            <Grid item xs={12} sm container>
              {/* <Grid item xs container direction="row" spacing={2}>
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <TextField
                    id="bankName"
                    name="bankName"
                    variant="outlined"
                    size="small"
                    className={classes.newsletterInput}
                    InputProps={{
                      autoComplete: "off",
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Button
                    variant="outlined"
                    className={classes.newsletterButton}
                  >
                    Subscribe
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>

            <Typography variant="h6" className={classes.heading}>
              Follow Us On
            </Typography>

            <IconButton aria-label="delete">
              <XIcon
                aria-label="twitter.com"
                onClick={() => window.open("https://twitter.com/Stockxbid")}
                fontSize="medium"
                className={classes.icon}
                style={{ backgroundColor: "#000000" }}
              />
            </IconButton>
            <IconButton aria-label="delete">
              <FacebookIcon
                aria-label="facebook.com"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/outxtock?mibextid=ZbWKwL"
                  )
                }
                fontSize="small"
                className={classes.icon}
                style={{ backgroundColor: "#4267B2" }}
              />
            </IconButton>
            <IconButton aria-label="delete">
              <InstagramIcon
                aria-label="instagram.com"
                onClick={() => window.open("https://www.instagram.com/outxtock?igsh=MW5yZHh2bG1ya3FiNA==")}
                fontSize="small"
                className={classes.icon}
                style={{ backgroundColor: "#8a50ca" }}
              />
            </IconButton>
            <IconButton aria-label="delete">
              <LinkedInIcon
                aria-label="Linkedin.com"
                onClick={() => window.open("https://www.Linkedin.com")}
                fontSize="small"
                className={classes.icon}
                style={{ backgroundColor: "#0077B5" }}
              />
            </IconButton>
            <IconButton aria-label="delete">
              <YouTubeIcon
                aria-label="youtube.com"
                onClick={() => window.open("https://youtube.com/@outxtock")}
                fontSize="small"
                className={classes.icon}
                style={{ backgroundColor: "#f00" }}
              />
            </IconButton>
            {/* <Typography variant="h6" className={classes.heading}>
              Address
            </Typography>
            <Typography variant="h6" className={classes.address}>
              29/1,First floor, 6th Cross Rd, Sangeetha Apts, Swimming Pool
              Extension, Malleshwaram 11th cross, Bengaluru, Karnataka 560003.
            </Typography>
            <Typography variant="h6" className={classes.address}>
              Ph: +91 9380272287 
            </Typography> */}
          </Grid>
        </Grid>
      </div>

      <div className={classes.footer2}>
        <Grid item xs={12}>
          <Typography component="p" className={classes.copyRight}>
            © 2024 Stockxbid Technologies Pvt Ltd. All rights reserved.
          </Typography>
        </Grid>
      </div>
    </Fragment>
  );
};
