import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import OtpInput from "react-otp-input";
import axios from "axios";

//redux actions
import { verifySellerOtp } from "../../../redux/actions/authActions";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { ActionTypes } from "./../../../redux/constants/actionTypes";
// import { isNumberKey } from "../../../util/commonUtil/commonUtil";
import Loading from "../../../util/spinner/Loading";

const useStyles = makeStyles((theme) => ({
  otp_form: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
    },
  },
  submitButton: {
    textTransform: "none",
    color: "#FFFFFF",
    background: "#53E7BC",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    width: "75%",
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
  },

  button: {
    marginRight: theme.spacing(1),
  },
  resend: {
    fontSize: 11,
    color: "#27B23E",
    fontFamily: "'Fira Sans', sans-serif",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const mobileRegex = RegExp(/^[6789]\d{9}$/);
const NUMERIC_ONLY = /^[0-9]+$/;
export const OtpLogin = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [phoneExist, setPhoneExist] = useState("");

  const handleInputsValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!mobile) {
      inputsValid = false;
      formErrors["mobile"] = "Please enter your mobile number.";
      dispatch({
        type: ActionTypes.CLEAR_FORM_MESSAGES,
      });
    }

    if (mobile && !mobileRegex.test(mobile)) {
      inputsValid = false;
      formErrors["mobile"] = "Invalid mobile number.";
      dispatch({
        type: ActionTypes.CLEAR_FORM_MESSAGES,
      });
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleRequestOtp = async (event) => {
    event.preventDefault();
    try {
      if (handleInputsValidation()) {
        setLoading(true);
       const result =  await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/login/phoneNumber/${mobile}/otp/generate`
        );
        if(result && result?.data){
          setLoading(false);
          if(result.status ===200)
            {
              // dispatch({
              //   type: ActionTypes.SET_ERROR,
              //   payload: "Something went wrong while generating and sending OTP",
              // });
              setOtp("");
              setActiveStep(1);
            }
        }
        // setOtp("");
        // setActiveStep(1);
      }
    }
     catch (error) {
      // console.log("Error:handleRequestOtp ", error);
      const errString = error.toString();
      setLoading(false)
      if (errString.indexOf('406')) {
          // dispatch({
          //   type: ActionTypes.SET_ERROR,
          //   payload: "User not exist for this mobile number !",
          // });
          formErrors["mobile"] = "User not exist for this mobile number !";
          setFormErrors(formErrors);
          dispatch({
            type: ActionTypes.CLEAR_FORM_MESSAGES,
          });
      }
      else if (errString.indexOf('404')) {
        dispatch({
          type: ActionTypes.SET_ERROR,
          payload: "Something went wrong while generating and sending OTP",
        });
       }
       else if (errString.indexOf('400')) {
        dispatch({
          type: ActionTypes.SET_ERROR,
          payload: "Something went wrong while generating and sending OTP",
        });
       }
       else {
        dispatch({
          type: ActionTypes.SET_ERROR,
          payload: error?.response?.data?.message || "",
        });
      }
    }
  };

  const handleVerifyOtp = (otp) => {
    const otpDto = {
      otp: otp,
    };

    dispatch(verifySellerOtp(mobile, otpDto, history));
  };

  // clear otp inputs
  // const handleResendOtp = () => {
  //   setActiveStep(0);
  //   setOtp([...otp.map((v) => "")]);
  // };

  return (
    <Fragment>
      {activeStep === 0 ? (
        <form onSubmit={handleRequestOtp} className={classes.otp_form}>
          {loading && <Loading />}
          <TextField
            id="mobileNumber"
            name="mobileNumber"
            placeholder="Your Mobile number"
            variant="outlined"
            size="small"
            value={mobile}
            onChange={(e) =>{
              const value = e.target.value;
              if (value !== "" && !NUMERIC_ONLY.test(value)) {
                return;
              }
              setMobile(e.target.value)}}
            inputProps={{ maxLength: 10 }}
            // onKeyUp={ (e) => isNumberKey(e) }
            InputProps={{
              autoComplete: "off",
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
              classes: {
                root: classes.labelRoot,
              },
            }}
            helperText={
              phoneExist
                ? phoneExist
                : formErrors.mobile
                ? formErrors.mobile
                : ""
            }
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            size="small"
            style={{ outline: "none" }}
          >
            Request OTP
          </Button>
        </form>
      ) : (
        <div
          className={classes.otp_form}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "14px" }}>Enter your OTP </p>
          <OtpInput
            onChange={(otp) => {
              if (/^\d*$/.test(otp)) {
                setOtp(otp);
              }
            }}
            value={otp}
            // isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              width: 40,
              height: 40,
              margin: "4px 10px",
              padding: "10px",
              border: "1px solid #F1DBA8",
              borderRadius: "4px",
              textAlign: "center",
              fontSize: 16,
              fontWeight: 600,
              fontFamily: "'Fira Sans', sans-serif",
              outline: "none",
            }}
            focusStyle={{
              border: "1px solid #CFD3DB",
              outline: "none",
            }}
            numInputs={4}
            separator={<span></span>}
            renderInput={(props) => <input {...props} />}
          />
          {/* {otp.map((data, index) => (
            <input
              className={classes.otpinput}
              name="otpField"
              type="text"
              key={index}
              value={data}
              onChange={(e) => handleChange(e.target, index)}
              onFocus={(e) => e.target.select()}
              maxLength="1"
              autoComplete="off"
            />
          ))}*/}

          <Button
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={otp && otp.length !== 4 ? true : false}
            size="small"
            style={{ outline: "none" }}
            onClick={() => handleVerifyOtp(otp)}
          >
            Verify
          </Button>

          <Typography
            component="p"
            style={{
              fontSize: 11,
              margin: 10,
              color: "#212121",
              fontFamily: "'Fira Sans', sans-serif",
            }}
          >
            Not received your OTP ?{" "}
            <Link
              component="button"
              className={classes.resend}
              onClick={handleRequestOtp}
            >
              Resend OTP
            </Link>
          </Typography>
        </div>
      )}
    </Fragment>
  );
};