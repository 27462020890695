import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  withStyles,
  makeStyles,
  useTheme,
  createStyles,
} from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Collapse,
  Box,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  PictureAsPdf as PictureAsPdfIcon,
  GetApp as GetAppIcon,
  Payment as PaymentIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@material-ui/icons";
import { Filter } from "./Filter";
import { ViewOrderDialog } from "./ViewOrderDialog";
import { SalesCommission } from "./SalesCommission";
import { CancelReason } from "../../customer/portfolio/CancelReason";
import {
  cancelOrderseller,
  handleAcceptOrder,
} from "../../../redux/actions/sellerActions";
import Axios from "axios";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d5f9ef",
    color: "#3c3c3c",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 900,
  },
  body: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#2d2b2b",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#eeeeee",
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      "& .MuiButtonBase-root": {
        outline: "none",
      },
    },
    table: {
      minWidth: 650,
    },
    noFound: {
      color: "#030104",
      textAlign: "center",
    },
    viewButton: {
      border: "1px solid #53E7BC",
      borderRadius: "0.375rem",
      background: "transparent",
      color: "#53E7BC",
      padding: "0.8rem",
    },
    filterText: {
      fontFamily: "inherit",
      color: "#3c3c3c",
      textAlign: "right",
      fontWeight: "bold",
    },
    buybutton: {
      borderRadius: "3px",
      backgroundColor: "#53e7bc",
      color: "#ffffff",
      marginTop: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#53e7bc",
        color: "#ffffff",
      },
    },
    dialogBackdrop: {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    dialogTitle: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "20px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#333",
      margin: 0,
      padding: theme.spacing(2, 3),
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(1),
      fontFamily: "'Roboto', sans-serif",
      color: "#555",
      padding: theme.spacing(2, 3),
    },
    dialogInput: {
      padding: theme.spacing(1),
      borderRadius: "4px",
      border: "1px solid #ccc",
      fontFamily: "'Roboto', sans-serif",
      width: "100%",
    },
    dialogError: {
      color: "red",
      fontSize: "14px",
      fontFamily: "'Roboto', sans-serif",
      margin: 0,
    },
    dialogActions: {
      justifyContent: "space-between",
      padding: theme.spacing(2),
    },
    dialogButton: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "14px",
      padding: theme.spacing(0.5, 1.5),
      textTransform: "capitalize",
    },
    dialogButtonCancel: {
      backgroundColor: "#f5f5f5",
      color: "#333",
      border: "none",
      "&:hover": {
        backgroundColor: "#e0e0e0",
      },
    },
    dialogButtonUpload: {
      color: "#fff",
      border: "none",
    },
    fileList: {
      marginTop: theme.spacing(2),
      padding: 0,
      listStyleType: "none",
    },
    fileListItem: {
      padding: theme.spacing(1),
    },
  })
);

export const Orders = () => {
  const classes = useStyles();
  const { orders } = useSelector((state) => state.sales);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentList, setCurrentList] = useState([]);
  const [openOrdersDiloug, setOrdersDiloug] = useState(false);
  const [orderedProduct, setOrderedProduct] = useState("");
  const [openSalesCommission, setOpenSalesCommission] = useState(false);
  const [salesCommission, setSalesCommision] = useState({});
  const [cancelOrderPopUp, setCancelOrderPopUp] = useState(false);
  const [cancelData, setCancelData] = useState({});
  const [filterFlag, setFilterFlag] = useState(false);
  const [itemsState, setItemsState] = useState(
    orders && orders.length > 0 ? orders : []
  );
  const [orderData, setOrderData] = useState({});
  const [filteredItemsState, setFilteredItemsState] = useState(
    orders && orders.length > 0 ? orders : []
  );
  const [openOrderIds, setOpenOrderIds] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const doc_upload = process.env.REACT_APP_DOC_UPLOAD;

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length === 0) {
      setError("Please select at least one file.");
      return;
    }

    const allowedTypes = ["application/pdf", "application/msword", "image/png", "image/jpeg"];
    const invalidFiles = selectedFiles.filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      setError("Some files have unsupported types. Please upload only PDFs, DOCs, or images.");
      return;
    }

    setError("");
    setFiles(prevFiles => {
      const uniqueFiles = selectedFiles.filter(
        file => !prevFiles.some(prevFile => prevFile.name === file.name && prevFile.lastModified === file.lastModified)
      );
      return [...prevFiles, ...uniqueFiles];
    });
  };

  const handleUpload = async () => {
    if (!files) {
      setError("File is required.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`files`, file);
      });

      const response = await Axios.post(`${doc_upload}/ORDERS/${selectedOrder.id}/${selectedProduct.id}`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      )

      if (response?.status === 200) {
        setDialogOpen(false);
        await handleAccept(selectedProduct, selectedOrder);
      } else {
        setError("Failed to upload the document. Please try again.");
      }
    } catch (err) {
      setError("Failed to upload the document. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isDialogOpen) {
      setFiles([]);
      setError("");
    }
  }, [isDialogOpen]);

  const handleSearch = (value) => {
    let newList = [];
    setSearch(value);
    if (value !== "") {
      newList = currentList.filter((item) => {
        const lc = item.id.toString();
        const filter = value.toString();
        setFilterFlag(true);
        return lc.includes(filter);
      });
    } else {
      setFilterFlag(false);
      newList = itemsState;
    }
    setFilteredItemsState(newList);
    setPage(0);
  };

  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const handleToDate = (date) => {
    setToDate(date);
  };

  const handleDateRangeFilter = () => {
    let newList = [];
    if (fromDate !== "" && toDate !== "") {
      let fromString = new Date(fromDate);
      let toString = new Date(toDate);
      let toDatess = new Date(toString.setDate(toString.getDate() + 1));
      if (fromString.toISOString() === toDatess.toISOString()) {
        newList = itemsState.filter(
          (item) =>
            new Date(item.datePurchased).toLocaleDateString() ===
            fromString.toLocaleDateString()
        );
      } else {
        newList = currentList.filter((item) => {
          const itemDate = new Date(item.datePurchased);
          return itemDate >= fromString && itemDate <= toDatess;
        });
      }
      setFilterFlag(true);
    } else {
      newList = itemsState;
    }
    setFilteredItemsState(newList);
    setPage(0);
  };

  const handleClearDateFilter = () => {
    setFromDate("");
    setToDate("");
    setSearch("");
    setFilteredItemsState(itemsState);
    setFilterFlag(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewOrders = (data) => {
    setOrdersDiloug(true);
    setOrderedProduct(data);
  };

  const handleCloseViewOrders = () => {
    setOrdersDiloug(false);
  };

  const handleViewSalesCommission = (item, order) => {
    setSalesCommision(item);
    setOpenSalesCommission(true);
    setOrderData(order);
  };

  const handleCloseSalesCommision = () => {
    setOpenSalesCommission(false);
  };

  const handleViewPurchaseOrder = (orderId, order) => {
    const data = localStorage.getItem("sell");
    const parsedData = JSON.parse(data);
    const id = parsedData.id;
    window.open(
      `${process.env.REACT_APP_PURCHASE_ORDER}/api/v1/purchaseOrder/order/${orderId}/${id}/pdf`,
      "_blank"
    );
  };

  const handleDownloadPurchaseOrder = (orderId, order) => {
    const data = localStorage.getItem("sell");
    const parsedData = JSON.parse(data);
    const id = parsedData.id;
    window.location.href = `${process.env.REACT_APP_PURCHASE_ORDER}/api/v1/purchaseOrder/order/${orderId}/${id}/download/pdf`;
  };

  useEffect(() => {
    setCurrentList(itemsState);
  }, [itemsState]);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredItemsState.length - page * rowsPerPage);

  const seeOrderedProducts = (
    <ViewOrderDialog
      open={openOrdersDiloug}
      order={orderedProduct}
      handleCloseViewOrders={handleCloseViewOrders}
    />
  );

  const displaySalesCommission = (
    <SalesCommission
      open={openSalesCommission}
      handleClose={handleCloseSalesCommision}
      commission={salesCommission}
      order={orderData}
    />
  );

  const dispatch = useDispatch();

  const submitCancelReason = (cancelReason, cancelComments, cancelData) => {
    let { productItemId, orderObj, orderNumber } = cancelData;
    callCancelOrder(
      productItemId,
      orderObj,
      orderNumber,
      cancelReason,
      cancelComments
    );
    setCancelOrderPopUp(false);
  };

  const callCancelOrder = async(
    productItemId,
    orderObj,
    orderNumber,
    cancelReason,
    cancelComments
  ) => {
    let productsObj = orderObj?.products.find(
      (prod) => prod.id === productItemId
    );
    let productsList = createProductsList(
      productsObj,
      cancelReason,
      cancelComments
    );

    let cancelOrderRequest = {
      order: [
        {
          orderId: orderObj.id,
          customerId: orderObj.customer.id,
          products: productsList,
          orderNumber: productsObj.orderNumber,
          orderPriceSummary: {
            taxTotal: productsObj.orderPriceSummary.taxTotal.replace("Rs.", ""),
            basePrice: productsObj.orderPriceSummary.basePrice.replace(
              "Rs.",
              ""
            ),
            total: productsObj.orderPriceSummary.total.replace("Rs.", ""),
            finalPrice: productsObj.orderPriceSummary.finalPrice.replace(
              "Rs.",
              ""
            ),
            subTotal: productsObj.orderPriceSummary.subTotal.replace("Rs.", ""),
            gstRate: productsObj.orderPriceSummary.gstRate.replace("%", ""),
            taxPricePerProduct:
              productsObj.orderPriceSummary.taxPricePerProduct.replace(
                "Rs.",
                ""
              ),
            shippingTotal: productsObj.orderPriceSummary.shippingTotal.replace(
              "Rs.",
              ""
            ),
            hsnCode: productsObj.orderPriceSummary.hsnCode,
          },
          deliveredDate: productsObj.deliveredDate,
          lorNumber: productsObj.lorNumber,
        },
      ],
    };

    try {
      setLoading(true);
      await dispatch(
        cancelOrderseller(
          cancelOrderRequest,
          productItemId,
          orderObj,
          orderNumber,
          orderObj.id,
          orders
        )
      );

    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const createProductsList = (productsObj, cancelReason, cancelComments) => {
    let productAttributesList = [];
    let productUserDetail;

    let attributesList = productsObj?.attributes;
    if (Array.isArray(attributesList) && attributesList.length > 0) {
      attributesList.forEach((ele) => {
        var attributes = {
          productAttributeId: ele.id,
          orderQuantity: productsObj.orderedQuantity,
          orderStatus: "CANCEL",
        };
        productAttributesList.push(attributes);
      });
    }

    if (productsObj?.product?.productUserDetail != null) {
      productUserDetail = {
        userId: productsObj.product.productUserDetail?.userId,
        userName: productsObj.product.productUserDetail?.userName,
        emailAddress: productsObj.product.productUserDetail?.emailAddress,
        userType: productsObj.product.productUserDetail?.userType,
        mid: productsObj.product.productUserDetail?.mid,
      };
    }

    var product = {
      product: {
        orderProductId: productsObj.id,
        cancelComment: cancelComments,
        cancelReason: cancelReason,
        productId: productsObj.product?.id,
        productAttribute: productAttributesList,
        productUserDetail: productUserDetail,
      },
    };
    return [product];
  };

  const handleOrderCancelPopUp = (item, orderObj) => {
    let cancelData = {
      productItemId: item.id,
      orderObj: orderObj,
      orderNumber: item.orderNumber,
    };
    setCancelData(cancelData);
    setCancelOrderPopUp(true);
  };

  const handleAccept = (item, orderObj) => {
    let acceptData = {
      order: [
        {
          customerId: orderObj.customer.id,
          orderId: orderObj.id,
          orderNumber: item.orderNumber,
          products: [
            {
              product: {
                orderProductId: item.id,
                productAttribute: [
                  {
                    orderQuantity: item.orderedQuantity,
                    orderStatus: "ACCEPT",
                    productAttribute: item.attributes.id,
                  },
                ],
                productId: item.attributes.id,
                productUserDetail: item.product.productUserDetail,
              },
            },
          ],
        },
      ],
    };

    dispatch(handleAcceptOrder(acceptData, orders));
    setFiles([]);
    setDialogOpen(false);
  };

  const handleToggleCollapse = (orderId) => {
    setOpenOrderIds((prevOpenOrderIds) =>
      prevOpenOrderIds.includes(orderId)
        ? prevOpenOrderIds.filter((id) => id !== orderId)
        : [...prevOpenOrderIds, orderId]
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Filter
          fromDate={fromDate}
          toDate={toDate}
          search={search}
          handleDateRangeFilter={handleDateRangeFilter}
          handleClearDateFilter={handleClearDateFilter}
          placeholder="Search Order ID"
          handleFromDate={handleFromDate}
          handleToDate={handleToDate}
          handleSearch={handleSearch}
        />

        <Grid item xs>
          {filterFlag && (
            <p className={classes.filterText}>
              {filteredItemsState?.length} Out of {orders?.length || 0} Items
              Filtered
            </p>
          )}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Order ID</StyledTableCell>
                  <StyledTableCell align="center">Order No.</StyledTableCell>
                  <StyledTableCell align="center">Order Status</StyledTableCell>
                  <StyledTableCell align="center">Order Type</StyledTableCell>
                  <StyledTableCell align="center">Items</StyledTableCell>
                  <StyledTableCell align="center">Order Date</StyledTableCell>
                  <StyledTableCell align="center">Logistics By</StyledTableCell>
                  <StyledTableCell align="center">
                    Sales Commission
                  </StyledTableCell>
                  <StyledTableCell align="center">Details</StyledTableCell>
                  <StyledTableCell align="center">
                    Purchase Order
                  </StyledTableCell>
                  <StyledTableCell align="center">Order Status</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              {filteredItemsState ? (
                filteredItemsState.length > 0 ? (
                  <TableBody>
                    {filteredItemsState
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order, index) => (
                        <React.Fragment key={index}>
                          <StyledTableRow>
                            <StyledTableCell
                              component="th"
                              align="center"
                              scope="row"
                            >
                              {order.id}
                              {order.products.length > 1 && (
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleToggleCollapse(order.id)}
                                >
                                  {openOrderIds.includes(order.id) ? (
                                    <ExpandLessIcon />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </IconButton>
                              )}
                            </StyledTableCell>

                            <StyledTableCell
                              component="th"
                              align="center"
                              scope="row"
                            >
                              {order.products.length > 1
                                ? "---"
                                : order.products[0].id}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {order.products.length > 1
                                ? "---"
                                : order.products[0].orderStatus}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {order.products.length > 1
                                ? "---"
                                : order.products[0].bargainedOrder === "NO"
                                  ? "Fixed"
                                  : "Bargained"}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {order.products.length > 1
                                ? "---"
                                : order.products[0].orderedQuantity}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {order.products.length > 1
                                ? "---"
                                : new Date(
                                  order.datePurchased
                                ).toLocaleDateString()}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {order.products.length > 1 ? "---" : "YWC"}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <Tooltip
                                title="Sales Commission"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  className={classes.viewButton}
                                  style={{
                                    outline: "none",
                                    height: "30px",
                                  }}
                                  onClick={() =>
                                    handleViewSalesCommission(
                                      order.salesCommission
                                        .orderSalesCommission[0],
                                      order
                                    )
                                  }
                                >
                                  <PaymentIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {order.products.length > 1 ? (
                                "---"
                              ) : (
                                <Tooltip
                                  title="View Order Details"
                                  placement="top"
                                  arrow
                                >
                                  <IconButton
                                    aria-label="view"
                                    className={classes.viewButton}
                                    style={{
                                      outline: "none",
                                      height: "30px",
                                    }}
                                    onClick={() =>
                                      handleViewOrders(order.products[0])
                                    }
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <Tooltip
                                title="View Purchase Order"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  className={classes.viewButton}
                                  style={{
                                    outline: "none",
                                    height: "30px",
                                  }}
                                  onClick={() =>
                                    handleViewPurchaseOrder(
                                      order.id,
                                      order
                                    )
                                  }
                                >
                                  <PictureAsPdfIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title="Download Purchase Order"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  className={classes.viewButton}
                                  style={{
                                    outline: "none",
                                    height: "30px",
                                  }}
                                  onClick={() =>
                                    handleDownloadPurchaseOrder(
                                      order.id,
                                      order
                                    )
                                  }
                                >
                                  <GetAppIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell>
                              {order.products.length > 1 ? (
                                "---"
                              ) : (
                                <>
                                  <Tooltip
                                    title="Accept Order"
                                    placement="top"
                                    arrow
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className={classes.buybutton}
                                      style={{
                                        height: "25px",
                                        fontSize: "11px",
                                        fontFamily: "'Fira Sans', sans-serif",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => {
                                        setDialogOpen(true);
                                        setSelectedOrder(order);
                                        setSelectedProduct(order.products[0]);
                                      }}
                                      disabled={
                                        order.products[0].orderStatus ===
                                          "ORDERED"
                                          ? false
                                          : true
                                      }
                                    >
                                      Accept
                                    </Button>
                                  </Tooltip>

                                  <Dialog
                                    open={isDialogOpen}
                                    onClose={() => setDialogOpen(false)}
                                    BackdropProps={{
                                      className: classes.dialogBackdrop, // Styled backdrop
                                    }}
                                  >
                                    <DialogTitle className={classes.dialogTitle}>Upload Document</DialogTitle>
                                    <DialogContent className={classes.dialogContent}>
                                      <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        className={classes.dialogInput}
                                      />
                                      {error && <p className={classes.dialogError}>{error}</p>}
                                      {files.length > 0 && ( // Only show the list if there are files selected
                                        <ul className={classes.fileList}>
                                          {files.map((file, index) => (
                                            <li key={index} className={classes.fileListItem}>
                                              {file.name}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    </DialogContent>
                                    <DialogActions className={classes.dialogActions}>
                                      <Button

                                        onClick={() => {
                                          setDialogOpen(false);
                                          setError("");
                                          setFiles([]);
                                        }
                                        }
                                        className={`${classes.dialogButton} ${classes.dialogButtonCancel}`}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={handleUpload}
                                        className={`${classes.dialogButton} ${classes.dialogButtonUpload}`}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={loading || files.length === 0}
                                      >
                                        {loading ? <CircularProgress size={20} color="inherit" /> : "Upload & Continue"}
                                      </Button>
                                    </DialogActions>
                                  </Dialog>

                                  <Tooltip
                                    title="Cancel Order"
                                    placement="top"
                                    arrow
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className={classes.buybutton}
                                      style={{
                                        height: "25px",
                                        fontSize: "11px",
                                        fontFamily: "'Fira Sans', sans-serif",
                                      }}
                                      onClick={() =>
                                        handleOrderCancelPopUp(
                                          order.products[0],
                                          order
                                        )
                                      }
                                      disabled={
                                        order.products[0].orderStatus ===
                                          "ORDERED"
                                          ? false
                                          : true
                                      }
                                    >
                                      {loading ? <CircularProgress size={20} color="inherit" /> : 'Cancel'}
                                    </Button>
                                  </Tooltip>
                                </>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          {order.products.length > 1 && (
                            <StyledTableRow>
                              <StyledTableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={11}
                              >
                                <Collapse
                                  in={openOrderIds.includes(order.id)}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Table size="small" aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell align="center">
                                            Order No.
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Order Status
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Order Type
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Items
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Order Date
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Logistics By
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Details
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Order Status
                                          </StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {order.products.map(
                                          (product, index) => (
                                            <StyledTableRow key={index}>
                                              <StyledTableCell align="center">
                                                {product.id}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {product.orderStatus}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {product.bargainedOrder === "NO"
                                                  ? "Fixed"
                                                  : "Bargained"}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {product.orderedQuantity}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {new Date(
                                                  order.datePurchased
                                                ).toLocaleDateString()}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                YWC
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                <Tooltip
                                                  title="View Order Details"
                                                  placement="top"
                                                  arrow
                                                >
                                                  <IconButton
                                                    aria-label="view"
                                                    className={
                                                      classes.viewButton
                                                    }
                                                    style={{
                                                      outline: "none",
                                                      height: "30px",
                                                    }}
                                                    onClick={() =>
                                                      handleViewOrders(product)
                                                    }
                                                  >
                                                    <VisibilityIcon fontSize="small" />
                                                  </IconButton>
                                                </Tooltip>
                                              </StyledTableCell>
                                              <StyledTableCell>
                                                <Tooltip
                                                  title="Accept Order"
                                                  placement="top"
                                                  arrow
                                                >
                                                  <Button
                                                    variant="contained"
                                                    size="small"
                                                    className={
                                                      classes.buybutton
                                                    }
                                                    style={{
                                                      height: "25px",
                                                      fontSize: "11px",
                                                      fontFamily:
                                                        "'Fira Sans', sans-serif",
                                                      marginRight: "10px",
                                                    }}
                                                    onClick={() => {
                                                      setDialogOpen(true);
                                                      setSelectedOrder(order);
                                                      setSelectedProduct(product);
                                                    }}
                                                    disabled={
                                                      product.orderStatus ===
                                                        "ORDERED"
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    Accept
                                                  </Button>
                                                </Tooltip>

                                                <Dialog
                                                  open={isDialogOpen}
                                                  onClose={() => setDialogOpen(false)}
                                                  BackdropProps={{
                                                    className: classes.dialogBackdrop, // Styled backdrop
                                                  }}
                                                >
                                                  <DialogTitle className={classes.dialogTitle}>Upload Document</DialogTitle>
                                                  <DialogContent className={classes.dialogContent}>
                                                    <input
                                                      type="file"
                                                      multiple
                                                      onChange={handleFileChange}
                                                      className={classes.dialogInput}
                                                    />
                                                    {error && <p className={classes.dialogError}>{error}</p>}
                                                    {/* {files.length > 0 && ( // Only show the list if there are files selected */}
                                                    <ul className={classes.fileList}>
                                                      {files.map((file, index) => (
                                                        <li key={index} className={classes.fileListItem}>
                                                          {file.name}
                                                        </li>
                                                      ))}
                                                    </ul>
                                                    {/* )} */}
                                                  </DialogContent>
                                                  <DialogActions className={classes.dialogActions}>
                                                    <Button

                                                      onClick={() => {
                                                        setDialogOpen(false);
                                                        setError("");
                                                        setFiles([]);
                                                      }
                                                      }
                                                      className={`${classes.dialogButton} ${classes.dialogButtonCancel}`}
                                                    >
                                                      Cancel
                                                    </Button>
                                                    <Button
                                                      onClick={handleUpload}
                                                      className={`${classes.dialogButton} ${classes.dialogButtonUpload}`}
                                                      variant="contained"
                                                      color="primary"
                                                      size="small"
                                                      disabled={loading || files.length === 0}
                                                    >
                                                      {loading ? <CircularProgress size={20} color="inherit" /> : "Upload & Continue"}
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>

                                                <Tooltip
                                                  title="Cancel Order"
                                                  placement="top"
                                                  arrow
                                                >
                                                  <Button
                                                    variant="contained"
                                                    size="small"
                                                    className={
                                                      classes.buybutton
                                                    }
                                                    style={{
                                                      height: "25px",
                                                      fontSize: "11px",
                                                      fontFamily:
                                                        "'Fira Sans', sans-serif",
                                                    }}
                                                    onClick={() =>
                                                      handleOrderCancelPopUp(
                                                        product,
                                                        order
                                                      )
                                                    }
                                                    disabled={
                                                      product.orderStatus ===
                                                        "ORDERED"
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    {loading ? <CircularProgress size={20} color="inherit" /> : 'Cancel'}
                                                  </Button>
                                                </Tooltip>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </React.Fragment>
                      ))}
                    {emptyRows > 0 && (
                      <StyledTableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={11} />
                      </StyledTableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={11} className={classes.noFound}>
                        No Orders Found
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )
              ) : (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell colSpan={11} className={classes.noFound}>
                      No Orders Found
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredItemsState.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      {seeOrderedProducts}
      {displaySalesCommission}
      <CancelReason
        open={cancelOrderPopUp}
        handleClose={() => setCancelOrderPopUp(false)}
        submitCancelReason={submitCancelReason}
        cancelData={cancelData}
      />
    </div>
  );
};
