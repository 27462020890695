import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../util/spinner/spinner";

import { getSellings } from "../../../redux/actions/sellerActions";

/* material ui */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";

/* filter component */
import { Filter } from "./Filter";
import { ProductBargainsDialog } from "./ProductBargainsDialog";
import { capitalize } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d5f9ef",
    color: "#3c3c3c",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 900,
    position: "initial",
  },
  body: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#2d2b2b",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#eeeeee",
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: 440,
  },
  noFound: {
    color: "#030104",
    textAlign: "center",
  },
  viewButton: {
    border: "1px solid #53E7BC",
    borderRadius: "0.375rem",
    background: "transparent",
    color: "#53E7BC",
    padding: "0.8rem",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
    margin: "auto",
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  filterText: {
    fontFamily: "inherit",
    color: "#3c3c3c",
    textAlign: "right",
    fontWeight: "bold",
  },
}));

export const Bargain = () => {
  const classes = useStyles();
  const seller = JSON.parse(localStorage.getItem("sell"));
  const { bargains, loading, error } = useSelector((state) => state.sales);

  const dispatch = useDispatch();

  const [openBargainDiloug, setOpenBargainDiloug] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [search, setSearch] = useState("");
  const [currentList, setCurrentList] = useState([]);
  const [bargainProduct, setBargainProduct] = useState({
    skuId: "",
    attributId: "",
  });
  // filterFlag : flag to check whether any filter applied or not
  const [filterFlag, setFilterFlag] = useState(false);

  const prepareUniqueBargainList = () => {
    let skuList = [];
    const list = bargains.filter((currItem) => {
      if (skuList.length === 0) {
        skuList.push(currItem._source.attribute_sku);
        return true;
      }
      if (skuList.find((item) => item === currItem._source.attribute_sku)) {
        return false;
      } else {
        skuList.push(currItem._source.attribute_sku);
        return true;
      }
    });
    return list;
  };

  const [itemsState, setItemsState] = useState(bargains ? [] : null);
  const [filteredItemsState, setFilteredItemsState] = useState(
    bargains ? [] : null
  );

  /* set Filter from Date */
  const handleFromDate = (date) => {
    setFromDate(date);
  };

  /* set Filter To Date */
  const handleToDate = (date) => {
    setToDate(date);
  };

  /* search by date range filter */
  const handleDateRangeFilter = () => {
    let newList = [];
    if (fromDate !== "" && toDate !== "") {
      let fromString = new Date(fromDate);
      let toString = new Date(toDate);
      let toDatess = new Date(toString.setDate(toString.getDate() + 1));
      if (fromString.toISOString() === toDatess.toISOString()) {
        newList = currentList.filter(
          (item) =>
            new Date(item._source.datetime).toLocaleDateString() ===
            fromString.toLocaleDateString()
        );
      } else {
        newList = currentList.filter((item) => {
          const itemDate = new Date(item._source.datetime);
          return itemDate >= fromString && itemDate <= toDatess;
        });
      }
      setFilterFlag(true);
    } else {
      newList = itemsState;
    }
    setFilteredItemsState(newList);
  };

  /* clear date filter */
  const handleClearDateFilter = () => {
    setFromDate("");
    setToDate("");
    setSearch("");
    setFilterFlag(false);
    setFilteredItemsState(itemsState);
  };

  /* search filter */
  const handleSearch = (value) => {
    let newList = [];
    setSearch(value);
    if (value !== "") {
      newList = currentList.filter((item) => {
        const lc = ("" + item._source.product_ID).toLowerCase();
        const filter = value.toLowerCase();
        setFilterFlag(true);
        return lc.includes(filter);
      });
    } else {
      setFilterFlag(false);
      newList = itemsState;
    }
    setFilteredItemsState(newList);
  };

  /* function to open specific product bargain list */
  const handleProductBargains = (item) => {
    setBargainProduct((prevState) => ({
      ...prevState,
      skuId: item._source.attribute_sku,
      attributId: item._source.attribute_id,
    }));
    setOpenBargainDiloug(true);
  };

  /* function to close bargin list */
  const handleCloseProductBargains = () => {
    setOpenBargainDiloug(false);
  };

  useEffect(() => {
    if (bargains) {
      setItemsState(prepareUniqueBargainList());
      setFilteredItemsState(prepareUniqueBargainList());
    }
  }, [bargains]);
  useEffect(() => {
    setCurrentList(itemsState);
  }, [itemsState]);

  useEffect(() => {
    dispatch(getSellings(seller));
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const seeAllProductBargains = (
    <ProductBargainsDialog
      open={openBargainDiloug}
      product={bargainProduct}
      handleCloseProductBargains={handleCloseProductBargains}
    />
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Filter
          fromDate={fromDate}
          toDate={toDate}
          search={search}
          placeholder="Search Product ID"
          handleFromDate={handleFromDate}
          handleToDate={handleToDate}
          handleDateRangeFilter={handleDateRangeFilter}
          handleClearDateFilter={handleClearDateFilter}
          handleSearch={handleSearch}
        />

        <Grid item xs>
          {filterFlag && (
            <p className={classes.filterText}>
              {filteredItemsState.length} Out of {bargains.length} Items
              Filtered
            </p>
          )}
          <TableContainer component={Paper} className={classes.container}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Product ID</StyledTableCell>
                  <StyledTableCell align="center">Attribute ID</StyledTableCell>
                  <StyledTableCell align="center">Category</StyledTableCell>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Color</StyledTableCell>
                  <StyledTableCell align="center">Size</StyledTableCell>
                  <StyledTableCell align="center">Bargain Date</StyledTableCell>
                  <StyledTableCell align="center">All Bids</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              {loading ? (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell colSpan={8}>
                      <Spinner />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              ) : error ? (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell colSpan={8}>{error}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              ) : (
                <React.Fragment>
                  {filteredItemsState ? (
                    filteredItemsState.length > 0 ? (
                      <TableBody>
                        {filteredItemsState.map((bargain, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              component="th"
                              align="center"
                              scope="row"
                            >
                              {bargain._source.product_ID}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {Array.isArray(bargain._source.attribute_id)
                                ? bargain._source.attribute_id.join(', ')
                                : bargain._source.attribute_id}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {capitalize(bargain._source.category)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {capitalize(bargain._source.sku_name)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {Array.isArray(bargain._source.attribute_color_code) ? (
                                bargain._source.attribute_color_code.map((color, index) => (
                                  <p
                                    key={index}
                                    className={classes.colorbox}
                                    style={{
                                      backgroundColor: color,
                                      margin: '0 5px',
                                      display: 'inline-block',
                                      width: '20px',
                                      height: '20px',
                                      borderRadius: '50%',
                                    }}
                                    title={bargain._source.attribute_color[index]}
                                  />
                                ))
                              ) : bargain._source.attribute_color_code ? (
                                <p
                                  className={classes.colorbox}
                                  style={{
                                    backgroundColor: bargain._source.attribute_color_code,
                                    margin: '0 5px',
                                    display: 'inline-block',
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                  }}
                                  title={bargain._source.attribute_color}
                                />
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {Array.isArray(bargain._source.attribute_size)
                                ? bargain._source.attribute_size.join(', ')
                                : bargain._source.attribute_size}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {new Date(
                                bargain._source.datetime
                              ).toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip
                                title="View All Bargains"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  className={classes.viewButton}
                                  style={{
                                    outline: "none",
                                    height: "30px",
                                  }}
                                  onClick={() => handleProductBargains(bargain)}
                                >
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={8}
                            className={classes.noFound}
                          >
                            Bargains not Found.
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    )
                  ) : null}
                </React.Fragment>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* bargain dialoug to see all specific product bargains*/}
      {seeAllProductBargains}
    </div>
  );
};
