import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ButtonBase from "@material-ui/core/ButtonBase";
import { getCartItemsByUser } from "../../../redux/actions/cartActions";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(0, 2),
    "& .MuiListItemText-primary": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
    },
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  titleblock: {
    backgroundColor: "#53E7BC",
    border: "1px solid #d6d6d6",
    borderRadius: 4,
  },
  summarytitle: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  productroot: {
    flexGrow: 1,
    height: "450px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    // maxWidth: 500,
    marginBottom: 10,
    border: "1px solid #d6d6d6",

  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  producttitle: {
    fontSize: "13px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "capitalize",
    width: "95%",
  },
  lables: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "capitalize",
    marginRight: theme.spacing(1),
    marginTop: "6px",
    marginBottom: "6px"
  },
  prodQty: {
    fontSize: "13px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "capitalize",
    marginRight: theme.spacing(1),
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
    marginLeft: theme.spacing(1),
  },
  itemprice: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
  },
  addresstype: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
  },
  adresstext: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    padding: 4,
  },
}));

/* image base url */
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
const bunnyCdnUrl = process.env.REACT_APP_BUNNY_CDN_URL;

export const ReviewOrder = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { handleBack, handleNext, openaddAddressUpdated } = props;
  const { cartItem } = useSelector((state) => state.shoppingCart);
  const { account } = useSelector((state) => state.auth);

  useEffect(() => {
    openaddAddressUpdated && dispatch(getCartItemsByUser(account.id));
  }, [openaddAddressUpdated])

  const extractPrice = (priceString) => {
    // Remove all non-numeric characters except the decimal point
    const cleanedString = priceString.replace(/[^0-9.]/g, "");

    // Check for and remove a leading dot (.) if it exists after cleaning
    const cleanedStringWithoutLeadingDot = cleanedString.startsWith(".")
      ? cleanedString.slice(1)
      : cleanedString;

    const numberPrice = parseFloat(cleanedStringWithoutLeadingDot);
    const finalPrice = Math.floor(numberPrice);
    return finalPrice;
  };

  return (
    <Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.titleblock}>
            <Typography gutterBottom className={classes.summarytitle}>
              Order summary (
              {cartItem.products ? `${cartItem.products.length} items` : ""})
            </Typography>
          </Grid>

          <Grid item md={8} xs={12}>
            <Fragment>
              <div className={classes.productroot}>
                {cartItem.products &&
                  cartItem.products.length > 0 &&
                  cartItem.products.map((item, index) => (
                    <Paper className={classes.paper} key={index}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <ButtonBase className={classes.image}>
                            {item.cartItemattributes.length > 0 ? (
                              <img
                                className={classes.img}
                                alt={
                                  item.cartItemattributes[0].optionValue.name
                                }
                                src={item?.cartItemattributes[0]?.optionValue?.image?.includes(bunnyCdnUrl) ? `${item?.cartItemattributes[0]?.optionValue?.image}` : `${bunnyCdnUrl}${item?.cartItemattributes[0]?.optionValue?.image}`}
                              />
                            ) : (
                              <img
                                className={classes.img}
                                alt={item.image.id}
                                src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                loading="lazy"
                              />
                            )}
                          </ButtonBase>
                        </Grid>

                        <Grid item xs={12} sm container>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={2}
                          >
                            <Grid item xs>
                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                className={classes.producttitle}
                              >
                                {item.description.name}
                              </Typography>

                              <Typography component="div">
                                {item.bargained === "YES" ? (
                                  <>
                                    <Typography
                                      variant="body2"
                                      className={classes.lables}
                                    >
                                      Original Price:
                                      <span
                                        style={{
                                          textDecoration: "line-through",
                                          marginLeft: 8,
                                        }}
                                      >
                                        {item.originalPrice}
                                      </span>
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      className={classes.lables}
                                    >
                                      Bargain Price:
                                      <span
                                        style={{
                                          marginLeft: 8,
                                        }}
                                      >
                                        Rs.{item.bargainPrice}
                                      </span>
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Typography>

                              <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.prodQty}
                              >
                                Total Qty: <span>{item.quantity}</span>
                              </Typography>

                              <Typography
                                variant="body2"
                                className={classes.lables}
                              >
                                Type:{" "}
                                {item.bargained === "YES"
                                  ? "bargained"
                                  : "Fixed"}
                              </Typography>

                              {item.cartItemattributes.length > 0 ? (
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.prodattribute}
                                >
                                  Size:{" "}
                                  <span>

                                    {item.bundleType === 'sizes' ? (
                                      item.cartItemattributes.map((attr) => attr.option.code).join(', ')
                                    ) : (item?.cartItemattributes[0].option.code ===
                                      "Watch Size"
                                      ? "One Size"
                                      : item.cartItemattributes[0].option
                                        .code === "Bag Size"
                                        ? "One Size"
                                        : item.cartItemattributes[0].option
                                          .code === "Toy Size"
                                          ? "One Size"
                                          : item.cartItemattributes[0].option.code)}
                                  </span>
                                </Typography>
                              ) : null}

                              {item.cartItemattributes.length > 0 ? (
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  component="div"
                                  className={classes.prodattribute}
                                  style={{ display: "flex" }}
                                >
                                  Color:{" "}

                                  {(item.bundleType === 'colors' || item.bundleType === '') ? (
                                    item.cartItemattributes.map((attr, index) => (
                                      <p
                                        key={index}
                                        className={classes.colorbox}
                                        style={{
                                          backgroundColor: `${attr.optionValue.name}`,
                                        }}
                                      />
                                    ))
                                  ) : (
                                    <p
                                      className={classes.colorbox}
                                      style={{
                                        backgroundColor: `${item.cartItemattributes[0].optionValue.name}`,
                                      }}
                                    />
                                  )}
                                </Typography>
                              ) : null}

                              
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Typography
                              variant="caption"
                              style={{ color: "#27B23E" }}
                            >
                              (Tax included)
                            </Typography>
                            <Typography variant="subtitle1">
                              {item.bargained === "NO" ? 'Rs.'+extractPrice(item.finalPrice) : item.displayBargainPrice}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              style={{ textAlign: "center" }}
                            >
                              x {item.quantity}
                            </Typography>
                            <Divider />
                            <Typography variant="subtitle1">
                              <span className={classes.itemprice}>
                                Rs.{item.price}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
              </div>
            </Fragment>
          </Grid>

          <Grid item md={4} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <ListItemText primary="Subtotal" />
                    <Typography variant="body2">
                      {cartItem.products ? cartItem.displaySubTotal : 0}
                    </Typography>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText primary="GST Total" />
                    <Typography variant="body2">
                      {cartItem.displayTaxTotal ? cartItem.displayTaxTotal : 0}
                    </Typography>
                  </ListItem>
                  {/* <ListItem className={classes.listItem}>
                    <ListItemText primary="Service Tax(18%)" />
                    <Typography variant="body2">
                      {cartItem.displayServiceTaxTotal
                        ? cartItem.displayServiceTaxTotal
                        : 0}
                    </Typography>
                  </ListItem> */}
                  <ListItem className={classes.listItem}>
                    <ListItemText primary="Shipping charges" />
                    <Typography variant="body2">
                      {cartItem.displayShippingTotal
                        ? cartItem.displayShippingTotal
                        : 0}
                    </Typography>
                  </ListItem>

                  <Divider />

                  <ListItem className={classes.listItem}>
                    <ListItemText primary="Total" />
                    <Typography variant="subtitle1" className={classes.total}>
                      {cartItem.products ? cartItem.displayTotal : 0}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.addresstype}
                >
                  Delivery address
                </Typography>
                <Typography className={classes.adresstext}>
                  {account.delivery.firstName} {account.delivery.lastName}
                </Typography>
                <Typography className={classes.adresstext}>
                  {account.delivery.address}
                </Typography>
                <Typography className={classes.adresstext}>
                  {account.delivery.city} <span>{"-"}</span>
                  {account.delivery.postalCode}
                </Typography>
                <Typography className={classes.adresstext}>
                  {account.delivery.stateProvince}
                </Typography>
                <Typography className={classes.adresstext}>
                  Ph: {account.delivery.phone}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <div className={classes.buttons}>
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
        >
          Place Order
        </Button>
      </div>
    </Fragment>
  );
};
