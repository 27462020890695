import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "50px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 12,
  },
  errorBlock: {
    margin: 18,
    textAlign: "center",
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "red",
  },
  imagetag: {
    textAlign: "center",
    margin: theme.spacing(4),
  },
}));

const imgBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const ImageViewer = () => {
  const classes = useStyles();

  const [url, setUrl] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    const refText = "STOCKXBID-SELLER";
    const sellerId = url.split("/")[7];
    const ImageName = url.split("/")[10];

    // if (url.includes(refText)) {
    //   setImage(`${imgBaseUrl}/static/PRODUCT/${sellerId}/IMAGE/${ImageName}`);
    //   setError("");
    // } else {
    //   setError("Invalid url.");
    // }
    setImage(url);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifycontent="center" spacing={2}>
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Paste url"
              inputProps={{ "aria-label": "search google maps" }}
              onChange={(e) => setUrl(e.target.value)}
            />

            <Button color="primary" variant="contained" onClick={handleSubmit}>
              view
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {error ? (
        <Grid item xs={12}>
          <div className={classes.errorBlock}>{error}</div>
        </Grid>
      ) : image ? (
        <Grid item xs={12}>
          <div className={classes.imagetag}>
            <img alt="product" src={image} width="300px" height="300px" />
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
};
