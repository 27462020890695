import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

/* redux actions */
import {
  addAttributeToMiniCart,
  addMiniCartToShoppingCart,
  clearMiniCart,
  // deleteAttributeFromMiniCart,
  deleteBundleProductFromMiniCart,
  minimumBuyAlert,
  SuccessMessage,
  // showBetaMsg,
  updateMiniCartAttributeQuantity,
  updateMiniCartBundleQuantity,
} from "../../redux/actions/productActions";

/* material ui css */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import Axios from "axios";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#27B23E",
    color: theme.palette.common.white,
    // border: "1px solid #ccc",
    fontFamily: "'Fira Sans', sans-serif",
  },
  body: {
    fontSize: 14,
    // border: "1px solid #ccc",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  miniCartButton: {
    fontSize: "18px",
    borderRadius: "2px",
    padding: "10px 8px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.2)",
    background: "#ff9d00",
    color: "#FFFFFF",
    fontWeight: 600,
    border: "none",
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "none",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    transition: "ease all 0.3s",
    "&:hover": {
      background: "#f59701",
      color: "#FFFFFF",
      transform: "translateY(-5px)",
    },
  },
  table: {
    marginTop: 10,
  },
  delete: {
    background: "#ff4d4d",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    "&:hover": {
      background: "#f59701",
      color: "#FFFFFF",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  cancelBbutton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#ff4d4d",
    color: "#FFF",
    "&:hover": {
      background: "#f59701",
      color: "#FFFFFF",
    },
  },
  noFound: {
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
  },
  total: {
    fontSize: 15,
    color: "#fff",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
  },
  iconButton: {
    fontSize: "1rem",
    border: "solid 1px #707070",
    borderColor: "#53e7bc",
    borderRadius: "50%",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
    margin: "auto",
  },
}));

export const MiniCart = (props) => {
  const classes = useStyles();
  const {
    handleMiniCartClose,
    addToMiniCart,
    product,
    color,
    size,
    attribute,
    quantity,
    setBundleTypeQuantity,
    updateTrigger,
    setUpdateTrigger,
    bundleTableData,
    setBundleTableData,
    setCartLoading,
    back,
  } = props;
  const { miniCart } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const history = useHistory();
  const [bundleProduct, setBundleProduct] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [flag, setFlag] = useState(false);
  const bunnyCdnUrl = process.env.REACT_APP_BUNNY_CDN_URL;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (
      miniCart
    ) {
      setBundleProduct(true);
      if (miniCart && miniCart.length > 0) {
        setTableData(miniCart);
      }
    }
  }, [miniCart]);

  useEffect(() => {
    if (bundleProduct) {
      let res = createBundleProducts();

      // console.log("Response is ===>>>>> ", res)
      // let miniCartAttributes = {
      //   "bundleType": product?.bundleType,
      //   "productAtrbteVariantIds": ids,
      //   "productAttributeId": 0,
      //   "productId": product.id,
      //   "quantity": quantity,
      // }
      // setTableData((prevTableData) => [...prevTableData, res]);
    }
  }, [bundleProduct]);

  useEffect(() => {
    if (updateTrigger === 1) {
      let res = createBundleProducts();

      for (let i = 0; i < tableData.length; i++) {
        if (product.bundleType === "colors" || product.bundleType === "") {
          if (tableData[i]?.sizeCode[0] !== size) {
            setFlag(true);
          }
        } else if (product.bundleType === "sizes") {
          // console.log(`Colors are ==========>>>>>>>>>>> ${tableData[i]?.colorCode} and color is ${color}`);
          if (tableData[i]?.colorCode[0] !== color) {
            setFlag(true);
          }
        }
      }

      setUpdateTrigger(0);
    }
  }, [updateTrigger, tableData, product, size, color]); // Make sure to add proper dependencies

  useEffect(() => {
    if (flag) {
      setTableData((prevTableData) => [
        ...prevTableData,
        createBundleProducts(),
      ]);
      setBundleTableData((prevTableData) => [
        ...prevTableData,
        createBundleProducts(),
      ]);
      setFlag(false);
    }
  }, [flag]);

  const createBundleProducts = useCallback(() => {
    if ((product?.bundleType === "colors" || product?.bundleType === "") && size) {
      let attribute = product?.attributes.filter(
        (att) => att.optionCode === size.optionCode
      );
      let colors = [];
      for (let i = 0; i < attribute[0]?.attributeOptionValues.length; i++) {
        colors.push(attribute[0]?.attributeOptionValues[i].colorCode);
      }
      return {
        sizeCode: [size.optionCode],
        colorCode: colors,
      };
      // setTableData([{
      //   sizeCode: [size.optionCode],
      //   colorCode: colors,
      // }])
    } else if (product?.bundleType === "sizes") {
      let values = [];

      for (let i = 0; i < product?.attributes?.length; i++) {
        for (
          let j = 0;
          j < product?.attributes[i]?.attributeOptionValues?.length;
          j++
        ) {
          values.push(product?.attributes[i]?.attributeOptionValues[j]);
        }
      }

      const unique = [];
      const seen = new Set();
      values.forEach((item) => {
        if (!seen.has(item["colorCode"])) {
          unique.push(item);
          seen.add(item["colorCode"]);
        }
      });

      let selectedSizes = [];
      let selectedColor = unique.filter((el) => el.colorCode === color);

      for (let i = 0; i < product?.attributes?.length; i++) {
        for (
          let j = 0;
          j < product?.attributes[i]?.attributeOptionValues.length;
          j++
        ) {
          if (
            product.attributes[i]?.attributeOptionValues[j]?.colorCode ===
            `${color}`
          ) {
            selectedSizes.push(product?.attributes[i].optionCode);
            // break
          }
        }
      }

      // formatting data for minicart
      let formattedData = [];
      selectedSizes.forEach((el) => {
        formattedData.push();
        // setTableData(formattedData);
      });

      // setTableData([{
      //   sizeCode: selectedSizes,
      //   colorCode: [selectedColor[0].colorCode],
      // }])

      return {
        sizeCode: selectedSizes,
        colorCode: [selectedColor[0].colorCode],
      };
    }
  }, [product, size, color]);

  // useEffect(() => {
  //   if (tableData && tableData.length > 0) {
  //     localStorage.setItem("bundleTableData", JSON.stringify(tableData));
  //   } else {
  //     setTableData(JSON.parse(localStorage.getItem("bundleTableData")));
  //   }
  // }, [tableData]);

  /* function to handle delete attribute */
  // const handleDeleteAttribute = (attribute) => {
  //   dispatch(deleteAttributeFromMiniCart(miniCart?.miniCartDetails?.readableMiniCart[0]?.id, attribute.id));
  // };

  /* function to add minicart products to shopping cart */
  const handleAddMiniCartToShoppingCart = (miniCartId) => {
    setCartLoading(true); // Start loading
    dispatch(addMiniCartToShoppingCart(miniCartId, history))
      .finally(() => {
        setCartLoading(false); // Stop loading once done
      });
    handleMiniCartClose(0);
    // dispatch(showBetaMsg());
  };

  /* function to handle remove and update product quantity */
  const removeAndUpdate = (data, attribute) => {
    const sub = attribute - 1;
    const attributeData = {
      bundleType: "",
      bundleValue: sub,
      miniCartAttributes: [
        {
          minicartAttributeId: data.id,
          productAttributeId: data.productAttributeId,
        },
      ],
      miniCartId: miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
      miniCartProductId:
        miniCart?.miniCartDetails?.readableMiniCart[0]?.productId,
      productId: miniCart?.miniCartDetails?.readableMiniCart[0]?.productId,
      quantity: 0,
    };

    dispatch(
      updateMiniCartBundleQuantity(
        miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
        attributeData
      )
    );
  };

  const updateQuantity = (attribute, operator) => {
    let res = formatMiniCartUpdateData(attribute, operator);
    dispatch(
      updateMiniCartBundleQuantity(
        miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
        res
      )
    );
  };

  /* function to handle add and update product quantity */
  const addAndUpdate = (data, attribute) => {
    const add = attribute + 1;
    const attributeData = {
      bundleType: "",
      bundleValue: add,
      miniCartAttributes: [
        {
          minicartAttributeId: data.id,
          productAttributeId: data.productAttributeId,
        },
      ],
      miniCartId: miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
      miniCartProductId:
        miniCart?.miniCartDetails?.readableMiniCart[0]?.productId,
      productId: miniCart?.miniCartDetails?.readableMiniCart[0]?.productId,
      quantity: 0,
    };

    dispatch(
      updateMiniCartBundleQuantity(
        miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
        attributeData
      )
    );
  };

  // useEffect(() => {
  //   return () => {
  //     handleMiniCartClose(0);
  //     dispatch(clearMiniCart());
  //   };
  // }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const extractPrice = (priceString) => {
    // Remove all non-numeric characters except the decimal point
    const cleanedString = priceString.replace(/[^0-9.]/g, "");

    // Check for and remove a leading dot (.) if it exists after cleaning
    const cleanedStringWithoutLeadingDot = cleanedString.startsWith(".")
      ? cleanedString.slice(1)
      : cleanedString;

    const numberPrice = parseFloat(cleanedStringWithoutLeadingDot);
    const finalPrice = Math.floor(numberPrice);
    return finalPrice;
  };

  const handleAddToMiniCart = () => {

    if (product?.bundleType === "colors" || product?.bundleType === "") {
      let ids = [];
      for (let i = 0; i < size?.attributeOptionValues.length; i++) {
        ids.push(size?.attributeOptionValues[i]?.attributeId);
      }

      let miniCartAttributes = {
        bargained: "NO",
        finalPrice: 0,
        originalPrice: extractPrice(product.originalPrice),
        bundleType: product?.bundleType,
        bundleValue: 1,
        productAtrbteVariantIds: ids,
        productAttributeId: 0,
        productId: product.id,
        quantity: 0,
      };
      dispatch(
        addAttributeToMiniCart(
          miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
          miniCartAttributes
        )
      );
    } else if (product?.bundleType === "sizes") {
      let ids = [];
      for (let i = 0; i < product?.attributes.length; i++) {
        for (
          let j = 0;
          j < product?.attributes[i]?.attributeOptionValues.length;
          j++
        ) {
          if (
            product?.attributes[i]?.attributeOptionValues[j].colorCode ==
            color
          ) {
            ids.push(
              product?.attributes[i]?.attributeOptionValues[j].attributeId
            );
          }
        }
      }

      let miniCartAttributes = {
        bargained: "NO",
        finalPrice: 0,
        originalPrice: extractPrice(product.originalPrice),
        bundleType: product?.bundleType,
        bundleValue: 1,
        productAtrbteVariantIds: ids,
        productAttributeId: 0,
        productId: product.id,
        quantity: 0,
      };
      dispatch(
        addAttributeToMiniCart(
          miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
          miniCartAttributes
        )
      );
    }
  };

  const handleBundleProductDelete = async (tableObject, index) => {
    let attributeData = formatMiniCartDeleteData(tableObject);
    try {
      dispatch(
        deleteBundleProductFromMiniCart(
          miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
          attributeData
        )
      );
      dispatch(SuccessMessage('Item removed from the minicart'));
    } catch (error) {
      dispatch(minimumBuyAlert('Something went wrong!!, Please try again'));
    }
  };

  const handleBundleCategoryProductDelete = async (tableObject, index) => {
    const apiData = {
      bargained: "NO",
      finalPrice: 0,
      originalPrice: 0,
      bundleType: tableObject.bundleType,
      bundleValue: tableObject.bundleValue,
      miniCartAttributes: [
        {
          minicartAttributeId: tableObject.miniCartAttributes[index].id,
          productAttributeId:
            tableObject.miniCartAttributes[index].productAttributeId,
        },
      ],
      miniCartId: tableObject.id,
      miniCartProductId: 0,
      productId: tableObject.productId,
      quantity: tableObject.miniCartAttributes[index].quantity,
    };
    dispatch(
      deleteBundleProductFromMiniCart(
        miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
        apiData
      )
    );
  };

  function formatMiniCartDeleteData(inputObject) {
    const miniCartAttributes = inputObject.productAttributeIds.map(
      (attributeId, index) => {
        return {
          minicartAttributeId: inputObject.ids[index], // Assuming ids correspond to attributeId by index
          productAttributeId: attributeId,
        };
      }
    );

    // Return the formatted object
    return {
      bargained: inputObject.bargained,
      finalPrice: inputObject.finalPrice,
      originalPrice: inputObject.originalPrice,
      bundleType: inputObject?.bundleType,
      bundleValue: inputObject?.bundleValue,
      miniCartAttributes: miniCartAttributes,
      miniCartId: miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
      productId: inputObject?.productid,
      quantity: 0,
      miniCartProductId: inputObject?.miniCartProductId
    };
  }

  function formatMiniCartUpdateData(attribute, operator) {
    let bundleValue =
      operator === "+"
        ? attribute.bundleValue + 1
        : Math.max(1, attribute.bundleValue - 1);
    const miniCartAttributes = attribute.productAttributeIds.map(
      (attributeId, index) => {
        return {
          minicartAttributeId: attribute.ids[index], // Assuming ids correspond to attributeId by index
          productAttributeId: attributeId,
        };
      }
    );

    return {
      bargained: attribute.bargained,
      finalPrice: attribute.finalPrice,
      originalPrice: attribute.originalPrice,
      bundleType: attribute?.bundleType,
      bundleValue: bundleValue,
      miniCartAttributes: miniCartAttributes,
      miniCartId: miniCart?.miniCartDetails?.readableMiniCart[0]?.id,
      productId: attribute?.productid,
      quantity: 0,
      miniCartProductId: attribute?.miniCartProductId
    };
  }

  function groupByCat(item, products, processedProductIds = new Set()) {
    // Assuming there's only one product in the `products` array
    const product = products[0]; // Access the single product directly

    if (!product) {
      return [];
    }

    // Check if the productId has already been processed
    if (processedProductIds.has(item?.productId)) {
      return [];
    }

    // Mark this productId as processed
    processedProductIds.add(item?.productId);

    // Extract relevant details from the single product
    const { colorCode, sizeCode, id, quantity, productAttributeId, imageUrl } =
      product;

    // Construct the result object for the single color and size
    const result = {
      colorCode: [colorCode],
      sizeCode: [sizeCode],
      ids: [id],
      productAttributeIds: [productAttributeId],
      imageUrl: [imageUrl],
      quantity,
      productid: item?.productId,
      bundleType: item?.bundleType,
      totalMiniCartPrice: item?.totalMiniCartPrice,
      bundleValue: item?.bundleValue,
      totalQuantity: item?.totalQuantity,
      bargained: item?.bargained,
      originalPrice: item?.originalPrice,
      finalPrice: item?.finalPrice,
      miniCartProductId: item?.miniCartProductId
    };

    // Return the result as an array
    return [result];
  }

  function groupByColorCode(item, products) {
    // Create an object to store grouped results
    let result = {};
    // Iterate over each product in the array
    products.forEach((product) => {
      const {
        colorCode,
        sizeCode,
        id,
        quantity,
        productAttributeId,
        imageUrl,
      } = product;
      if (!result[colorCode]) {
        result[colorCode] = {
          colorCode: [colorCode],
          sizeCode: [],
          ids: [],
          productAttributeIds: [],
          imageUrl: [],
          quantity: 0,
          productid: item?.productId,
          bundleType: item?.bundleType,
          totalMiniCartPrice: item?.totalMiniCartPrice,
          bundleValue: item?.bundleValue,
          totalQuantity: item?.totalQuantity,
          bargained: item?.bargained,
          originalPrice: item?.originalPrice,
          finalPrice: item?.finalPrice,
          miniCartProductId: item?.miniCartProductId
        };
      }

      // Add the current sizeCode, id and quantity
      result[colorCode].sizeCode.push(sizeCode);
      result[colorCode].ids.push(id);
      result[colorCode].productAttributeIds.push(productAttributeId);
      result[colorCode].quantity = quantity;
      result[colorCode].imageUrl.push(imageUrl);
    });

    // Return the values of the result object as an array
    return Object.values(result);
  }

  function groupBySizeCode(item, products) {
    // Create an object to store grouped results by sizeCode
    let result = {};
    // Iterate over each product in the array
    products.forEach((product) => {
      const {
        sizeCode,
        colorCode,
        id,
        quantity,
        productAttributeId,
        imageUrl,
      } = product;

      // Check if this sizeCode already exists in the result object
      if (!result[sizeCode]) {
        result[sizeCode] = {
          sizeCode: [sizeCode], // Array containing the size code
          colorCode: [], // Array to hold color codes
          ids: [], // Array to hold ids
          quantity: 0,
          imageUrl: [], // To accumulate the quantity
          productAttributeIds: [],
          productid: item?.productId,
          bundleType: item?.bundleType,
          totalMiniCartPrice: item?.totalMiniCartPrice,
          bundleValue: item?.bundleValue,
          totalQuantity: item?.totalQuantity,
          bargained: item?.bargained,
          originalPrice: item?.originalPrice,
          finalPrice: item?.finalPrice,
          miniCartProductId: item?.miniCartProductId
        };
      }

      // Add the current colorCode, id and quantity
      result[sizeCode].colorCode.push(colorCode);
      result[sizeCode].ids.push(id);
      result[sizeCode].productAttributeIds.push(productAttributeId);
      result[sizeCode].imageUrl.push(imageUrl);
      result[sizeCode].quantity = quantity;
    });

    // Return the values of the result object as an array
    return Object.values(result);
  }

  useEffect(() => {
    if (
      miniCart?.miniCartDetails?.readableMiniCart &&
      miniCart?.miniCartDetails?.readableMiniCart.length > 0
    ) {
      // Process all objects in readableMiniCart
      const aggregatedOutput =
        miniCart.miniCartDetails.readableMiniCart.reduce((acc, item) => {
          if (
            item?.miniCartAttributes &&
            item.miniCartAttributes.length > 0
          ) {
            if (item.bundleType === "sizes") {
              return acc.concat(
                groupByColorCode(item, item.miniCartAttributes)
              );
            } else if (item.bundleType === "colors" || item.bundleType === "" || item.bundleType === null) {
              return acc.concat(
                groupBySizeCode(item, item.miniCartAttributes)
              );
            } else {
              return acc.concat(groupByCat(item, item.miniCartAttributes));
            }
          }
          return acc;
        }, []);
      setTableData(aggregatedOutput);
    }

    // else {
    //   if (
    //     miniCart?.miniCartDetails?.readableMiniCart &&
    //     miniCart?.miniCartDetails?.readableMiniCart.length > 0
    //   ) {
    //     const aggregatedOutput = miniCart.miniCartDetails.readableMiniCart.reduce((acc, item) => {
    //       if (item?.miniCartAttributes && item.miniCartAttributes.length > 0) {
    //         return acc.concat(groupByCat(item, item.miniCartAttributes));
    //       }
    //       return acc;
    //     }, []);

    //     setTableData(aggregatedOutput);

    //   }
    // }
  }, [miniCart]);

  // console.log("====>>> Table Data is ====>>> ", tableData);

  return (
    <div>
      <Button
        variant="contained"
        className={classes.miniCartButton}
        startIcon={<AddShoppingCartIcon />}
        onClick={() => handleAddToMiniCart()}
      >
        Add to Minicart
      </Button>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" style={{ paddingLeft: "20px" }}>
                Image
              </StyledTableCell>
              <StyledTableCell align="center">Size</StyledTableCell>
              <StyledTableCell align="center">Color</StyledTableCell>
              <StyledTableCell align="center">
                <Typography>Price per piece</Typography> (without GST)
              </StyledTableCell>
              <StyledTableCell align="center" style={{ paddingLeft: "30px" }}>
                Bundle
              </StyledTableCell>
              <StyledTableCell align="center">Total Quantity</StyledTableCell>
              <StyledTableCell align="center">
                <Typography>Price</Typography> (GST is included)
              </StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          {tableData ? (
            <TableBody>
              {tableData.map((attribute, index) => (
                <StyledTableRow key={attribute?.productid}>
                  <StyledTableCell component="th" align="center" scope="row">
                    {attribute?.imageUrl &&
                      attribute?.imageUrl?.length > 0 &&
                      (() => {
                        const validImages = attribute.imageUrl.map((url) =>
                          url.includes(bunnyCdnUrl) ? url : `${bunnyCdnUrl}${url}`
                        );

                        if (validImages.length > 0) {
                          const randomImage =
                            validImages[Math.floor(Math.random() * validImages.length)];

                          return (
                            <img
                              src={randomImage}
                              alt="Invalid Image"
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "cover",
                              }}
                            />
                          );
                        }
                      })()}
                  </StyledTableCell>

                  <StyledTableCell component="th" align="center" scope="row">
                    {attribute.sizeCode?.map((el) => `${el}, `)}
                  </StyledTableCell>
                  <StyledTableCell component="th" align="center" scope="row">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {attribute.colorCode.map((el) => (
                        <p
                          className={classes.colorbox}
                          style={{
                            backgroundColor: `${el}`,
                            width: "20px", // Set the desired width for each color circle
                            height: "20px", // Set the desired height for each color circle
                            margin: "0 5px", // Add some spacing between circles
                            borderRadius: "50%", // Make the color box a circle
                          }}
                        />
                      ))}
                    </div>
                  </StyledTableCell>

                  {attribute.bargained === "NO" && (<StyledTableCell align="center">
                    Rs.{attribute?.originalPrice}
                  </StyledTableCell>)}

                  {attribute.bargained === "YES" && (
                    <StyledTableCell
                      align="center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: '50%',
                        paddingBottom: '40%',
                      }}
                    >
                      <div style={{ textDecoration: "line-through", margin: 0, padding: 0 }}>
                        Rs.{attribute?.originalPrice}
                      </div>
                      <div style={{ margin: 0, padding: 0 }}>
                        Rs.{attribute?.finalPrice}
                      </div>
                    </StyledTableCell>
                  )}


                  <StyledTableCell align="center">
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="close"
                      size="small"
                      onClick={() => updateQuantity(attribute, "-")}
                    >
                      <RemoveRoundedIcon className={classes.iconButton} />
                    </IconButton>
                    {attribute?.bundleValue}
                    <IconButton
                      edge="end"
                      color="inherit"
                      aria-label="close"
                      size="small"
                      onClick={() => updateQuantity(attribute, "+")}
                    >
                      <AddRoundedIcon className={classes.iconButton} />
                    </IconButton>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {attribute.totalQuantity}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Rs.{attribute.totalMiniCartPrice}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.delete}
                      onClick={() => {
                        {
                          handleBundleProductDelete(attribute, index);
                        }
                      }}
                    >
                      Remove
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow style={{ backgroundColor: "#27B23E" }}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  className={classes.total}
                >
                  Total:
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>

                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center" className={classes.total}>
                  {miniCart?.totalBundleQuantity}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  width="30%"
                  className={classes.total}
                >
                  Rs.{miniCart?.totalPricesWithGST}
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ) : (
            //  : Object.keys(miniCart).length > 0 &&
            //   miniCart?.miniCartDetails?.readableMiniCart[0]?.miniCartAttributes
            //     .length > 0 ? (
            //   <TableBody>
            //     {miniCart?.miniCartDetails?.readableMiniCart[0]?.miniCartAttributes.map(
            //       (attribute, index) => (
            //         <StyledTableRow key={index}>
            //           <StyledTableCell component="th" align="center" scope="row">
            //             {attribute?.imageUrl &&
            //               attribute?.imageUrl.length > 0 &&
            //               (() => {
            //                 const validImages =
            //                   attribute?.imageUrl || attribute?.imageUrl[0];
            //                 // check this code properly
            //                 // attribute && attribute?.imageUrl?.length > 0
            //                 //   ? attribute?.imageUrl?.filter((url) => url)
            //                 //   : [attribute?.imageUrl];

            //                 if (validImages?.length > 0) {
            //                   const randomImage =
            //                     validImages[
            //                     Math.floor(Math.random() * validImages.length)
            //                     ];
            //                   return (
            //                     <img
            //                       src={`${bunnyCdnUrl}${attribute?.imageUrl}`}
            //                       alt="Invalid Image"
            //                       style={{
            //                         width: "60px",
            //                         height: "60px",
            //                         objectFit: "cover",
            //                       }}
            //                     />
            //                   );
            //                 }
            //               })()}
            //           </StyledTableCell>
            //           <StyledTableCell component="th" align="center" scope="row">
            //             {attribute.sizeCode}
            //           </StyledTableCell>
            //           <StyledTableCell align="center">
            //             <p
            //               className={classes.colorbox}
            //               style={{
            //                 backgroundColor: `${attribute.colorCode}`,
            //               }}
            //             />
            //           </StyledTableCell>
            //           <StyledTableCell align="center">
            //             <IconButton
            //               edge="start"
            //               color="inherit"
            //               aria-label="close"
            //               size="small"
            //               onClick={() => removeAndUpdate(attribute, miniCart?.miniCartDetails?.readableMiniCart[0]
            //                 ?.bundleValue)}
            //             >
            //               <RemoveRoundedIcon className={classes.iconButton} />
            //             </IconButton>
            //             {attribute.bundleValue ||
            //               miniCart?.miniCartDetails?.readableMiniCart[0]
            //                 ?.bundleValue}
            //             <IconButton
            //               edge="end"
            //               color="inherit"
            //               aria-label="close"
            //               size="small"
            //               onClick={() => addAndUpdate(attribute, miniCart?.miniCartDetails?.readableMiniCart[0]
            //                 ?.bundleValue)}
            //             >
            //               <AddRoundedIcon className={classes.iconButton} />
            //             </IconButton>
            //           </StyledTableCell>
            //           <StyledTableCell align="center">
            //             {attribute.totalQuantity || attribute?.quantity}
            //           </StyledTableCell>
            //           <StyledTableCell align="center">
            //             {attribute.price}
            //           </StyledTableCell>
            //           <StyledTableCell align="center">
            //             <Button
            //               variant="contained"
            //               size="small"
            //               className={classes.delete}
            //               onClick={() => {
            //                 if (
            //                   product?.categories[0]?.code === "streetwears" ||
            //                   product?.categories[0]?.code === "sneakers"
            //                 ) {
            //                   handleBundleProductDelete(attribute, index);
            //                 } else {
            //                   handleBundleCategoryProductDelete(
            //                     miniCart?.miniCartDetails?.readableMiniCart[0],
            //                     index
            //                   );
            //                 }
            //               }}
            //             >
            //               Delete
            //             </Button>
            //           </StyledTableCell>
            //           <StyledTableCell align="center"></StyledTableCell>
            //         </StyledTableRow>
            //       )
            //     )}
            //     <StyledTableRow style={{ backgroundColor: "#27B23E" }}>
            //       <StyledTableCell
            //         component="th"
            //         scope="row"
            //         className={classes.total}
            //       >
            //         Total:
            //       </StyledTableCell>
            //       <StyledTableCell align="center"></StyledTableCell>
            //       <StyledTableCell align="center"></StyledTableCell>
            //       <StyledTableCell align="center"></StyledTableCell>
            //       <StyledTableCell align="center" className={classes.total}>
            //         {
            //           miniCart?.miniCartDetails?.readableMiniCart[0]
            //             ?.totalQuantity
            //         }
            //       </StyledTableCell>
            //       <StyledTableCell
            //         align="center"
            //         width="30%"
            //         className={classes.total}
            //       >
            //         {
            //           miniCart?.miniCartDetails?.readableMiniCart[0]
            //             ?.totalMiniCartPrice
            //         }
            //       </StyledTableCell>
            //       <StyledTableCell align="center"> </StyledTableCell>
            //       <StyledTableCell align="center"> </StyledTableCell>
            //     </StyledTableRow>
            //   </TableBody>
            // )
            <TableBody>
              <StyledTableRow>
                <StyledTableCell
                  colSpan={5}
                  align="center"
                  className={classes.noFound}
                >
                  Products not found
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <div className={classes.buttons}>
        <Button
          variant="contained"
          size="small"
          className={classes.cancelBbutton}
          onClick={() =>
            handleMiniCartClose(
              miniCart?.miniCartDetails?.readableMiniCart[0]?.id
            )
          }
        >
          Delete MiniCart
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          disabled={
            miniCart?.miniCartDetails?.readableMiniCart?.[0]?.miniCartAttributes
              ?.length > 0 || attribute
              ? false
              : true
          }
          onClick={() =>
            handleAddMiniCartToShoppingCart(
              miniCart?.miniCartDetails?.readableMiniCart[0]?.id
            )
          }
        >
          {back === true ? 'Update Cart' : 'Add to Cart'}
        </Button>
      </div>
    </div>
  );
};
