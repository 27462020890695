import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const elasticSearchUrl = process.env.REACT_APP_ELASTIC_SEARCH_URL;

/* get product by product id */
export const getProductbyId = (id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(`${baseUrl}/product/${id}`);
    dispatch({ type: ActionTypes.PRODUCT_DETAILS_SUCCESS, payload: data });
    dispatch({ type: ActionTypes.PRODUCT_DETAILS_CLEAR_ERROR });
    dispatch({ type: ActionTypes.RESET_PINCODE });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({
        type: ActionTypes.PRODUCT_DETAILS_ERROR,
        payload: "",
      });
    } else {
      dispatch({
        type: ActionTypes.PRODUCT_DETAILS_SERVER_ERROR,
        payload: "Server error , try again later !!",
      });
    }
  }
};

export const getProductbyOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(
      `${baseUrl}/orders/orderedProductReview/${id}`
    );
    dispatch({
      type: ActionTypes.PRODUCT_DETAILS_ORDER_SUCCESS,
      payload: data,
    });
    dispatch({ type: ActionTypes.PRODUCT_DETAILS_CLEAR_ERROR });
    dispatch({ type: ActionTypes.RESET_PINCODE });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({
        type: ActionTypes.PRODUCT_DETAILS_ERROR,
        payload: "",
      });
    } else {
      dispatch({
        type: ActionTypes.PRODUCT_DETAILS_SERVER_ERROR,
        payload: "Server error , try again later !!",
      });
    }
  }
};

/* get reviews by product id */
export const getReviewsByProduct = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/products/${id}/reviews/ratings`
    );
    dispatch({ type: ActionTypes.GET_PRODUCT_REVIEWS, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

/* function to get related products by product id */
export const getRelatedProductsByProduct = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}/related/products/${id}`);
    dispatch({ type: ActionTypes.GET_RELATED_PRODUCTS, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

/* function to get all bids to specific product */
export const getAllBidsByProduct = (skuId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.PRODUCT_BIDS_REQUEST });
    const { data } = await axios.get(
      `${elasticSearchUrl}/api/viewAllProductBargains/${skuId}`
    );
    dispatch({ type: ActionTypes.PRODUCT_ALL_BIDS, payload: data.data });
  } catch (error) {
    if (error.response.status === 404) {
      dispatch({
        type: ActionTypes.PRODUCT_BIDS_ERROR,
        payload: "Not Found",
      });
    } else {
      dispatch({
        type: ActionTypes.PRODUCT_BIDS_SERVER_ERROR,
        payload: "Server error , try again later !!",
      });
    }
  }
};

/* function to get all asks to specific product */
export const getAllAsksByProduct = (skuId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.PRODUCT_BIDS_REQUEST });
    const { data } = await axios.get(
      `${elasticSearchUrl}/api/viewAllAsks/${skuId}`
    );
    dispatch({ type: ActionTypes.PRODUCT_ALL_ASKS, payload: data.data });
  } catch (error) {
    if (error.response.status === 404) {
      dispatch({
        type: ActionTypes.PRODUCT_BIDS_ERROR,
        payload: "Not Found",
      });
    } else {
      dispatch({
        type: ActionTypes.PRODUCT_BIDS_SERVER_ERROR,
        payload: "Server error , try again later !!",
      });
    }
  }
};

/* function to clear product redux state after page unmount */
export const clearProductOnPageUnMount = () => (dispatch) => {
  dispatch({ type: ActionTypes.PRODUCT_DETAILS_SUCCESS, payload: "" });
};

export const updateProductType = (id) => async (dispatch) => {
  try {
    const status = {
      bargain: "NO",
    };

    const { data } = await axios.patch(
      `${baseUrl}/product/${id}/update/bargain/status`,
      status
    );
    dispatch({ type: ActionTypes.UPDATE_BARGAIN_TYPE, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

/* Function to add products to product wishlist */
export const addProductToWishlist = (productId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/auth/customer/wishlist/product/${productId}`,
      productId
    );
    dispatch({ type: ActionTypes.ADD_PRODUCT_WISHLIST, payload: data });
    dispatch({
      type: ActionTypes.SET_SNACKBAR,
      payload: {
        alert: "success",
        message: "Product is successfully added to the wishlist.",
      },
    });
  } catch (error) {
    // console.log(error);
  }
};

/* Function to create mini cart */
export const createMiniCart =
  (persistableMiniCartAttribute) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/auth/product/minicart`,
        persistableMiniCartAttribute
      );
      dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: data });
    } catch (error) {
      dispatch(minimumBuyAlert(error.response.data.message));
    }
  };

export const onLoadData =
  () => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/auth/product/minicart/getMiniCart`
      );
      dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: data });
      return data;
      // dispatch({ type: ActionTypes.PRODUCT_DETAILS_SUCCESS, payload: product });
    } catch (error) {
      dispatch(minimumBuyAlert(error?.response?.data?.message));
    }
  };

export const onLoadMiniCart =
  (persistableMiniCartAttribute) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/auth/product/minicart/getMiniCart`
      );
      if (!data || data.length === 0) {
        await dispatch(createMiniCart(persistableMiniCartAttribute));
      } else {
        dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: data });
      }
    } catch (error) {
      dispatch(minimumBuyAlert(error.response.data.message));
    }
  };

export const onLoadMiniCartBargain =
  (persistableMiniCartAttribute) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/auth/product/minicart/getMiniCart`
      );

      if (!data || data.length === 0) {
        await dispatch(createMiniCart(persistableMiniCartAttribute));
      } else {
        const isMatchFound = data?.miniCartDetails?.readableMiniCart.some((cartItem) =>
          cartItem.miniCartAttributes.some((attribute) =>
            persistableMiniCartAttribute.productAtrbteVariantIds.includes(attribute.productAttributeId)
          )
        );
        if (!isMatchFound) {
          // const { bargain } = await axios.post(
          //   `${baseUrl}/auth/product/minicart/${data?.miniCartDetails?.readableMiniCart[0]?.id}`,
          //   persistableMiniCartAttribute
          // );
          dispatch(addAttributeToMiniCart(data?.miniCartDetails?.readableMiniCart[0]?.id, persistableMiniCartAttribute));
          // dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: bargain });
          // return bargain;
        }
        else {
          dispatch(onLoadData());
        }
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      dispatch(minimumBuyAlert(errorMessage));
      if (
        errorMessage?.includes(
          "ProductAttribute already Added to Minicart id:"
        )
      ) {
        try {
          const { data: repeat } = await axios.get(
            `${baseUrl}/auth/product/minicart/getMiniCart`
          );
          dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: repeat });
        } catch (retryError) {
          dispatch(
            minimumBuyAlert(
              retryError?.response?.data?.message || "Retry failed"
            )
          );
        }
      }
    }
  };

/* Function to add product attributes to minicart */
export const addAttributeToMiniCart =
  (miniCartId, persistableMiniCartAttribute) => async (dispatch) => {
    try {

      const { data } = await axios.post(
        `${baseUrl}/auth/product/minicart/${miniCartId}`,
        persistableMiniCartAttribute
      );
      dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: data });
      return data;
    } catch (error) {
      dispatch(minimumBuyAlert(error.response.data.message));
    }
  };

/* Function to delete product attribute from mini cart */
// export const deleteAttributeFromMiniCart =
//   (miniCartId, miniCartAttrId) => async (dispatch) => {
//     try {
//       const { data } = await axios.post(
//         `${baseUrl}/auth/product/minicart/${miniCartId}/miniCartAttribute/${miniCartAttrId}`
//       );
//       dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: data });
//     } catch (error) {
//       // console.log(error);
//     }
//   };

export const deleteBundleProductFromMiniCart = (miniCartId, miniCartAttributes) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/auth/product/minicart/${miniCartId}/miniCartAttribute`,
      miniCartAttributes
    );

    if (data.miniCartDetails.readableMiniCart.length === 0) {
      window.location.reload();
    } else {
      dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: data });
    }
  } catch (error) {
    dispatch(minimumBuyAlert(error.response.data.message));
  }
};


/* function to delete mini cart completely */
export const deleteMiniCart = (miniCartId) => async (dispatch) => {
  try {
    await axios.delete(`${baseUrl}/auth/product/minicart/${miniCartId}`);
  } catch (error) {
    // console.log(error);
    dispatch(minimumBuyAlert(error.response.data.message));
  }
};

/* Function to move mini cart items to shopping cart */
export const addMiniCartToShoppingCart =
  (miniCartId, history) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/auth/minicart/${miniCartId}/shopingCart`
      );
      dispatch({ type: ActionTypes.ADD_PRODUCT_TO_CART, payload: data });
      dispatch(SuccessMessage("Products added to Cart Successfully"))
      history.push("/cart");
    } catch (error) {
      dispatch(minimumBuyAlert(error.response.data.message));
    }
  };

/* Function to clear mini cart on page unmount */
export const clearMiniCart = () => (dispatch) => {
  dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: {} });
};

/* Function to update attribute quantity in mini cart */
export const updateMiniCartAttributeQuantity =
  (miniCartId, miniCartAttrId, persistableMiniCartAttribute) =>
    async (dispatch) => {
      try {
        const { data } = await axios.put(
          `${baseUrl}/auth/product/minicart/${miniCartId}/miniCartAttribute/${miniCartAttrId}`,
          persistableMiniCartAttribute
        );

        dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: data });
      } catch (error) {
        // console.log(error);
        dispatch(minimumBuyAlert(error.response.data.message));
      }
    };

export const updateMiniCartBundleQuantity =
  (miniCartId, attributeData) => async (dispatch) => {
    try {
      const { data } = await axios.put(
        `${baseUrl}/auth/product/minicart/${miniCartId}/miniCartAttribute`,
        attributeData
      );

      dispatch({ type: ActionTypes.CREATE_MINI_CART, payload: data });
    } catch (error) {
      dispatch(minimumBuyAlert(error.response.data.message));
    }
  };

/* minimum buy alert */
export const minimumBuyAlert = (message) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: {
      alert: "error",
      message: message,
    },
  });
};

export const SuccessMessage = (message) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: {
      alert: "success",
      message: message,
    },
  });
};

export const showBetaMsg = () => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: {
      alert: "success",
      message: "Its a BETA version, We are coming live SOON !!!",
    },
  });
};
