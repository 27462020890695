import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";

/* Import react components */
import { AppBars } from "../../components/AppBar";
import Spinner from "../../util/spinner/spinner";
import { SnackBar } from "../../components/SnackBar";

/* Import redux actions */
import { getProductbyOrder } from "../../redux/actions/productActions";
import { submitProductReviews } from "../../redux/actions/customerActions";

/*Import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";

/* materrial ui css */
const useStyles = makeStyles((theme) => ({
  rootReviews: {
    flexGrow: 1,
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
      fontWeight: 600,
      color: "#000000",
      fontFamily: "'Fira Sans', sans-serif",
      opacity: 0.9,
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiOutlinedInput-inputMultiline": {
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
  },
  details: {
    margin: 24,
  },
  reviewTitle: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#383838",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
  },
  productTitlediv: {
    margin: "auto",
  },
  productTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#383838",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    textTransform: "capitalize",
  },
  imagediv: {
    margin: "auto",
  },
  img: {
    width: 150,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "blue",
      fontSize: 14,
      opacity: 0.4,
    },
  },
  overallTitle: {
    fontSize: 18,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#000000",
  },
  featureslable: {
    fontSize: 16,
    fontWeight: 500,
    color: "#5e5e5e",
    width: 120,
    padding: 6,
    fontFamily: "'Fira Sans', sans-serif",
  },
  submitButton: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.29,
    textAlign: "center",
    background: "#4AD0AA",
    color: "#FFFFFF",
    textTransform: "none",
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  cancelButton: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.29,
    textAlign: "center",
    background: "#ffffff",
    color: "#3b3b3b",
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    border: "1px solid #3b3b3b",
    "&:hover": {
      background: "#ffffff",
      color: "#3b3b3b",
    },
  },
  spacing: {
    marginTop: theme.spacing(1),
  },
  ratingValidationLabel: {
    fontSize: 15,
    fontWeight: 200,
    color: "#FF0000",
    width: 200,
    padding: 9,
    fontFamily: "'Fira Sans', sans-serif",
  }
}));

/* Image base url */
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
const bunnyCdnUrl = process.env.REACT_APP_BUNNY_CDN_URL;

export const WriteReviews = () => {
  const classes = useStyles();
  const params = useParams();

  const { productOrder, loading, error } = useSelector((state) => state.product);
  const { account } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  // const [title, setTitle] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [quality, setQuality] = useState(0);
  const [values, setValues] = useState(0);
  const [matches, setMatches] = useState(0);
  const [pros, setPros] = useState("");
  const [cons, setCons] = useState("");
  const [commentSeller, setCommentSeller] = useState("");
  const [sellerRating, setSellerRating] = useState(0);
  const [formErrors, setFormErrors] = useState({});

  const handleCancelReview = (event) => {
    event.preventDefault();
    history.push(`/customer/portfolio`);
  };

  const handleValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    // if (!title) {
    // 	inputsValid = false;
    // 	formErrors["title"] = "this field is required";
    // }

    if (!review) {
      inputsValid = false;
      formErrors["review"] = "This field is required";
    }

    if (!pros) {
      inputsValid = false;
      formErrors["pros"] = "This field is required";
    }

    if (!cons) {
      inputsValid = false;
      formErrors["cons"] = "This field is required";
    }



    if (!commentSeller) {
      inputsValid = false;
      formErrors["commentSeller"] = "This field is required";
    }



    if(!rating) {
      inputsValid = false;
      formErrors["rating"] = "Please rate this";
    }

    if(!quality) {
      inputsValid = false;
      formErrors["quality"] = "Please rate this";
    }

    if(!values) {
      inputsValid = false;
      formErrors["values"] = "Please rate this";
    } 
    
    if(!matches) {
      inputsValid = false;
      formErrors["matches"] = "Please rate this";
    }

    if(!sellerRating) {
      inputsValid = false;
      formErrors["sellerRating"] = "Please rate this";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      const ratingsData = {
        customerId: account.id,
        description: review,
        language: "en",
        productId: productOrder.product.id,
        product_cons: cons,
        product_pros: pros,
        product_matches: matches,
        product_quality: quality,
        product_values: values,
        rating: rating,
        userComment: commentSeller,
        userRating: sellerRating,
        date: new Date(),
      };

      dispatch(submitProductReviews(ratingsData, history));
    }
  };

  useEffect(() => {
    dispatch(getProductbyOrder(params.id));
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <div className={classes.rootReviews}>
      <AppBars />
      <Container fixed>
        <Grid container justifyContent="center" alignItems="center">
          {loading ? (
            <Spinner />
          ) : error ? (
            <p>{error}</p>
          ) : productOrder ? (
            <>
              <Grid item xs={10}>
                <div className={classes.details}>
                  <Typography className={classes.reviewTitle}>
                    Write your review
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={10}>
                <div style={{ display: "flex" }}>
                  <div className={classes.imagediv}>
                    <img
                      className={classes.img}
                      alt="complex"
                      src={productOrder?.image?.includes(bunnyCdnUrl) ? `${productOrder?.image}` : `${bunnyCdnUrl}${productOrder?.image}`}
                    />
                  </div>

                  <div className={classes.productTitlediv}>
                    <Typography className={classes.productTitle}>
                      {productOrder.product.description.title}
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={10}>
                <div className={classes.details}>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    {/* <div>
									<FormLabel>Enter the Head line of your comment</FormLabel>
									<TextField
										id="title"
										name="title"
										variant="outlined"
										size="small"
										fullWidth={true}
										placeholder="What's most important to know?"
										value={title}
										onChange={(e) => setTitle(e.target.value)}
										InputProps={{
											classes: { input: classes.input },
										}}
										helperText={formErrors.title ? formErrors.title : ""}
									/>
								</div> */}

                    <div className={classes.spacing}>
                      <FormLabel>Write your review <span style={{ color: "red" }}>*</span></FormLabel>
                      <TextField
                        id="outlined-multiline-static"
                        placeholder="What did you like or dislike? What did you use this product for?"
                        multiline
                        fullWidth={true}
                        rows={10}
                        variant="outlined"
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                        InputProps={{
                          classes: { input: classes.input },
                        }}
                        helperText={formErrors.review ? formErrors.review : ""}
                      />
                    </div>

                    <div className={classes.spacing}>
                      <Typography className={classes.overallTitle}>
                        Overall rating <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Rating
                        name="rating"
                        value={rating}
                        precision={1}
                        onChange={(e, newValue) => setRating(newValue)}
                        size="large"
                      />
                      <label name="ratingError" className={classes.ratingValidationLabel} >
                        {(formErrors.rating && (rating === null || rating === 0))? formErrors.rating : ""}
                      </label>
                    </div>

                    <div className={classes.spacing}>
                      <Typography className={classes.overallTitle}>
                        Features
                      </Typography>

                      <div style={{ display: "flex" }}>
                        <Typography className={classes.featureslable}>
                          Quality <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Rating
                          name="quality"
                          value={quality}
                          precision={1}
                          onChange={(e, newValue) => setQuality(newValue)}
                          size="large"
                        />
                        <label name="qualityError" className={classes.ratingValidationLabel} >
                          {(formErrors.quality && (quality === null || quality === 0)) ? formErrors.quality : ""}
                        </label>
                      </div>

                      <div style={{ display: "flex" }}>
                        <Typography className={classes.featureslable}>
                          Values <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Rating
                          name="values"
                          value={values}
                          precision={1}
                          onChange={(e, newValue) => setValues(newValue)}
                          size="large"
                        />
                        <label name="valuesError" className={classes.ratingValidationLabel}>
                          {(formErrors.values && (values === null || values === 0)) ? formErrors.values : ""}
                        </label>
                      </div>

                      <div style={{ display: "flex" }}>
                        <Typography className={classes.featureslable}>
                          Matches <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Rating
                          name="matches"
                          value={matches}
                          precision={1}
                          onChange={(e, newValue) => setMatches(newValue)}
                          size="large"
                        />
                        <label name="matchesError" className={classes.ratingValidationLabel} >
                           {(formErrors.matches && (matches === null || matches === 0)) ? formErrors.matches : ""}
                        </label>
                      </div>
                    </div>

                    <div className={classes.spacing}>
                      <Typography className={classes.overallTitle}>
                        Seller rating <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Rating
                        name="sellerRating"
                        value={sellerRating}
                        precision={1}
                        onChange={(e, newValue) => setSellerRating(newValue)}
                        size="large"
                      />
                      <label name="sellerRatingError" className={classes.ratingValidationLabel} >
                          {(formErrors.sellerRating && (sellerRating === null || sellerRating === 0)) ? formErrors.sellerRating : ""}
                      </label>
                    </div>

                    <div className={classes.spacing}>
                      <FormLabel>Add Pros of the product <span style={{ color: "red" }}>*</span></FormLabel>
                      <TextField
                        id="pros"
                        name="pros"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        placeholder="Ex: color, bargain"
                        value={pros}
                        onChange={(e) => setPros(e.target.value)}
                        InputProps={{
                          classes: { input: classes.input },
                        }}
                        helperText={formErrors.pros  ? formErrors.pros : ""}
                      />
                    </div>

                    <div className={classes.spacing}>
                      <FormLabel>Add Cons of the product <span style={{ color: "red" }}>*</span></FormLabel>
                      <TextField
                        id="cons"
                        name="cons"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        placeholder="Ex: size, delivery"
                        value={cons}
                        onChange={(e) => setCons(e.target.value)}
                        InputProps={{
                          classes: { input: classes.input },
                        }}
                        helperText={formErrors.cons ? formErrors.cons : ""}
                      />
                    </div>

                    <div className={classes.spacing}>
                      <FormLabel>Comment Seller <span style={{ color: "red" }}>*</span></FormLabel>
                      <TextField
                        id="commentSeller"
                        name="commentSeller"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        placeholder="Ex: good, bad"
                        value={commentSeller}
                        onChange={(e) => setCommentSeller(e.target.value)}
                        InputProps={{
                          classes: { input: classes.input },
                        }}
                        helperText={
                          formErrors.commentSeller 
                            ? formErrors.commentSeller
                            : ""
                        }
                      />
                    </div>

                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.cancelButton}
                      onClick={handleCancelReview}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.submitButton}
                    >
                      Submit
                    </Button>
                  </form>
                </div>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Container>

      <SnackBar />
    </div>
  );
};
