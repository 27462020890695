import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

/* react components */

import { AppBars } from "../components/AppBar";
// import { CategoryList } from "../components/CategoryList";
import { SnackBar } from "../components/SnackBar";
import Spinner from "../util/spinner/spinner";
import { Footer } from "../components/Footer";

/* redux actions */
import {
  deleteCartItem,
  addUpdateCartItem,
  removeUpdateCartItem,
  soldOutAlert,
  getCartItemsByUser,
} from "../redux/actions/cartActions";
import { minimumBuyAlert } from "../redux/actions/productActions";
import { getBargainUpdateRequest } from "../util/componentUtil/CheckOutUtiils";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import IconButton from "@material-ui/core/IconButton";
import { isArrayNotEmpty } from "../util/commonUtil/commonUtil";
import MenuHeader from "../components/MenuHeader";
import Axios from "axios";
import Sitemap from "./Sitemap/sitemap";
import usePreviousLocation from "../hooks/previousLocation";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    // '& .MuiSvgIcon-root': {
    //     fontSize: "1rem",
    //     border: "solid 1px #707070",
    //     borderColor: "#53e7bc",
    //     borderRadius: "50%"
    // }
  },
  appBar: {
    position: "relative",
  },
  cartTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#383838",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    marginTop: 85,
    marginBottom: "1rem",
    marginLeft: "5.5rem",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 930,
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.16)",
    border: "1px solid #53e7bc",
    marginTop: theme.spacing(3),
  },
  cartidGrid: {
    borderBottom: "solid 1px #53e7bc",
    display: "flex",
    justifyContent: "space-between",
  },
  cartId: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 600,
    color: "#747474",
    letterSpacing: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
  },
  viewDetails: {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#747474",
  },
  image: {
    width: 140,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  productTitle: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    marginTop: theme.spacing(1),
    textTransform: "capitalize",
  },
  specification: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  specTitle: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
  },
  specValue: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    fontWeight: 600,
    margin: "auto",
  },
  removeButton: {
    position: "relative",
    top: "40%",
    bottom: "10%",
    backgroundColor: "#e75380",
    borderRadius: "3px",
    fontSize: "14px",
    textTransform: "none",
    color: "#ffffff",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    width: "100px",
    "&:hover": {
      backgroundColor: "#e75380",
    },
  },
  checkoutSection: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
  },
  checkoutButton: {
    borderRadius: "3px",
    height: "35px",
    width: "178px",
    position: "relative",
    color: "#ffffff",
    textTransform: "none",
  },
  goBackButton: {
    backgroundColor: "#EFEFEF",
    height: "35px",
    width: "178px",
    marginLeft: "10px",
    position: "relative",
    color: "#000",
    textTransform: "none",
    margin: "10px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  subTotal: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#140101",
    fontFamily: "'Fira Sans', sans-serif",
    paddingTop: theme.spacing(1),
  },
  iconButton: {
    fontSize: "1rem",
    border: "solid 1px #707070",
    borderColor: "#53e7bc",
    borderRadius: "50%",
  },
  emptyCart: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
  },
  outofstock: {
    fontSize: 12,
    margin: "0 auto",
    borderRadius: "2px",
    backgroundColor: "#fff",
    boxShadow: "0 1px 3px 0 rgba(0,0,0,.12)",
    textAlign: "center",
    padding: "10px 15px",
    textTransform: "uppercase",
    pointerEvents: "none",
    position: "absolute",
    top: "50%",
    color: "#27B23E",
    fontWeight: 600,
  },
  outofstockText: {
    fontSize: 16,
    color: "#27B23E",
    fontFamily: "'Fira Sans', sans-serif",
  },
  whiteFont: {
    color: "#fff",
  },
}));

/* image base url */
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
const bunnyCdnUrl = process.env.REACT_APP_BUNNY_CDN_URL;

export const Cart = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("cust"));
  const [showSiteMap1, setShowSiteMap1] = useState(false);
  const { cartItem, loading, serverError } = useSelector(
    (state) => state.shoppingCart
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const previousLocation = usePreviousLocation();

  /* function to increase product quanity */
  const handleAddItem = (item, productAttributeId, cartCode) => {
    let array = item.attributes.map((items) =>
      items.attributeOptionValues.filter(
        (ele) => ele.attributeId === productAttributeId
      )
    );
    if (item.quantity === array.flat()[0].attributeQuantity) {
      dispatch(minimumBuyAlert("Maximum Quantity exceeds."));
    } else {
      const cartData = {
        attributes: [
          {
            id:
              item.cartItemattributes.length > 0
                ? item.cartItemattributes[0].productAttributeId
                : 0,
          },
        ],
        bargainPrice: item.price,
        bargained: item.bargained,
        product: item.id,
        quantity: item.quantity + 1,
      };
      dispatch(addUpdateCartItem(cartData, cartCode, item.cartItemId));
    }
  };

  const gotoProductPage = (item) => {
    history.push(
      `/product/${item[item.length - 1]?.categories[0]?.code}/${item[item.length - 1]?.description?.name.replace(
        / /g,
        "-"
      )}/${item[item.length - 1]?.id}`,
      { back: true }
    );
  };

  /* function to decrease product quantity */
  const handleRemoveItem = (item, cartCode) => {
    if (item.quantity === item.cartItemattributes[0].minQuantity) {
      dispatch(
        minimumBuyAlert(
          "Sorry, Quantity should be greater than or equal to minimum buy."
        )
      );
    } else {
      const cartData = {
        attributes: [
          {
            id:
              item.cartItemattributes.length > 0
                ? item.cartItemattributes[0].productAttributeId
                : 0,
          },
        ],
        bargainPrice: item.price,
        bargained: item.bargained,
        product: item.id,
        quantity: item.quantity - 1,
      };

      dispatch(removeUpdateCartItem(cartData, cartCode, item.cartItemId));
    }
  };

  /* function to delete product from cart */
  const handleDeleteCartItem = (item, cartCode, userId) => {
    let bargainUpdateRequest = getBargainUpdateRequest(
      [item],
      userId,
      "REMOVED"
    );

    dispatch(
      deleteCartItem(
        item.id,
        cartCode,
        userId,
        item.cartItemId,
        bargainUpdateRequest
      )
    );
  };
  /* function to checkout cart products */
  const handleGoToCheckout = async (items) => {
    const cartItems = [];
    items.map((itemDetails) => {
      const itemCheck = {
        cartAttributeDetails: [
          {
            productAttributeId:
              itemDetails.cartItemattributes[0].productAttributeId,
            productAttributeAvailableQuantity: null,
            minQuantity: itemDetails.quantity,
            outOfStock: null,
            message: null,
          },
        ],
        userId: itemDetails.productUserDetail.userId,
        productId: itemDetails.id,
      };
      cartItems.push(itemCheck);
    });
    const soldOutCheck = {
      cartItem: cartItems,
    };

    const { data } = await Axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/orders/checkOutOfStock`,
      soldOutCheck
    );

    let soldOut = data.cartItem.filter((dataLIst) => {
      return dataLIst.cartAttributeDetails[0].outOfStock === true;
    });

    if (soldOut.length > 0) {
      dispatch(soldOutAlert());
      await dispatch(getCartItemsByUser(cartItem.customer));
    } else {
      history.push("/checkout");
    }
  };

  const getPrice = (item, productAttributeId) => {
    let array = item.attributes.map((items) =>
      items.attributeOptionValues.filter(
        (ele) => ele.attributeId === productAttributeId
      )
    );
    var newArr = [];
    for (var i = 0; i < array.length; i++) {
      newArr = newArr.concat(array[i]);
    }

    if (isArrayNotEmpty(newArr) && item.bargain !== "YES") {
      return newArr[0]?.attributePrice;
    }
    return item.bargainPrice || newArr[0]?.attributePrice;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBars position="absolute" color="default" className={classes.appBar} />
      {/* <CategoryList /> */}
      {!showSiteMap1 ? (
        <>
          <MenuHeader />
          <Container style={{ margin: "30px 0px 30px 0px" }}>
            <Grid item xs={12}>
              <Typography component="h6" className={classes.cartTitle}>
                My Shopping Cart{" "}
                {cartItem.products
                  ? `(${cartItem.products.length} items)`
                  : null}
              </Typography>
            </Grid>

            {loading ? (
              <Spinner />
            ) : serverError ? (
              <Paper className={classes.paper}>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.emptyCart}
                >
                  {/* {serverError} */}
                  Loading ...
                </Typography>
              </Paper>
            ) : (
              <Fragment>
                {Object.keys(cartItem).length > 0 &&
                  cartItem.products.length > 0 ? (
                  cartItem.products
                    .sort((a, b) => b.cartItemId - a.cartItemId)
                    .map((item, index) => (
                      <Fragment key={index}>
                        {item.categories[0].code === "sneakers" ||
                          item.categories[0].code === "streetwears" ? (
                          // Render based on item.bundleType
                          item.bundleType === "sizes" ? (
                            // Render for bundleType = "sizes"
                            <Paper className={classes.paper}>
                              <Grid item className={classes.cartidGrid}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.cartId}
                                >
                                  ItemID: {item.id}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.viewDetails}
                                >
                                  Type:{" "}
                                  {item.bargained === "YES"
                                    ? "Bargained"
                                    : "Fixed"}
                                </Typography>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item>
                                  <ButtonBase
                                    className={classes.image}
                                    style={{
                                      opacity: item.outOfStock ? 0.6 : 1,
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/product/${item.categories[0].code
                                        }/${item.description.name.replace(
                                          / /g,
                                          "-"
                                        )}/${item.id}`,
                                        { back: true }
                                      )
                                    }
                                  >
                                    {item.cartItemattributes.length > 0 ? (
                                      (() => {
                                        const validAttribute =
                                          item.cartItemattributes.find(
                                            (attr) => attr.optionValue?.image
                                          );
                                        return validAttribute ? (
                                          <img
                                            src={validAttribute?.optionValue?.image?.includes(bunnyCdnUrl) ? `${validAttribute?.optionValue?.image}` : `${bunnyCdnUrl}${validAttribute?.optionValue?.image}`}
                                            alt="Invalid image"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              borderRadius: "4px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            className={classes.img}
                                            alt="complex"
                                            src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                          />
                                        );
                                      })()
                                    ) : (
                                      <img
                                        className={classes.img}
                                        alt="complex"
                                        src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                      />
                                    )}
                                    {item.outOfStock && (
                                      <Typography
                                        className={classes.outofstock}
                                      >
                                        Sold Out
                                      </Typography>
                                    )}
                                  </ButtonBase>
                                </Grid>

                                <Grid item xs={12} sm container>
                                  <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    spacing={1}
                                  >
                                    <Grid item xs>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.productTitle}
                                      >
                                        {item.description.name}
                                      </Typography>
                                      <Box className={classes.specification}>
                                        <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Default Color
                                          </Typography>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            flexWrap="wrap"
                                          >
                                            {item.cartItemattributes.length >
                                              0 ? (
                                              <p
                                                key={index}
                                                className={classes.colorbox}
                                                style={{
                                                  backgroundColor: `${item?.cartItemattributes[0]
                                                    ?.optionValue?.name ||
                                                    "transparent"
                                                    }`,
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "50%",
                                                  margin: "5px 5px",
                                                  border: "1px solid #ccc",
                                                }}
                                              />
                                            ) : (
                                              <Typography
                                                variant="subtitle1"
                                                className={classes.specValue}
                                              >
                                                -
                                              </Typography>
                                            )}
                                          </Box>
                                        </Box>
                                        <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Sizes
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.specValue}
                                          >
                                            {item.cartItemattributes
                                              .map((attr) => attr.option?.code)
                                              .join(", ")}
                                          </Typography>
                                        </Box>

                                        <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Price without GST
                                          </Typography>

                                          <Typography
                                            variant="subtitle1"
                                            className={classes.specValue}
                                          >
                                            {getPrice(
                                              item,
                                              item?.cartItemattributes[0]
                                                ?.productAttributeId
                                            )}
                                          </Typography>
                                        </Box>

                                        <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Total Quantity
                                          </Typography>

                                          <Typography
                                            variant="subtitle1"
                                            className={classes.specValue}
                                          >
                                            {item?.quantity}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                  {/* <Button
                                  variant="contained"
                                  size="small"
                                  id="cart-remove"
                                  className={classes.removeButton}
                                  onClick={() =>
                                    handleDeleteCartItem(
                                      item,
                                      cartItem.code,
                                      user.id
                                    )
                                  }
                                >
                                  Remove
                                </Button> */}
                                </Grid>
                              </Grid>
                            </Paper>
                          ) : (item.bundleType === "colors" || item.bundleType === "") ? (
                            // Render for bundleType = "colors"
                            <Paper className={classes.paper}>
                              <Grid item className={classes.cartidGrid}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.cartId}
                                >
                                  ItemID: {item.id}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.viewDetails}
                                >
                                  Type:{" "}
                                  {item.bargained === "YES"
                                    ? "Bargained"
                                    : "Fixed"}
                                </Typography>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item>
                                  <ButtonBase
                                    className={classes.image}
                                    style={{
                                      opacity: item.outOfStock ? 0.6 : 1,
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/product/${item.categories[0].code
                                        }/${item.description.name.replace(
                                          / /g,
                                          "-"
                                        )}/${item.id}`,
                                        { back: true }
                                      )
                                    }
                                  >
                                    {item.cartItemattributes.length > 0 ? (
                                      (() => {
                                        const validAttribute =
                                          item.cartItemattributes.find(
                                            (attr) => attr.optionValue?.image
                                          );
                                        return validAttribute ? (
                                          <img
                                            src={validAttribute?.optionValue?.image?.includes(bunnyCdnUrl) ? `${validAttribute?.optionValue?.image}` : `${bunnyCdnUrl}${validAttribute?.optionValue?.image}`}
                                            alt="Invalid image"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              borderRadius: "4px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            className={classes.img}
                                            alt="complex"
                                            src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                          />
                                        );
                                      })()
                                    ) : (
                                      <img
                                        className={classes.img}
                                        alt="complex"
                                        src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                      />
                                    )}
                                    {item.outOfStock && (
                                      <Typography
                                        className={classes.outofstock}
                                      >
                                        Sold Out
                                      </Typography>
                                    )}
                                  </ButtonBase>
                                </Grid>

                                <Grid item xs={12} sm container>
                                  <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    spacing={1}
                                  >
                                    <Grid item xs>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.productTitle}
                                      >
                                        {item.description.name}
                                      </Typography>
                                      <Box className={classes.specification}>
                                        <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Colors
                                          </Typography>

                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            flexWrap="wrap"
                                          >
                                            {item.cartItemattributes.length >
                                              0 ? (
                                              item.cartItemattributes.map(
                                                (attr, index) => (
                                                  <p
                                                    key={index}
                                                    className={classes.colorbox}
                                                    style={{
                                                      backgroundColor: `${attr.optionValue
                                                        ?.name ||
                                                        "transparent"
                                                        }`,
                                                      width: "20px",
                                                      height: "20px",
                                                      borderRadius: "50%",
                                                      margin: "5px 5px",
                                                      border: "1px solid #ccc",
                                                    }}
                                                  />
                                                )
                                              )
                                            ) : (
                                              <Typography
                                                variant="subtitle1"
                                                className={classes.specValue}
                                              >
                                                -
                                              </Typography>
                                            )}
                                          </Box>
                                        </Box>

                                        <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Default Size
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.specValue}
                                          >
                                            {item?.cartItemattributes[0]?.option
                                              ?.code || "-"}
                                          </Typography>
                                        </Box>

                                        <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Price without GST
                                          </Typography>

                                          <Typography
                                            variant="subtitle1"
                                            className={classes.specValue}
                                          >
                                            {getPrice(
                                              item,
                                              item?.cartItemattributes[0]
                                                ?.productAttributeId
                                            )}
                                          </Typography>
                                        </Box>

                                        <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Total Quantity
                                          </Typography>

                                          <Typography
                                            variant="subtitle1"
                                            className={classes.specValue}
                                          >
                                            {item?.quantity}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={3}>
                                    {/* <Button
                                    variant="contained"
                                    size="small"
                                    id="cart-remove"
                                    className={classes.removeButton}
                                    onClick={() =>
                                      handleDeleteCartItem(
                                        item,
                                        cartItem.code,
                                        user.id
                                      )
                                    }
                                  >
                                    Remove
                                  </Button> */}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          ) : (
                            <Paper className={classes.paper}>
                              <Grid item className={classes.cartidGrid}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.cartId}
                                >
                                  ItemID : {item.id}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.viewDetails}
                                >
                                  Type :{" "}
                                  {item.bargained === "YES"
                                    ? "Bargained"
                                    : "Fixed"}
                                </Typography>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item>
                                  <ButtonBase
                                    className={classes.image}
                                    style={{
                                      opacity: item.outOfStock ? 0.6 : 1,
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/product/${item.categories[0].code
                                        }/${item.description.name.replace(
                                          / /g,
                                          "-"
                                        )}/${item.id}`,
                                        { back: true }
                                      )
                                    }
                                  >
                                    {item.cartItemattributes.length > 0 ? (
                                      (() => {
                                        const validAttribute =
                                          item.cartItemattributes.find(
                                            (attr) => attr.optionValue?.image
                                          );
                                        return validAttribute ? (
                                          <img
                                            src={validAttribute?.optionValue?.image?.includes(bunnyCdnUrl) ? `${validAttribute?.optionValue?.image}` : `${bunnyCdnUrl}${validAttribute?.optionValue?.image}`}
                                            alt="Invalid image"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              borderRadius: "4px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            className={classes.img}
                                            alt="complex"
                                            src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                          />
                                        );
                                      })()
                                    ) : (
                                      <img
                                        className={classes.img}
                                        alt="complex"
                                        src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                      />
                                    )}

                                    {item.outOfStock ? (
                                      <Typography
                                        className={classes.outofstock}
                                      >
                                        Sold Out
                                      </Typography>
                                    ) : null}
                                  </ButtonBase>
                                </Grid>

                                <Grid item xs={12} sm container>
                                  <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    spacing={1}
                                  >
                                    <Grid item xs>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.productTitle}
                                      >
                                        {item.description.name}
                                      </Typography>

                                      <Box className={classes.specification}>
                                        {item.outOfStock ? (
                                          <Typography
                                            className={classes.outofstockText}
                                          >
                                            Sorry, this product is currently out
                                            of stock.
                                          </Typography>
                                        ) : (
                                          <Fragment>
                                            <Box>
                                              <Typography
                                                variant="caption"
                                                className={classes.specTitle}
                                              >
                                                Color
                                              </Typography>

                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                flexWrap="wrap"
                                              >
                                                {item.cartItemattributes.length >
                                                  0 ? (
                                                  item.cartItemattributes.map(
                                                    (attr, index) => (
                                                      <p
                                                        key={index}
                                                        className={classes.colorbox}
                                                        style={{
                                                          backgroundColor: `${attr.optionValue
                                                            ?.name ||
                                                            "transparent"
                                                            }`,
                                                          width: "20px",
                                                          height: "20px",
                                                          borderRadius: "50%",
                                                          margin: "5px 5px",
                                                          border: "1px solid #ccc",
                                                        }}
                                                      />
                                                    )
                                                  )
                                                ) : (
                                                  <Typography
                                                    variant="subtitle1"
                                                    className={classes.specValue}
                                                  >
                                                    -
                                                  </Typography>
                                                )}
                                              </Box>
                                            </Box>

                                            <Box>
                                              <Typography
                                                variant="caption"
                                                className={classes.specTitle}
                                              >
                                                Size
                                              </Typography>

                                              <Typography
                                                variant="subtitle1"
                                                className={classes.specValue}
                                              >
                                                {item.cartItemattributes
                                                  ?.length > 0 &&
                                                  (item.cartItemattributes[0]
                                                    .option?.code ===
                                                    "Watch Size"
                                                    ? "One Size"
                                                    : item.cartItemattributes[0]
                                                      .option?.code ===
                                                      "Bag Size"
                                                      ? "One Size"
                                                      : item.cartItemattributes[0]
                                                        .option?.code ===
                                                        "Toy Size"
                                                        ? "One Size"
                                                        : item.cartItemattributes[0]
                                                          .option?.code)}
                                              </Typography>
                                            </Box>

                                            <Box>
                                              <Typography
                                                variant="caption"
                                                className={classes.specTitle}
                                              >
                                                Price without GST
                                              </Typography>

                                              <Typography
                                                variant="subtitle1"
                                                className={classes.specValue}
                                              >
                                                {getPrice(
                                                  item,
                                                  item?.cartItemattributes[0]
                                                    ?.productAttributeId
                                                )}
                                              </Typography>
                                            </Box>

                                            <Box>
                                              <Typography
                                                variant="caption"
                                                className={classes.specTitle}
                                              >
                                                Total Quantity
                                              </Typography>

                                              <Typography
                                                variant="subtitle1"
                                                className={classes.specValue}
                                              >
                                                {item?.quantity}
                                              </Typography>
                                            </Box>

                                            {/* <Box>
                                            <Typography
                                              variant="caption"
                                              className={classes.specTitle}
                                            >
                                              Quantity
                                            </Typography>

                                            <Box
                                              style={{
                                                display: "flex",
                                              }}
                                            >
                                              {item.bargained === "NO" && (
                                                <IconButton
                                                  edge="start"
                                                  color="inherit"
                                                  aria-label="close"
                                                  size="small"
                                                  onClick={() =>
                                                    handleRemoveItem(
                                                      item,
                                                      cartItem.code
                                                    )
                                                  }
                                                >
                                                  <RemoveRoundedIcon
                                                    className={classes.iconButton}
                                                  />
                                                </IconButton>
                                              )}

                                              <Typography
                                                variant="subtitle1"
                                                className={classes.specValue}
                                              >
                                                {item.quantity}
                                              </Typography>

                                              {item.bargained === "NO" && (
                                                <IconButton
                                                  edge="end"
                                                  color="inherit"
                                                  aria-label="close"
                                                  size="small"
                                                  onClick={() =>
                                                    handleAddItem(
                                                      item,
                                                      item?.cartItemattributes[0]
                                                        ?.productAttributeId,
                                                      cartItem.code
                                                    )
                                                  }
                                                >
                                                  <AddRoundedIcon
                                                    className={classes.iconButton}
                                                  />
                                                </IconButton>
                                              )}
                                            </Box> */}
                                            {/* </Box> */}
                                          </Fragment>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                  {/* <Button
                                  variant="contained"
                                  size="small"
                                  id="cart-remove"
                                  className={classes.removeButton}
                                  onClick={() =>
                                    handleDeleteCartItem(
                                      item,
                                      cartItem.code,
                                      user.id
                                    )
                                  }
                                >
                                  Remove
                                </Button> */}
                                </Grid>
                              </Grid>
                            </Paper>
                          )
                        ) : (
                          <Paper className={classes.paper}>
                            <Grid item className={classes.cartidGrid}>
                              <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.cartId}
                              >
                                ItemID : {item.id}
                              </Typography>

                              <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.viewDetails}
                              >
                                Type :{" "}
                                {item.bargained === "YES"
                                  ? "Bargained"
                                  : "Fixed"}
                              </Typography>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item>
                                <ButtonBase
                                  className={classes.image}
                                  style={{ opacity: item.outOfStock ? 0.6 : 1 }}
                                  onClick={() =>
                                    history.push(
                                      `/product/${item.categories[0].code
                                      }/${item.description.name.replace(
                                        / /g,
                                        "-"
                                      )}/${item.id}`,
                                      { back: true }
                                    )
                                  }
                                >
                                  {item.cartItemattributes.length > 0 ? (
                                    (() => {
                                      const validAttribute =
                                        item.cartItemattributes.find(
                                          (attr) => attr.optionValue?.image
                                        );
                                      return validAttribute ? (
                                        <img
                                          src={validAttribute?.optionValue?.image?.includes(bunnyCdnUrl) ? `${validAttribute?.optionValue?.image}` : `${bunnyCdnUrl}${validAttribute?.optionValue?.image}`}
                                          alt="Invalid image"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "4px",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          className={classes.img}
                                          alt="complex"
                                          src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                        />
                                      );
                                    })()
                                  ) : (
                                    <img
                                      className={classes.img}
                                      alt="complex"
                                      src={item?.image?.imageUrl?.includes(bunnyCdnUrl) ? `${item?.image?.imageUrl}` : `${bunnyCdnUrl}${item?.image?.imageUrl}`}
                                    />
                                  )}

                                  {item.outOfStock ? (
                                    <Typography className={classes.outofstock}>
                                      Sold Out
                                    </Typography>
                                  ) : null}
                                </ButtonBase>
                              </Grid>

                              <Grid item xs={12} sm container>
                                <Grid
                                  item
                                  xs
                                  container
                                  direction="column"
                                  spacing={1}
                                >
                                  <Grid item xs>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.productTitle}
                                    >
                                      {item.description.name}
                                    </Typography>

                                    <Box className={classes.specification}>
                                      {item.outOfStock ? (
                                        <Typography
                                          className={classes.outofstockText}
                                        >
                                          Sorry, this product is currently out
                                          of stock.
                                        </Typography>
                                      ) : (
                                        <Fragment>
                                          <Box>
                                            <Typography
                                              variant="caption"
                                              className={classes.specTitle}
                                            >
                                              Color
                                            </Typography>

                                            <Box
                                              display="flex"
                                              alignItems="center"
                                              flexWrap="wrap"
                                            >
                                              {item.cartItemattributes.length >
                                                0 ? (
                                                item.cartItemattributes.map(
                                                  (attr, index) => (
                                                    <p
                                                      key={index}
                                                      className={classes.colorbox}
                                                      style={{
                                                        backgroundColor: `${attr.optionValue
                                                          ?.name ||
                                                          "transparent"
                                                          }`,
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "50%",
                                                        margin: "5px 5px",
                                                        border: "1px solid #ccc",
                                                      }}
                                                    />
                                                  )
                                                )
                                              ) : (
                                                <Typography
                                                  variant="subtitle1"
                                                  className={classes.specValue}
                                                >
                                                  -
                                                </Typography>
                                              )}
                                            </Box>
                                          </Box>

                                          <Box>
                                            <Typography
                                              variant="caption"
                                              className={classes.specTitle}
                                            >
                                              Size
                                            </Typography>

                                            <Typography
                                              variant="subtitle1"
                                              className={classes.specValue}
                                            >
                                              {item.cartItemattributes?.length >
                                                0 &&
                                                (item.cartItemattributes[0]
                                                  .option?.code === "Watch Size"
                                                  ? "One Size"
                                                  : item.cartItemattributes[0]
                                                    .option?.code ===
                                                    "Bag Size"
                                                    ? "One Size"
                                                    : item.cartItemattributes[0]
                                                      .option?.code ===
                                                      "Toy Size"
                                                      ? "One Size"
                                                      : item.cartItemattributes[0]
                                                        .option?.code)}
                                            </Typography>
                                          </Box>

                                          <Box>
                                            <Typography
                                              variant="caption"
                                              className={classes.specTitle}
                                            >
                                              Price without GST
                                            </Typography>

                                            <Typography
                                              variant="subtitle1"
                                              className={classes.specValue}
                                            >
                                              {getPrice(
                                                item,
                                                item?.cartItemattributes[0]
                                                  ?.productAttributeId
                                              )}
                                            </Typography>
                                          </Box>

                                          <Box>
                                            <Typography
                                              variant="caption"
                                              className={classes.specTitle}
                                            >
                                              Total Quantity
                                            </Typography>

                                            <Typography
                                              variant="subtitle1"
                                              className={classes.specValue}
                                            >
                                              {item?.quantity}
                                            </Typography>
                                          </Box>

                                          {/* <Box>
                                          <Typography
                                            variant="caption"
                                            className={classes.specTitle}
                                          >
                                            Quantity
                                          </Typography>

                                          <Box
                                            style={{
                                              display: "flex",
                                            }}
                                          >
                                            {item.bargained === "NO" && (
                                              <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="close"
                                                size="small"
                                                onClick={() =>
                                                  handleRemoveItem(
                                                    item,
                                                    cartItem.code
                                                  )
                                                }
                                              >
                                                <RemoveRoundedIcon
                                                  className={classes.iconButton}
                                                />
                                              </IconButton>
                                            )}

                                            <Typography
                                              variant="subtitle1"
                                              className={classes.specValue}
                                            >
                                              {item.quantity}
                                            </Typography>

                                            {item.bargained === "NO" && (
                                              <IconButton
                                                edge="end"
                                                color="inherit"
                                                aria-label="close"
                                                size="small"
                                                onClick={() =>
                                                  handleAddItem(
                                                    item,
                                                    item?.cartItemattributes[0]
                                                      ?.productAttributeId,
                                                    cartItem.code
                                                  )
                                                }
                                              >
                                                <AddRoundedIcon
                                                  className={classes.iconButton}
                                                />
                                              </IconButton>
                                            )}
                                          </Box>
                                        </Box> */}
                                        </Fragment>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                {/* <Button
                                variant="contained"
                                size="small"
                                id="cart-remove"
                                className={classes.removeButton}
                                onClick={() =>
                                  handleDeleteCartItem(
                                    item,
                                    cartItem.code,
                                    user.id
                                  )
                                }
                              >
                                Remove
                              </Button> */}
                              </Grid>
                            </Grid>
                          </Paper>
                        )}
                      </Fragment>
                    ))
                ) : (
                  <Paper className={classes.paper}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.emptyCart}
                    >
                      Your OutXtock Basket is empty
                    </Typography>
                  </Paper>
                )}

                <Grid container spacing={2}>
                  {Object.keys(cartItem).length > 0 &&
                    cartItem.products.length > 0 ? (
                    <Grid item xs={12} style={{ margin: "10px 0 50px 0" }}>
                      <div className={classes.checkoutSection}>
                        <Typography component="h6" className={classes.subTotal}>
                          Subtotal without GST : {cartItem.displayBaseTotal}
                        </Typography>

                        <Box>
                          <Button
                            className={classes.goBackButton}
                            onClick={() => gotoProductPage(cartItem.products)}
                          >
                            Back to MiniCart
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.checkoutButton}
                            onClick={() =>
                              handleGoToCheckout(cartItem.products)
                            }
                          >
                            Checkout
                          </Button>
                        </Box>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Fragment>
            )}
          </Container>
        </>
      ) : (
        <Sitemap />
      )}

      <Footer showSiteMap={showSiteMap1} setShowSiteMap={setShowSiteMap1} />

      <SnackBar />
    </div>
  );
};
