import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Editor, EditorState, RichUtils, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

/* import react component */
import { MyEditor } from "../editor/Editor";

/* import react redux actions */
import {
  addAttribute,
  deleteAttributeOption,
} from "../../../redux/actions/sellerActions";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import { validatePositiveNumberEvent } from "./../../../util/commonUtil/commonUtil";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  dilougDiv: {
    background: "#F7F7F7",
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  appBar: {
    position: "fixed",
    background: "#53E7BC",
    marginBottom: "1rem",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1100,
  },
  title: {
    fontSize: 18,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#383838",
    margin: "auto",
  },
  gridSection: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // marginTop: '20px',
      // background: '#F7F7F7',
    },
  },
  details: {
    textAlign: "center",
    margin: "auto",
    padding: theme.spacing(1),
    paddingTop: 100,
    width: "70%",
    // border: "1px solid #d8d8d8",
    borderRadius: "4px",
  },
  labletext: {
    color: "#4A4A4A",
    fontSize: 15,
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
  },
  span: {
    color: "#4A4A4A",
    fontSize: "16px",
    fontWeight: "normal",
    fontFamily: "'Fira Sans', sans-serif",
  },
  headingSec: {
    padding: "6px",
    color: "#4A4A4A",
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "20px 0px 0px 26px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "90%",
    margin: "auto",
    border: "1px solid #d8d8d8",
    "& .MuiFormControlLabel-label": {
      color: "#383838",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: "normal",
      fontSize: 12,
    },
  },
  sectionDiv: {
    padding: theme.spacing(2),
  },
  colorpick: {
    margin: "auto",
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
    opacity: 0.5,
  },
  uploadInput: {
    display: "none",
  },
  uploadImageButton: {
    textTransform: "none",
    fontFamily: "'Fira Sans', sans-serif",
    background: "#4AD0AA",
    margin: "20px",
    color: "#FFFFFF",
    fontWeight: 600,
    marginBottom: 0,
    "&:hover": {
      background: "#4AD0AA",
    },
  },
  submitdiv: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "center",
  },
  submitButton: {
    textTransform: "none",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  cancelButton: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    textTransform: "none",
    "&:hover": {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
  tablediv: {
    padding: theme.spacing(3),
  },
  table: {
    minWidth: 650,
    "& .MuiTableCell-body": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#2d2b2b",
    },
    "& .MuiTableCell-head": {
      backgroundColor: "#eeeeee",
      color: "#3c3c3c",
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: "13px",
      fontWeight: 900,
    },
  },
  tablehead: {
    backgroundColor: "#eeeeee",
    color: "#3c3c3c",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 900,
  },
  inputErrorBlock: {
    fontSize: "13px",
    color: "red",
    width: "80%",
    margin: "auto",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
  },
  noFound: {
    color: "#030104",
    textAlign: "center",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
    marginBottom: "10px",
  },
  deleteButton: {
    border: "1px solid #53E7BC",
    background: "transparent",
    color: "#53E7BC",
    borderRadius: "0.2rem",
    margin: "4px",
  },
  imageholder: {
    margin: "auto",
    width: "150px",
    height: "150px",
    border: "1px solid #d8d8d8",
    borderRadius: "3px",
    marginTop: "1rem",
  },
  img: {
    margin: "18px 0px 8px 0px",
    width: "85px",
    height: "85px",
    border: "1px solid #d8d8d8",
    borderRadius: "3px",
    padding: theme.spacing(1),
  },
  colorpicker: {
    width: "18px",
    height: "18px",
    border: "1px solid #e0e0e0",
    cursor: "pointer",
    borderRadius: "5px",
    margin: "10px 10px 0 0",
    userSelect: "none",
    transition: "ease all 0.3s",
  },
  formControl: {
    color: "#aaa",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 14,
  },
  colorCodes: {
    flexGrow: 1,
    height: "250px",
    overflowY: "scroll",
    border: "1px solid #d8d8d8",
    borderRadius: "3px",
    margin: "auto",
    padding: "0px 0px 16px 16px",
    width: "90%",
  },
  closeIcon: {
    color: "#000000",
    marginLeft: "-27px",
    marginTop: "-75px",
  },
  iconButton: {
    padding: '4px',
    color: '#1976d2',
    backgroundColor: '#f9f9f9',
    borderRadius: '50%',
    border: '2px solid transparent',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: '#fff',
      border: '2px solid #1976d2',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    },
  },
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    fontSize: '0.85rem',
    padding: '8px 8px',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
    transition: 'opacity 0.3s ease',
  },
  arrow: {
    color: '#333',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NUMERICSPEL_ONLY = /^[0-9]+$/;
const NUMDOT_ONLY = /^[0-9.]+$/;
export const AddAttribute = (props) => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const {
    open,
    handleaddAttributeClose,
    product,
    sizeoption,
    coloroption,
    onsubmit,
  } = props;
  const dispatch = useDispatch();

  const [size, setSize] = useState({ id: "", code: "" });
  const [styleCode, setStyleCode] = useState("");
  const [itemSkuCode, setItemSkuCode] = useState("");
  const [styleDesc, setStyleDesc] = useState("");
  const [tradeDiscount, setTradeDiscount] = useState("");
  const [price, setPrice] = useState("");
  const [mrp, setMrp] = useState("");
  const [quantity, setQuantity] = useState("");
  const [minQuantity, setMinQuantity] = useState(1);
  const [ean, setEan] = useState("");
  const [height, setHeight] = useState("");
  const [length, setLength] = useState("");
  const [weight, setWeight] = useState("");
  const [width, setWidth] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [colorCode, setColorCode] = useState("");
  const [images, setImages] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [previews, setPreviews] = useState([]);
  const [allImgSize, setAllImgSize] = useState(0);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [bundleValue, setBundleValue] = useState("");


  /* set size */

  const handleSelectSize = (code) => {
    setSelectedSizes((prevSelectedSizes) =>
      prevSelectedSizes.includes(code)
        ? prevSelectedSizes.filter((size) => size !== code)
        : [...prevSelectedSizes, code]
    );
  };

  const handleSelectColor = (color) => {
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.includes(color)
        ? prevSelectedColors.filter((col) => col !== color)
        : [...prevSelectedColors, color]
    );
  };

  const handleSingleSelectSize = (e) => {
    const code = e.target.value;
    setSelectedSizes([code]);
  };

  const handleSingleSelectColor = (color) => {
    setSelectedColors([color]);
  };

  /* upload single image */
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const validExtensions = ["jpg", "jpeg", "png"];
    const maxFileSize = 1.5 * 1024 * 1024;

    if (files.length > 5) {
      alert("You can upload up to 5 images only.");
      return;
    }

    const filteredFiles = files.filter((file) => {
      const isValidExtension = validExtensions.includes(
        file.name.split(".").pop().toLowerCase()
      );
      const isValidSize = file.size <= maxFileSize;
      if (!isValidExtension) {
        alert(`${file.name} has an invalid file type.`);
      } else if (!isValidSize) {
        alert(`${file.name} exceeds 1.5MB size limit.`);
      }
      return isValidExtension && isValidSize;
    });

    if (filteredFiles.length === 0) {
      return;
    }

    const newImages = [...images, ...filteredFiles];
    const newPreviews = [
      ...previews,
      ...filteredFiles.map((file) => URL.createObjectURL(file)),
    ];

    if (newImages.length > 5) {
      alert("You can upload up to 5 images only.");
      setImages(newImages.slice(0, 5));
      setPreviews(newPreviews.slice(0, 5));
    } else {
      setImages(newImages);
      setPreviews(newPreviews);
    }

    const totalSize = newImages.reduce((acc, file) => acc + file.size, 0);
    setAllImgSize(totalSize);
  };

  const handleRemoveImage = () => {
    if (images.length > 0) {
      const newImages = images.slice(0, -1);
      const newPreviews = previews.slice(0, -1);
      const lastImageSize = images[images.length - 1].size;

      setImages(newImages);
      setPreviews(newPreviews);
      setAllImgSize((prevSize) => prevSize - lastImageSize);
    }
  };

  /* basic form vaildation */
  const handleFormValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!styleCode) {
      inputsValid = false;
      formErrors["styleCode"] = "this field is required";
    }
    if (!itemSkuCode) {
      inputsValid = false;
      formErrors["itemSkuCode"] = "this field is required";
    }
    if (!mrp) {
      inputsValid = false;
      formErrors["mrp"] = "this field is required";
    }
    if (!quantity) {
      inputsValid = false;
      formErrors["quantity"] = "this field is required";
    }
    if (!minQuantity || minQuantity < 1) {
      inputsValid = false;
      formErrors["minQuantity"] = "Please specify minimum quantity to buy.";
    }
    if (!height) {
      inputsValid = false;
      formErrors["height"] = "this field is required";
    }
    if (!length) {
      inputsValid = false;
      formErrors["length"] = "this field is required";
    }
    if (!weight) {
      inputsValid = false;
      formErrors["weight"] = "this field is required";
    }
    if (!width) {
      inputsValid = false;
      formErrors["width"] = "this field is required";
    }
    if (selectedColors.length === 0) {
      inputsValid = false;
      formErrors["color"] = "this field is required";
    }
    if (selectedSizes.length === 0) {
      inputsValid = false;
      formErrors["size"] = "this field is required";
    }
    if (
      product.categories[0].code === "sneakers" ||
      product.categories[0].code === "streetwears"
    ) {
      if (!bundleValue) {
        inputsValid = false;
        formErrors["bundle"] = "this field is required";
      }
    }

    if (images.length === 0) {
      inputsValid = false;
      formErrors["images"] = "this field is required";
    } else {
      if (allImgSize >= 3145728) {
        inputsValid = false;
        formErrors["images"] = "Please upload the Images less then 3MB";
      }
    }
    setFormErrors(formErrors);
    return inputsValid;
  };

  /* clear add attribute form */
  const clearForm = () => {
    setSize({ id: "", code: "" });
    setColorCode("");
    setItemSkuCode();
    setStyleCode();
    setWidth();
    setLength();
    setHeight();
    setEan();
    setMinQuantity();
    setQuantity();
    setMrp();
    setPrice();
    setTradeDiscount();
    setStyleDesc();
    setWeight("");
    setImages([]);
    setFormErrors({});
    setPreviews([]);
    setSelectedColors([]);
    setSelectedSizes([]);
    setBundleValue("");
  };

  /* function to delete attribute from added list */
  const handleDeleteColor = (attributeId) => {
    dispatch(deleteAttributeOption(product.id, attributeId));
    clearForm();
    handleaddAttributeClose();
  };

  /* function to handle submit form data */
  const handleSubmitForm = (event) => {
    event.preventDefault();
    if (handleFormValidation()) {
      const attributesToDispatch = [];

      if (product.bundleType === "sizes") {
        selectedSizes.forEach((sizeCode) => {
          attributesToDispatch.push(createAttributeData(sizeCode, bundleValue));
        });
      } else if (product.bundleType === "colors") {
        selectedColors.forEach((colorCode) => {
          attributesToDispatch.push(
            createAttributeData(bundleValue, colorCode)
          );
        });
      } else {
        selectedColors.forEach((colorCode) => {
          attributesToDispatch.push(
            createAttributeData(selectedSizes[0], colorCode)
          );
        });
      }

      attributesToDispatch.forEach((attributeData) => {
        dispatch(addAttribute(attributeData, product.id, images, account.id));
      });

      onsubmit();
      clearForm();
      setAllImgSize(0);
      handleaddAttributeClose();
    }
  };

  const createAttributeData = (sizeCode, colorCode) => {
    return {
      articleSpecification: {
        dimensionUnitOfMeasure: "cm",
        height: Number(height),
        id: 0,
        length: Number(length),
        weight: Number(weight),
        weightUnitOfMeasure: "g",
        width: Number(width),
      },
      attiributeDescription: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
      attributeDefault: false,
      attributeDisplayOnly: true,
      ean: ean,
      id: 0,
      item_sku: itemSkuCode,
      minQuantity: Number(minQuantity),
      option: {
        categoryCode: "string",
        code: sizeCode,
        id: 0,
        readOnly: true,
        type: "string",
      },
      optionValue: {
        code: colorCode,
        defaultValue: true,
        id: 0,
        image: "string",
        price: "string",
        sortOrder: 0,
      },
      priceFinal: Number(price),
      productAttributePrice: Number(mrp),
      productAttributeQuantity: Number(quantity),
      productId: 0,
      sortOrder: 0,
      styleCode: styleCode,
      styleDescription: styleDesc,
      tradeDisCount: tradeDiscount,
    };
  };

  /* function to close dialog window */
  const handleCloseDialog = () => {
    handleaddAttributeClose();
    setFormErrors({});
    clearForm();
  };

  useEffect(() => {
    if (
      product &&
      product.categories &&
      product.categories.length > 0 &&
      product.categories[0].code
    ) {
      const categoryCode = product.categories[0].code;

      if (categoryCode === "streetwears" || categoryCode === "sneakers") {
        if (product.bundleType === "sizes") {
          const colorArray = Array.isArray(product.bundleValue)
            ? product.bundleValue
            : [product.bundleValue];
          setSelectedColors(colorArray);
        } else if (product.bundleType === "colors") {
          const sizeArray = Array.isArray(product.bundleValue)
            ? product.bundleValue
            : [product.bundleValue];
          setSelectedSizes(sizeArray);
        }
      }
    }
  }, [product]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      className={classes.dilougDiv}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            style={{
              outline: "none",
              border: "2px solid #000000",
              padding: 0,
              color: "#000000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography className={classes.title}>Add Attributes</Typography>
        </Toolbar>
      </AppBar>

      {product && Object.keys(product).length !== 0 && (
        <Grid container spacing={3} className={classes.gridSection}>
          <Grid item xs={12}>
            <div className={classes.details}>
              <Typography>
                <Box component="span" className={classes.labletext}>
                  Product Name :{" "}
                </Box>
                <Box component="span" className={classes.span}>
                  {product.description.name}
                </Box>
              </Typography>
              <Typography>
                <Box component="span" className={classes.labletext}>
                  Product ID :{" "}
                </Box>
                <Box component="span" className={classes.span}>
                  {product.id}
                </Box>
              </Typography>
              <Typography>
                <Box component="span" className={classes.labletext}>
                  Category Name :{" "}
                </Box>
                <Box component="span" className={classes.span}>
                  {product.categories[0].code}
                </Box>
              </Typography>

              {(product.bundleType === 'colors' || product.bundleType === 'sizes') && (
                <Typography>
                  <Box component="span" className={classes.labletext}>
                    Bundle Type :{" "}
                  </Box>
                  <Box component="span" className={classes.span}>
                    Pack of {product.bundleType}
                  </Box>
                </Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              {product.categories[0].code === "streetwears" ||
                product.categories[0].code === "sneakers" ? (
                <>
                  {product.bundleType === "sizes" && (
                    <>
                      <Typography className={classes.headingSec}>
                        Select Default Color *{'   '}
                        <Tooltip
                          title="Select only one default color for pack of sizes."
                          arrow
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton className={classes.iconButton}>
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Paper className={classes.colorCodes}>
                        {coloroption && coloroption.length > 0 && (
                          <Fragment>
                            {coloroption
                              .filter((color) => {
                                const colorExistsInAttributes = product.attributes.some(attribute =>
                                  attribute.attributeOptionValues.some(
                                    (option) => option.colorCode === color.colorCode
                                  )
                                );
                                return !colorExistsInAttributes;
                              })
                              .map((color, index) => (
                                <Tooltip
                                  title={color.colorName}
                                  key={index}
                                  arrow
                                  placement="top"
                                >
                                  <button
                                    className={classes.colorpicker}
                                    style={{
                                      background: color.colorCode,
                                      border: bundleValue ===
                                        color.colorName

                                        ? "2px solid #000"
                                        : "none",
                                    }}
                                    onClick={() => setBundleValue(color.colorName)}
                                  ></button>
                                </Tooltip>
                              ))}
                          </Fragment>
                        )}
                      </Paper>

                      <Typography className={classes.headingSec}>
                        Select Pack of Sizes *{'   '}
                        <Tooltip
                          title="You can select one size or more than one size."
                          arrow
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton className={classes.iconButton}>
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Paper className={classes.paper}>
                        <FormGroup row>
                          {sizeoption &&
                            sizeoption.length > 0 &&
                            sizeoption.map((value) => (
                              <FormControlLabel
                                key={value.id}
                                control={
                                  <Checkbox
                                    checked={selectedSizes.includes(value.code)}
                                    onChange={() =>
                                      handleSelectSize(value.code)
                                    }
                                    name={value.id.toString()}
                                    size="small"
                                  />
                                }
                                label={value.code}
                              />
                            ))}
                        </FormGroup>
                      </Paper>
                    </>
                  )}

                  {product.bundleType === "colors" && (
                    <>
                      <Typography className={classes.headingSec}>
                        Select Default Size *{'   '}
                        <Tooltip
                          title="You can select only one default size for pack of colors."
                          arrow
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton className={classes.iconButton}>
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Paper className={classes.paper}>
                        <FormGroup row>
                          {sizeoption &&
                            sizeoption.length > 0 &&
                            sizeoption
                              .filter((value) => {
                                const selectedSizeCodes = product.attributes
                                  .map((attr) => attr.optionCode)
                                  .flat();

                                return !selectedSizeCodes.includes(value.code);
                              })
                              .map((value) => (
                                <FormControlLabel
                                  key={value.id}
                                  control={
                                    <Radio
                                      checked={bundleValue === value.code}
                                      onChange={() => setBundleValue(value.code)}
                                      name="sizeOptions"
                                      value={value.code}
                                      size="small"
                                    />
                                  }
                                  label={value.code}
                                />
                              ))}
                        </FormGroup>
                      </Paper>

                      <Typography className={classes.headingSec}>
                        Select Pack of Colors *{'   '}
                        <Tooltip
                          title="You can select one color or more than one color."
                          arrow
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton className={classes.iconButton}>
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Paper className={classes.colorCodes}>
                        {coloroption && coloroption.length > 0 && (
                          <Fragment>
                            {coloroption.map((color, index) => (
                              <Tooltip
                                title={color.colorName}
                                key={index}
                                arrow
                                placement="top"
                              >
                                <button
                                  className={`${classes.colorpicker} ${selectedColors.includes(color.colorName)
                                    ? classes.selected
                                    : ""
                                    }`}
                                  style={{
                                    background: color.colorCode,
                                    border: selectedColors.includes(
                                      color.colorName
                                    )
                                      ? "2px solid #000"
                                      : "none",
                                  }}
                                  onClick={() =>
                                    handleSelectColor(color.colorName)
                                  }
                                ></button>
                              </Tooltip>
                            ))}
                          </Fragment>
                        )}
                      </Paper>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Typography className={classes.headingSec}>
                    Select Size
                  </Typography>
                  <Paper className={classes.paper}>
                    <RadioGroup
                      name="size"
                      aria-label="size"
                      value={selectedSizes.length > 0 ? selectedSizes[0] : ""}
                      onChange={handleSingleSelectSize}
                      row
                    >
                      {sizeoption &&
                        sizeoption.length > 0 &&
                        sizeoption.map((value) => (
                          <FormControlLabel
                            key={value.id}
                            value={value.code}
                            name={value.id.toString()}
                            control={<Radio size="small" />}
                            label={value.code}
                          />
                        ))}
                    </RadioGroup>
                  </Paper>

                  <Typography className={classes.headingSec}>
                    Select Color
                  </Typography>
                  <Paper className={classes.colorCodes}>
                    {coloroption && coloroption.length > 0 && (
                      <Fragment>
                        {coloroption.map((color, index) => (
                          <Tooltip
                            title={color.colorName}
                            key={index}
                            arrow
                            placement="top"
                          >
                            <button
                              className={`${classes.colorpicker} ${selectedColors.includes(color.colorName)
                                ? classes.selected
                                : ""
                                }`}
                              style={{
                                background: color.colorCode,
                                border: selectedColors.includes(
                                  color.colorName
                                )
                                  ? "2px solid #000"
                                  : "none",
                              }}
                              onClick={() =>
                                handleSelectColor(color.colorName)
                              }
                            ></button>
                          </Tooltip>
                        ))}
                      </Fragment>
                    )}
                  </Paper>
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography className={classes.headingSec}>
                Add Attribute Value
              </Typography>

              <form onSubmit={handleSubmitForm} autoComplete="off">
                <Paper className={classes.paper}>
                  <div className={classes.sectionDiv}>
                    {product.categories[0].code !== "streetwears" &&
                      product.categories[0].code !== "sneakers" ? (
                      <>
                        <TextField
                          id="size"
                          name="size"
                          label="Size *"
                          variant="outlined"
                          size="small"
                          onChange={(e) =>
                            handleSingleSelectSize(e.target.value)
                          }
                          value={
                            selectedSizes.length > 0 ? selectedSizes[0] : ""
                          }
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                            },
                          }}
                          helperText={formErrors.size ? formErrors.size : ""}
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                        <TextField
                          id="color"
                          name="color"
                          label="Color *"
                          variant="outlined"
                          size="small"
                          onChange={(e) =>
                            handleSelectColor(e.target.value)
                          }
                          value={selectedColors.join(", ")}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                            },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          helperText={
                            formErrors.color ? formErrors.color : ""
                          }
                        />
                      </>
                    ) : (
                      <>
                        {product.bundleType === "sizes" ? (
                          <>
                            <TextField
                              id="color"
                              name="color"
                              label="Color *"
                              variant="outlined"
                              size="small"
                              onChange={(e) =>
                                setBundleValue(e.target.value)
                              }
                              value={bundleValue}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                },
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              helperText={
                                formErrors.bundle ? formErrors.bundle : ""
                              }
                            />

                            {/* Size as TextField */}
                            <TextField
                              id="size"
                              name="size"
                              label="Size *"
                              variant="outlined"
                              size="small"
                              onChange={handleSelectSize}
                              value={selectedSizes.join(", ")}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                },
                              }}
                              helperText={
                                formErrors.size ? formErrors.size : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <TextField
                              id="size"
                              name="size"
                              label="Size *"
                              variant="outlined"
                              size="small"
                              onChange={(e) =>
                                setBundleValue(e.target.value)
                              }
                              value={bundleValue}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                },
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              helperText={
                                formErrors.bundle ? formErrors.bundle : ""
                              }
                            />

                            <TextField
                              id="color"
                              name="color"
                              label="Color *"
                              variant="outlined"
                              size="small"
                              onChange={(e) =>
                                handleSelectColor(e.target.value)
                              }
                              value={selectedColors.join(", ")}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                },
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              helperText={
                                formErrors.color ? formErrors.color : ""
                              }
                            />
                          </>
                        )}
                      </>
                    )}

                    <TextField
                      id="styleCode"
                      name="styleCode"
                      label="Style Code *"
                      variant="outlined"
                      size="small"
                      value={styleCode}
                      onChange={(e) => setStyleCode(e.target.value)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.styleCode ? formErrors.styleCode : ""
                      }
                    />

                    <TextField
                      id="itemSkuCode"
                      name="itemSkuCode"
                      label="Item SKU *"
                      variant="outlined"
                      size="small"
                      value={itemSkuCode}
                      onChange={(e) => setItemSkuCode(e.target.value)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.itemSkuCode ? formErrors.itemSkuCode : ""
                      }
                    />

                    <TextField
                      id="styleDesc"
                      name="styleDesc"
                      label="Style Description"
                      variant="outlined"
                      size="small"
                      value={styleDesc}
                      onChange={(e) => setStyleDesc(e.target.value)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.styleDesc ? formErrors.styleDesc : ""
                      }
                    />

                    <TextField
                      id="tradeDiscount"
                      name="tradeDiscount"
                      label="Trade Discount"
                      variant="outlined"
                      size="small"
                      type="tel"
                      value={tradeDiscount}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                          return;
                        }
                        setTradeDiscount(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 100 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                    />

                    <TextField
                      id="mrp"
                      name="mrp"
                      type="tel"
                      label="Price excluding GST *"
                      variant="outlined"
                      size="small"
                      value={mrp}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                          return;
                        }
                        setMrp(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 9999999 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={formErrors.mrp ? formErrors.mrp : ""}
                    />

                    <TextField
                      id="price"
                      name="price"
                      type="tel"
                      label="Final Price "
                      variant="outlined"
                      size="small"
                      value={price}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                          return;
                        }
                        setPrice(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 9999999 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                    />

                    <TextField
                      id="quantity"
                      name="quantity"
                      type="tel"
                      label="Quantity *"
                      variant="outlined"
                      size="small"
                      value={quantity}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                          return;
                        }
                        setQuantity(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 9999999 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.quantity ? formErrors.quantity : ""
                      }
                    />

                    <TextField
                      id="minQuantity"
                      name="minQuantity"
                      type="tel"
                      label="Minimum Quantity to buy *"
                      variant="outlined"
                      size="small"
                      value={minQuantity}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                          return;
                        }
                        setMinQuantity(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 9999999 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.minQuantity ? formErrors.minQuantity : ""
                      }
                    />

                    <TextField
                      id="ean"
                      name="ean"
                      label="EAN"
                      variant="outlined"
                      size="small"
                      value={ean}
                      onChange={(e) => setEan(e.target.value)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                    />

                    <TextField
                      id="length"
                      name="length"
                      type="tel"
                      label="length *"
                      variant="outlined"
                      size="small"
                      value={length}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMDOT_ONLY.test(value)) {
                          return;
                        }
                        setLength(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 1000 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={formErrors.length ? formErrors.length : ""}
                    />

                    <TextField
                      id="width"
                      name="width"
                      type="tel"
                      label="width *"
                      variant="outlined"
                      size="small"
                      value={width}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMDOT_ONLY.test(value)) {
                          return;
                        }
                        setWidth(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 1000 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={formErrors.width ? formErrors.width : ""}
                    />

                    <TextField
                      id="height"
                      name="height"
                      type="tel"
                      label="height *"
                      variant="outlined"
                      size="small"
                      value={height}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMDOT_ONLY.test(value)) {
                          return;
                        }
                        setHeight(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 1000 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={formErrors.height ? formErrors.height : ""}
                    />

                    <TextField
                      id="weight"
                      name="weight"
                      type="tel"
                      label="Weight *"
                      variant="outlined"
                      size="small"
                      value={weight}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMDOT_ONLY.test(value)) {
                          return;
                        }
                        setWeight(e.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1, max: 1000 } }}
                      onKeyUp={(e) => validatePositiveNumberEvent(e)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={formErrors.weight ? formErrors.weight : ""}
                    />

                    <MyEditor
                      Editor={Editor}
                      EditorState={EditorState}
                      RichUtils={RichUtils}
                      editorState={editorState}
                      setEditorState={setEditorState}
                    />

                    <Fragment>
                      {previews.length > 0 && (
                        <div className={classes.multipreview}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.removeimage}
                            onClick={handleRemoveImage}
                          >
                            Remove Images
                          </Button>
                          <div className={classes.imageGrid}>
                            {previews.map((url, index) => (
                              <Fragment key={index}>
                                <img
                                  src={url}
                                  alt={`Preview ${index + 1}`}
                                  className={classes.img}
                                />
                              </Fragment>
                            ))}
                          </div>
                        </div>
                      )}

                      <input
                        accept=".jpg, .jpeg, .png"
                        className={classes.uploadInput}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleImageUpload}
                        disabled={images.length >= 5}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          component="span"
                          className={classes.uploadImageButton}
                          disabled={images.length >= 5}
                        >
                          {images.length >= 5
                            ? "Maximum 5 Images Selected"
                            : "Upload Image"}
                        </Button>
                      </label>

                      {formErrors.images && (
                        <Box className={classes.inputErrorBlock}>
                          {formErrors.images}
                        </Box>
                      )}
                    </Fragment>
                  </div>
                </Paper>

                <div className={classes.submitdiv}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.submitButton}
                  >
                    Add Attribute
                  </Button>

                  <Button
                    variant="contained"
                    className={classes.cancelButton}
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.headingSec}>
              Added Attributes
            </Typography>
            <div className={classes.tablediv}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tablehead}>
                      <TableCell align="center">Size</TableCell>
                      <TableCell align="start">Color</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {product.attributes && product.attributes.length > 0 ? (
                      product.attributes.map((attribute, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" align="center" scope="row">
                            {attribute.optionCode}
                          </TableCell>
                          <TableCell align="center">
                            {attribute.attributeOptionValues.map(
                              (otpion, index) => (
                                <p
                                  key={index}
                                  className={classes.colorbox}
                                  style={{
                                    backgroundColor: `${otpion.colorCode}`,
                                  }}
                                />
                              )
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {attribute.attributeOptionValues.map(
                              (otpion, index) => (
                                <p key={index}>{otpion.attributePrice}</p>
                              )
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {attribute.attributeOptionValues.map(
                              (otpion, index) => (
                                <p key={index}>{otpion.attributeQuantity}</p>
                              )
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {attribute.attributeOptionValues.map(
                              (option, index) => (
                                <div key={index}>
                                  {option.optionValueDefault ? (
                                    ""
                                  ) : (
                                    <IconButton
                                      aria-label="delete"
                                      className={classes.deleteButton}
                                      style={{
                                        outline: "none",
                                        height: "0px",
                                      }}
                                      onClick={(e) =>
                                        handleDeleteColor(option.attributeId)
                                      }
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  )}
                                </div>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key={1}>
                        <TableCell colSpan={5} className={classes.noFound}>
                          Attributes not found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};
