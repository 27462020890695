import {ActionTypes} from "../constants/actionTypes.js";
import axios from "axios";
import { snackBarAlert } from './deliveryActions';

const baseUrl = process.env.REACT_APP_BASE_URL;
const bargainRequestBaseUrl = process.env.REACT_APP_BARGAIN_REQUEST;

const CHECK_OUT_BARGAIN_UPDATE_URL = `${bargainRequestBaseUrl}/checkout/update/bargain`;


export const addToCart = (cartData, userId, history, updateElastic) => async (dispatch) => {

    try {
        if (userId) {
            dispatch({type: ActionTypes.CART_PAGE_LOADING});
            // dispatch({type: ActionTypes.LOADING_REQUEST});
            const {data} = await axios.post(
                `${baseUrl}/customers/${userId}/cart`,
                cartData
            );
            
            dispatch(snackBarAlert("success" , "Product added to cart successfully!!!"));
            // await callTheElasticUpdate(updateElastic, elasticUpdate);
            // dispatch({type: ActionTypes.LOADED_REQUEST});
     
            dispatch({type: ActionTypes.ADD_PRODUCT_TO_CART, payload: data});
            // dispatch({ type: ActionTypes.DELETE_NOTIFICATION, payload: elasticUpdate.docId });
            // history.push("/cart");
        }
    } catch (error) {
        if (error.response) {
            dispatch({
                type: ActionTypes.CART_PAGE_ERROR,
                payload: error.response.data.message,
            });
        } else {
            dispatch({
                type: ActionTypes.CART_PAGE_SERVER_ERROR,
                payload: "Server error , try again later !!",
            });
        }
    }
};

export const addToCartFromBargain = (cartData, userId, history, updateElastic, elasticUpdate) => async (dispatch) => {

    try {
        if (userId) {
            // dispatch({type: ActionTypes.CART_PAGE_LOADING});
            // // dispatch({type: ActionTypes.LOADING_REQUEST});
            // const {data} = await axios.post(
            //     `${baseUrl}/customers/${userId}/cart`,
            //     cartData
            // );
            await dispatch({ type: ActionTypes.DELETE_NOTIFICATION, payload: elasticUpdate.docId });
            // dispatch(snackBarAlert("success" , "Product added to cart successfully!!!"));
            await callTheElasticUpdate(updateElastic, elasticUpdate);
            // dispatch({type: ActionTypes.LOADED_REQUEST});
            // dispatch({type: ActionTypes.ADD_PRODUCT_TO_CART, payload: data});
            
            // history.push("/cart");
        }
    } catch (error) {
        if (error.response) {
            dispatch({
                type: ActionTypes.CART_PAGE_ERROR,
                payload: error.response.data.message,
            });
        } else {
            dispatch({
                type: ActionTypes.CART_PAGE_SERVER_ERROR,
                payload: "Server error , try again later !!",
            });
        }
    }
};

export const callTheElasticUpdate = async (updateElastic, elasticUpdate) => {
    if (updateElastic) {
        let elasticUpdateUrl = `${bargainRequestBaseUrl}/update/bargain`;
        await axios.put(elasticUpdateUrl, elasticUpdate);
    }
};

export const getCartItemsByUser = (userId) => async (dispatch) => {
    try {
        dispatch({type: ActionTypes.CART_PAGE_LOADING});
        const {data} = await axios.get(`${baseUrl}/customers/${userId}/cart`);
        dispatch({type: ActionTypes.GET_CART_ITEM, payload: data});
        dispatch({type: ActionTypes.CART_CLEAR_ERRORS});
    } catch (error) {
        if (error.response.status === 404) {
            dispatch({
                type: ActionTypes.GET_CART_ITEM,
                payload: {},
            });
        } else {
            dispatch({
                type: ActionTypes.CART_PAGE_SERVER_ERROR,
                payload: "Server error , try again later !!",
            });
        }
    }
};

export const deleteCartItem =
    (pid, code, uid, cartItemId, updateRequest) => async (dispatch) => {
        try {
            dispatch({type: ActionTypes.CART_PAGE_LOADING});
            await axios.delete(
                `${baseUrl}/cart/${code}/product/${pid}/cartItem/${cartItemId}`
            );
            await dispatch(checkoutUpdateBargain(updateRequest));
            const {data} = await axios.get(`${baseUrl}/customers/${uid}/cart`);
            dispatch({type: ActionTypes.DELETE_CART_ITEM, payload: data});
        } catch (error) {
            // console.log(error);
        }
    };

export const addUpdateCartItem =
    (cartData, code, cartItemId) => async (dispatch) => {
        try {
            const {data} = await axios.put(
                `${baseUrl}/cart/${code}/cartItem/${cartItemId}`,
                cartData
            );
            dispatch({type: ActionTypes.INCREASE_QUANTITY, payload: data});
        } catch (error) {
            // console.log(error);
        }
    };

export const removeUpdateCartItem =
    (cartData, code, cartItemId) => async (dispatch) => {
        try {

            const {data} = await axios.put(
                `${baseUrl}/cart/${code}/cartItem/${cartItemId}`,
                cartData
            );
            dispatch({type: ActionTypes.DECREASE_QUANTITY, payload: data});
        } catch (error) {
            // console.log(error);
        }
    };

export const soldOutAlert = () => (dispatch) => {
    dispatch({
        type: ActionTypes.SET_SNACKBAR,
        payload: {
            alert: "error",
            message: "Please Remove Out of Stock Products to proceed.",
        },
    });
};

export const checkoutUpdateBargain = (updateRequest) => async (dispatch) => {
    try {
        if(updateRequest !== null && updateRequest !== undefined) {
             await axios.put(
                CHECK_OUT_BARGAIN_UPDATE_URL,
                updateRequest
            );
        }
    } catch (error) {
        // console.log(error);
    }
};
