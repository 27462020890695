import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";

/* material ui */
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PublishIcon from "@material-ui/icons/Publish";
import DnsIcon from "@material-ui/icons/Dns";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ListIcon from "@material-ui/icons/List";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import DateRangeIcon from "@material-ui/icons/DateRange";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ErrorIcon from "@material-ui/icons/Error";

/* redux actions */
import {
  changeGstNumber,
  clearAllValidations,
  gstVerification,
} from "../../../redux/actions/otherActions";
import { isStringNullorEmpty } from "../../../util/commonUtil/commonUtil";

/* material ui css */
const useStyles = makeStyles((theme) =>
  createStyles({
    signuproot: {
      "& .MuiFormControlLabel-label": {
        fontSize: 13,
        fontFamily: "'Fira Sans', sans-serif",
        color: "#4A4A4A",
      },
      "& .MuiOutlinedInput-inputAdornedStart": {
        paddingLeft: 4,
        backgroundColor: "#fff",
        borderLeft: "1px solid pink",
        borderRadius: 0,
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        backgroundColor: "#fff",
        borderRight: "1px solid pink",
      },
      "& .MuiFormHelperText-root": {
        color: "red",
        fontFamily: "'Fira Sans', sans-serif",
        textAlign: "center",
      },
      "& .MuiButton-root": {
        minWidth: 0,
      },
      "& .MuiInputAdornment-positionStart": {
        color: "#000000",
      },
    },
    title: {
      fontWeight: "bold",
      color: "#53e7bc",
      textDecoration: "underline",
    },
    lable: {
      textAlign: "start",
      margin: "0px 10px",
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#000000",
    },
    msmetitle: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#4A4A4A",
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    usertype: {
      display: "flex",
      alignItems: "center",
      borderRadius: 3,
      margin: "8px 7px",
    },
    iconButton: {
      padding: 6,
      border: "1px solid pink",
      borderRadius: "4px",
      color: "#000000",
    },
    labelRoot: {
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#666666",
    },
    msmedate: {
      margin: "10px 0px",
      padding: 7,
      borderRadius: "4px",
      border: "1px solid pink",
      outline: "none",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#666666",
      fontSize: 14,
    },
    msmevalid: {
      margin: "10px 0px",
      padding: 7,
      borderRadius: "4px",
      border: "1px solid pink",
      outline: "none",
      width: "90%",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#666666",
      fontSize: 14,
    },
    select: {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#666666",
    },
    nextbutton: {
      "&:hover": {
        backgroundColor: "#53e7bc",
      },
    },
  })
);

const gstRegEx = RegExp(
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
);
const ALPHA_ONLY = /^[a-zA-Z\s]+$/; 
const AlPHANUMERIC_ONLY = /^[A-Za-z0-9\s]+$/;
export const SupplierDetails = (props) => {
  const classes = useStyles();
  const { initialValues, handleInputChange, gstFromPDF } = props;
  const { validGst, validGstMessage, invalidGst, invalidGstMessage } =
    useSelector((state) => state.Others);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  /* function to verify GST Number */
  const gstVerify = (gstNumber) => {
    clearErrors();

    if (( isStringNullorEmpty(initialValues.gstNumber) ||  initialValues.gstNumber ) &&
      !gstRegEx.test(initialValues.gstNumber )) {
      let errors = {};
      errors["gstNumber"] = "Please enter Valid GST number.";
      setErrors(errors);
      return;
    }

    dispatch(gstVerification(gstNumber));
  };

  const clearErrors = () => {
    setErrors({});
  };

  /* function to change GST Number*/
  const handleChangeGst = () => {
    dispatch(changeGstNumber());
  };


  /* vaildate supplier details form inputs. */
  const inputValidation = () => {
    let errors = {};
    let inputsValid = true;

    if (!initialValues.userType) {
      inputsValid = false;
      errors["userType"] = "Please select seller type.";
    }

    if (!initialValues.suppliername) {
      inputsValid = false;
      errors["suppliername"] = "Please enter seller name.";
    }

    if (!initialValues.supplierType) {
      inputsValid = false;
      errors["supplierType"] = "Please select seller title.";
    }

    if (!initialValues.gstNumber) {
      inputsValid = false;
      errors["gstNumber"] = "Please enter GST number.";
    }

    if (initialValues.gstNumber && !gstRegEx.test(initialValues.gstNumber)) {
      inputsValid = false;
      errors["gstNumber"] = "Please enter Valid GST number.";
    }

    if (
      initialValues.gstNumber &&
      gstRegEx.test(initialValues.gstNumber) &&
      !validGst
    ) {
      inputsValid = false;
      errors["gstNumber"] = "Please verify GST number.";
    }

    // if (!initialValues.base64GstFile) {
    //   inputsValid = false;
    //   errors["gstFile"] = "Please upload GST document.";
    // }
    // if(initialValues.gstNumber !== gstFromPDF?.GSTN){
    //   inputsValid = false;
    //   // errors["gstNumber"] = "Please Enter correct GST number.";
    //   errors["gstFile"] = "Please Upload Valid GST file.";

    // }

    setErrors(errors);
    return inputsValid;
  };

  const handleNext = () => {
    if (inputValidation()) {
      props.handleNext();
    }
  };

  useEffect(() => {
    dispatch(clearAllValidations());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.signuproot}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            Seller Statutory Details
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Seller Type{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="outlined-select-currency-native"
                variant="outlined"
                size="small"
                name="userType"
                select
                value={initialValues.userType}
                onChange={handleInputChange}
                // className={classes.select}
                SelectProps={{
                  native: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountBoxIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={errors.userType ? errors.userType : ""}
              >
                <option aria-label="None" value="">
                  --Select One--
                </option>
                <option value="MANU">Manufacturer</option>
                <option value="MERCH">Merchant</option>
                <option value="VNDR">Vendor</option>
              </TextField>
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Seller Name{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="suppliername"
                name="suppliername"
                variant="outlined"
                size="small"
                value={initialValues.suppliername}
                onChange={
                  (event) => {
                    const value = event.target.value;
                    if (value !== "" && !ALPHA_ONLY.test(value)) {
                      return;
                    } 
                  handleInputChange(event)
                  }
                }
                className={classes.texfield}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DnsIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={errors.suppliername ? errors.suppliername : ""}
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Title{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>

              <TextField
                id="outlined-select-currency-native"
                variant="outlined"
                size="small"
                name="supplierType"
                select
                value={initialValues.supplierType}
                onChange={handleInputChange}
                SelectProps={{
                  native: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GroupAddIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={errors.supplierType ? errors.supplierType : ""}
              >
                <option aria-label="None" value="">
                  --Select One--
                </option>
                <option value="Individual">Individual</option>
                <option value="Proprietor">
                  Proprietorship/ Sole Proprietorship
                </option>
                <option value="PartnershipFirm">Partnership Firm</option>
                <option value="LLP">LLP</option>
                <option value="OPCPrivateLimited">OPC Private Limited</option>
                <option value="PrivateLimited">Private Limited</option>
                <option value="PublicLimited">Public Limited</option>
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                GST Number{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="gstNumber"
                name="gstNumber"
                variant="outlined"
                size="small"
                value={initialValues.gstNumber.toUpperCase()}
                className={classes.texfield}
                onChange={
                  (event) => {
                    const value = event.target.value;
                    if (value !== "" && !AlPHANUMERIC_ONLY.test(value)) {
                      return;
                    } 
                  handleInputChange(event)
                  }
                }
                inputProps={{ maxLength: 16 }}
                disabled={validGst ? true : false}
                InputProps={
                  validGst
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            <VerifiedUserIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleChangeGst}
                              edge="end"
                              style={{
                                fontSize: 12,
                                fontFamily: "'Fira Sans', sans-serif",
                                color: "#008000",
                              }}
                            >
                              Change
                            </IconButton>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.labelRoot,
                        },
                      }
                    : {
                        startAdornment: (
                          <InputAdornment position="start">
                            <VerifiedUserIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => gstVerify(initialValues.gstNumber)}
                              edge="end"
                              style={{
                                fontSize: 12,
                                fontFamily: "'Fira Sans', sans-serif",
                                color: "#008000",
                              }}
                            >
                              Verify
                            </IconButton>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.labelRoot,
                        },
                      }
                }
                helperText={
                  errors.gstNumber ? (
                    errors.gstNumber
                  ) : validGst ? (
                    <Typography
                      component={"span"}
                      style={{
                        fontSize: "14px",
                        color: "green",
                      }}
                    >
                      <DoneAllIcon size="small" style={{ fontSize: "18px" }} />{" "}
                      {validGstMessage}
                    </Typography>
                  ) : invalidGst ? (
                    <Typography 
                      component={"span"} 
                      style={{ 
                        fontSize: "14px" ,
                         color: "red",
                       }}>
                      <ErrorIcon size="small" style={{ fontSize: "18px" , color: "#ff0000" }} />{" "}
                      {invalidGstMessage}
                    </Typography>
                  ) : (
                    ""
                  )
                }
                // helperText={errors.gstNumber ? errors.gstNumber : ""}
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                GST File{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>

              <TextField
                id="gstFile"
                variant="outlined"
                size="small"
                value={initialValues.gstFile}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <label htmlFor="contained-button-file-pan">
                        <Button
                          color="default"
                          component="span"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <PublishIcon />
                        </Button>
                      </label>
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={errors.gstFile ? errors.gstFile : ""}
              />

              <input
                accept=".pdf"
                id="contained-button-file-pan"
                type="file"
                onChange={props.gstUpload}
                style={{ display: "none" }}
                onClick={(e) => (e.target.value = null)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* {initialValues.userType === "MANU" && ( */}
          <Fragment>
            <Grid item xs={12}>
              <Typography className={classes.msmetitle}>
                MSME Information
              </Typography>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={6} xs={12}>
                  <FormControl
                    component="fieldset"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <FormLabel component="legend" className={classes.lable}>
                      Are you MSME ?
                    </FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="msme"
                      style={{ display: "block" }}
                      value={initialValues.msme}
                      onChange={handleInputChange}
                    >
                      <FormControlLabel
                        value="YES"
                        control={<Radio color="primary" size="small" />}
                        label="YES"
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio color="primary" size="small" />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={6} xs={12}>
                  <InputLabel className={classes.lable}>UAM Number</InputLabel>
                  <TextField
                    id="uamNumber"
                    name="umaNumber"
                    variant="outlined"
                    size="small"
                    className={classes.texfield}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CreditCardIcon />
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={6} xs={12}>
                  <InputLabel className={classes.lable}>
                    Memorandum Number
                  </InputLabel>
                  <TextField
                    id="memorandum"
                    name="memorandum"
                    variant="outlined"
                    size="small"
                    className={classes.texfield}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CreditCardIcon />
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  />
                </Grid>

                <Grid item lg={4} md={6} xs={12}>
                  <InputLabel className={classes.lable}>MSME Type</InputLabel>

                  <TextField
                    id="outlined-select-currency-native"
                    variant="outlined"
                    size="small"
                    name="msmeType"
                    select
                    value={initialValues.msmeType}
                    onChange={handleInputChange}
                    SelectProps={{
                      native: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ListIcon />
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  >
                    <option aria-label="None" value="">
                      --Select One--
                    </option>
                    <option value="MICRO">Micro Enterprise</option>
                    <option value="SMALL">Small Enterprise</option>
                    <option value="MEDIUM">Medium Enterprise</option>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <InputLabel className={classes.lable}>Date of Issue</InputLabel>
              <IconButton className={classes.iconButton} aria-label="menu">
                <DateRangeIcon />
              </IconButton>
              <DatePicker
                selected={initialValues.msmeDate}
                onChange={(date) => props.handleMsmeDate(date)}
                className={classes.msmedate}
              />
            </Grid>

            <Grid item lg={2} md={3} xs={12}>
              <InputLabel className={classes.lable}>Valid From</InputLabel>
              <DatePicker
                selected={initialValues.msmeValidFrom}
                onChange={(date) => props.handleMsmeValidFrom(date)}
                className={classes.msmevalid}
              />
            </Grid>

            <Grid item lg={2} md={3} xs={12}>
              <InputLabel className={classes.lable}>Valid To</InputLabel>
              <DatePicker
                selected={initialValues.msmeValidTo}
                onChange={(date) => props.handleMsmeValidTo(date)}
                className={classes.msmevalid}
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                MSME Certification
              </InputLabel>

              <TextField
                id="msmeDocument"
                variant="outlined"
                size="small"
                value={initialValues.msmeDocName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <label htmlFor="contained-button-file">
                        <Button color="default" component="span">
                          <PublishIcon />
                        </Button>
                      </label>
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
              />

              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={props.msmeUpload}
                style={{ display: "none" }}
              />
            </Grid>
          </Fragment>
        {/* )} */}
      </Grid>

      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          className={classes.nextbutton}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
